import { Stack } from '@mui/material';
import { DebouncedInput } from 'components/third-party/react-table';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { supabase } from 'supabase/supabase';
import { CenePlusRequest } from 'types/user';
import CenePlusRequestsStatistic from './cenePlusRequestsStatistic';
import CenePlusRequestsTable from './cenePlusRequests.table';
import ReusableTabs from 'components/tabs/reusableTabs';
import { Pagination } from '@mui/material';

enum ITab {
  ALL = 'All',
  NEW = 'New',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

const CenePlusRequestsPage = () => {
  const [filter, setFilter] = useState('');
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const [cenePlusRequests, setCenePlusRequests] = useState<CenePlusRequest[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('cene_plus_requests').select('*');

      setCenePlusRequests(data as CenePlusRequest[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const filterCenePlusByStatus = () => {
    if (activeTab === ITab.ALL) {
      return cenePlusRequests;
    }
    return cenePlusRequests.filter((request) => request.status?.toLowerCase() === activeTab.toLowerCase());
  };

  const filteredRequestsByStatus = filterCenePlusByStatus().filter((request) =>
    Object.values(request).join(' ').toLowerCase().includes(filter.toLowerCase())
  );

  const concatenateFields = (request: CenePlusRequest) => {
    const metadataFields = Object.values(request).join(' ');
    const otherFields = 'email' in request ? request.email : '';
    return `${metadataFields} ${otherFields}`.toLowerCase();
  };

  const filteredRequests = filteredRequestsByStatus.filter((request) => concatenateFields(request).includes(filter.toLowerCase()));
  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const paginatedCustomers = filteredRequests.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <CenePlusRequestsStatistic requests={cenePlusRequests} />
      <Stack
        sx={{
          mt: 3,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderInline: '1px solid #1e1e1e',
          borderTop: '1px solid #1e1e1e',
          padding: '20px'
        }}
      >
        <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} />

        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search Cene+ request..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <Stack>
        <CenePlusRequestsTable requests={paginatedCustomers} isLoading={isLoading} onSuccess={fetchRequests} />
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 3, alignSelf: 'center' }} />
      </Stack>
    </>
  );
};

export default CenePlusRequestsPage;
