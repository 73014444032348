import { CalendarOutlined } from '@ant-design/icons';
import { ROUTER_PARTNER_FLOORPLANS } from 'constants/routes';
import { NavItemType } from 'types/menu';

const partnerEvents: NavItemType = {
  id: 'group-partner-events-loading',
  type: 'group',
  children: [
    {
      id: 'group-partner-events',
      title: 'Events',
      type: 'collapse',
      icon: CalendarOutlined,
      children: [
        {
          permissions: ['*', 'events:c', 'events:r', 'events:u', 'events:d'],
          id: 'events',
          title: 'My Events',
          type: 'item',
          url: '/events',
          breadcrumbs: false
        },
        {
          permissions: ['*', 'events:c', 'events:r', 'events:u', 'events:d'],
          id: 'partner-floorplans',
          title: 'My Floorplans',
          type: 'item',
          url: ROUTER_PARTNER_FLOORPLANS,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default partnerEvents;
