import { useEffect, useState } from 'react';
import { Event, EventStatus } from '@ceneteam/namespace';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import ReusableTabs from 'components/tabs/reusableTabs';
import { Button, Pagination, Stack, Typography } from '@mui/material';
import EventsStatistic from './eventsStatistic';
import { DebouncedInput } from 'components/third-party/react-table';
import EventsTable from './events.table';
import { parseISO } from 'date-fns';
import { useNavigate } from 'react-router';
import { ROUTER_EVENT_EDIT } from 'constants/routes';

enum ITab {
  ALL = 'All events',
  PUBLISHED = 'Published',
  CENE = 'Cene events',
  CENE_PLUS = 'Cene+ events',
  ACTIVE = 'Active',
  PAST_EVENTS = 'Past events',
  DRAFTS = 'Drafts'
}

const ITEMS_PER_PAGE = 10;

const CeneEventsPage = () => {
  const navigation = useNavigate();
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('events').select('*');

      setEvents(data as Event[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handleFilterChange = (value: string) => {
    setFilter(value);
    setCurrentPage(1);
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const getEventDisplayStatus = (event: Event): { displayStatus: string; statusStyles: object } => {
    let displayStatus = '';
    let statusStyles = { backgroundColor: '#000', color: '#fff' };

    if (event.status === 'coming_soon') {
      displayStatus = 'Drafts';
    } else if (event.status === 'published') {
      const currentDate = new Date();
      const startDate = parseISO(event.starts_at as string);
      const endDate = parseISO(event.ends_at as string);

      if (currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime()) {
        displayStatus = 'Active';
      } else if (currentDate > endDate) {
        displayStatus = 'Past Event';
      } else {
        displayStatus = 'Published';
      }
    }

    return { displayStatus, statusStyles };
  };

  const filterEvents = () => {
    return events.filter((event) => {
      const { displayStatus } = getEventDisplayStatus(event);

      switch (activeTab) {
        case ITab.CENE:
          return event.type === 'cene';
        case ITab.CENE_PLUS:
          return event.type === 'cene+';
        case ITab.ACTIVE:
          return displayStatus === 'Active';
        case ITab.PAST_EVENTS:
          return displayStatus === 'Past Event';
        case ITab.DRAFTS:
          return event.status === EventStatus.Draft;
        case ITab.PUBLISHED:
          return event.status === EventStatus.Published && displayStatus === 'Published';
        default:
          return events;
      }
    });
  };

  const filteredEventsByStatus = filterEvents().filter((event) =>
    Object.values(event).join(' ').toLowerCase().includes(filter.toLowerCase())
  );

  const concatenateFields = (event: Event) => {
    const metadataFields = Object.values(event).join(' ');
    const otherFields = 'email' in event ? event.email : '';
    return `${metadataFields} ${otherFields}`.toLowerCase();
  };

  const filteredEvents = filteredEventsByStatus.filter((event) => concatenateFields(event).includes(filter.toLowerCase()));

  const paginatedEvents = filteredEvents.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <>
      <Typography sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '24px', color: '#b3b3b3', mb: 3 }}>
        View and manage Cene Partner events, ticket stock, event status, location, and key stats.
      </Typography>
      <EventsStatistic events={events} />
      <Stack
        sx={{
          mt: 3,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderInline: '1px solid #1e1e1e',
          borderTop: '1px solid #1e1e1e',
          padding: '20px'
        }}
      >
        <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} color="#8057DB" />

        <Stack flexDirection="row" gap={2}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => handleFilterChange(String(value))}
            placeholder="Search event..."
            sx={{ width: '300px !important' }}
          />
          <Button onClick={() => navigation(ROUTER_EVENT_EDIT.replace(':id', 'new'))} variant="contained">
            Create event
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <EventsTable events={paginatedEvents} isLoading={isLoading} />
        <Pagination
          count={Math.ceil(filteredEvents.length / ITEMS_PER_PAGE)}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ mt: 3, alignSelf: 'center' }}
        />
      </Stack>
    </>
  );
};

export default CeneEventsPage;
