import { Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useState } from 'react';

export interface ITableAndGuest {
  zone: string;
  table: string;
  ownedBy: string;
  allocationType: string;
  seats: string;
  paymentProvider: string;
  assignedBy: string;

  id: string;
}

export enum TablesAndGuestsColumnId {
  ZONE = 'zone',
  TABLE = 'table',
  OWNED_BY = 'ownedBy',
  ALLOCATION_TYPE = 'allocationType',
  SEATS = 'seats',
  PAYMENT_PROVIDER = 'paymentProvider',
  ASSIGNED_BY = 'assignedBy'
}

interface TableColumn {
  id: TablesAndGuestsColumnId;
  title: string;
  sortable: boolean;
  cell?: (table: ITableAndGuest) => React.ReactNode;
}

const tableAndGuestColumns: TableColumn[] = [
  { id: TablesAndGuestsColumnId.ZONE, title: 'Zone', sortable: true, cell: (table) => table.zone },
  { id: TablesAndGuestsColumnId.TABLE, title: 'Table', sortable: true, cell: (table) => table.table },
  { id: TablesAndGuestsColumnId.OWNED_BY, title: 'Owned by', sortable: true, cell: (table) => table.ownedBy },
  { id: TablesAndGuestsColumnId.ALLOCATION_TYPE, title: 'Allocation type', sortable: true, cell: (table) => table.allocationType },
  { id: TablesAndGuestsColumnId.SEATS, title: 'Seats', sortable: true, cell: (table) => table.seats },
  { id: TablesAndGuestsColumnId.PAYMENT_PROVIDER, title: 'Payment provider', sortable: true, cell: (table) => table.paymentProvider },
  { id: TablesAndGuestsColumnId.ASSIGNED_BY, title: 'Assigned by', sortable: true, cell: (table) => table.assignedBy }
];

const getColumnValue = (column: TableColumn, table: ITableAndGuest): React.ReactNode => {
  return column.cell ? column.cell(table) : '';
};

interface TableAndGuestTableRowsProps {
  tables: ITableAndGuest[];
  onSuccess?: () => void;
}

const TablesAndGuestTableRows = ({ tables = [], onSuccess = () => null }: TableAndGuestTableRowsProps) => {
  const renderCellContent = (column: TableColumn, table: ITableAndGuest) => {
    return getColumnValue(column, table);
  };

  return (
    <>
      {tables.map((table) => (
        <TableRow key={table.id}>
          {tableAndGuestColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, table)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface TablesAndGuestsManagementTableProps {
  isLoading?: boolean;
  tables?: ITableAndGuest[];
  onSuccess?: () => void;
}

const TablesAndGuestsManagementTable = ({
  onSuccess = () => null,
  isLoading = false,
  tables = []
}: TablesAndGuestsManagementTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: TablesAndGuestsColumnId; direction: 'asc' | 'desc' }>({
    key: TablesAndGuestsColumnId.ZONE,
    direction: 'asc'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;

  const filterTables = tables.filter((table) =>
    tableAndGuestColumns.some((column) => {
      const value = getColumnValue(column, table);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const totalPages = Math.ceil(filterTables.length / itemsPerPage);
  const paginatedTablesAndGuests = filterTables.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const sortedTables = [...paginatedTablesAndGuests].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = tableAndGuestColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const isNumeric = !isNaN(Number(valueA)) && !isNaN(Number(valueB));

    if (isNumeric) {
      return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: TablesAndGuestsColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Manage tables</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Find Table by Zone, Number, Owner"
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<TablesAndGuestsColumnId> columns={tableAndGuestColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={tableAndGuestColumns.length}>
                    <CircularLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : tables.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={tableAndGuestColumns.length}>
                    <Stack textAlign="center">No tables and guests yet</Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TablesAndGuestTableRows onSuccess={onSuccess} tables={sortedTables} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Stack sx={{ alignItems: 'center', py: 2 }}>
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 3, alignSelf: 'center' }} />
        </Stack>
      </MainCard>
    </>
  );
};

export default TablesAndGuestsManagementTable;
