import { AppstoreAddOutlined, CheckOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { Partner } from 'types/partner';
import { Venue } from '../..';
import { useNavigate, useParams } from 'react-router';
import { ROUTE_VENUE } from 'constants/routes';
import assignFormSchema from 'schemas/assignFormSchema';

export interface Floorplan {
  id: string;
  venue_id: string;
  name: string;
  description: string;
  seatsio_chart_id: string;
}

interface FormProps {
  currentObject: Venue | null;
  modalToggler?: () => void;
}

const AssignPartnerForm = ({ currentObject, modalToggler = () => null }: FormProps) => {
  const [, setLoading] = useState(false);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [floorplans, setFloorplans] = useState<Floorplan[]>([]);
  const [isSuccess, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: { partner: {} as Partner, floorplan: '' },
    validationSchema: assignFormSchema,
    onSubmit: async (values) => {
      try {
        const { error } = await supabase
          .from('partner_floorplans')
          .insert({ partner_id: values.partner.id, floorplan_id: values.floorplan });

        if (error) {
          enqueueSnackbar('Failed to assign partner. Try again later.', { variant: 'error' });
        } else {
          modalToggler();
          enqueueSnackbar('Floorplan assigned success', { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong. Try again later.', { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    fetchPartners();
    fetchFloorplans();
  }, []);

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('partners').select('*').is('deleted_at', null);
      setPartners(data as Partner[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchFloorplans = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('floorplans').select('*');
      setFloorplans(data as Floorplan[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSelect = (event: React.SyntheticEvent<Element, Event>, value: string | Partner | null) => {
    if (value === null) {
      formik.setFieldValue('partner', {});
    } else if (typeof value === 'object') {
      formik.setFieldValue('partner', value);
    }
  };

  const handleFloorplanSelect = (event: React.SyntheticEvent, value: Floorplan | null) => {
    formik.setFieldValue('floorplan', value ? value.id : '');
  };

  const { touched, errors, getFieldProps } = formik;

  return (
    <Grid sx={{ paddingBlock: 3, paddingInline: 5 }}>
      {isSuccess ? (
        <>
          <div
            style={{
              margin: 'auto',
              borderRadius: '50%',
              backgroundColor: '#91DCBE',
              width: '54px',
              height: '54px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CheckOutlined
              style={{
                fontSize: '24px',
                height: '24px',
                color: '#000'
              }}
            />
          </div>
          <DialogContentText sx={{ mt: 1, textAlign: 'center', fontSize: '24px', fontWeight: 500, color: '#F0F0F0' }}>
            Confirmed
          </DialogContentText>
          <DialogContentText
            sx={{
              mt: 1,
              textAlign: 'center',
              fontSize: '16px',
              color: '#BFBFBF'
            }}
          >
            {formik.values.partner?.company_name} was successfully added to {currentObject?.name}
          </DialogContentText>
          <DialogContent sx={{ paddingInline: '1px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel sx={{ color: '#BFBFBF' }}>Select Floorplan</InputLabel>
                  <Autocomplete
                    id="floorplan"
                    options={floorplans}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={handleFloorplanSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Start typing..."
                        error={Boolean(touched.floorplan && errors.floorplan)}
                        helperText={touched.floorplan && errors.floorplan}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Typography sx={{ textAlign: 'center', fontSize: '14px', color: '#BFBFBF', my: 3 }}>Or</Typography>
            <Typography
              onClick={() => {
                navigate(`${ROUTE_VENUE.replace(':id', id!)}/new-floorplan/`);
              }}
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Create Floorplan
            </Typography>
            <Button
              onClick={() => formik.submitForm()}
              type="submit"
              variant="contained"
              sx={{ width: '100%', mt: 3, backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
              disabled={!formik.values.floorplan || Object.keys(formik.values.floorplan).length === 0}
            >
              Continue
            </Button>
          </DialogContent>
        </>
      ) : (
        <>
          <div
            style={{
              margin: 'auto',
              padding: '20px',
              borderRadius: '50%',
              backgroundColor: '#8057DB',
              width: '76px',
              height: '76px',
              border: '1px solid #434343'
            }}
          >
            <AppstoreAddOutlined
              style={{
                fontSize: '36px',
                height: '36px'
              }}
            />
          </div>
          <DialogTitle sx={{ fontSize: '24px', color: '#F0F0F0', textAlign: 'center' }}>Assign A Partner to this Venue</DialogTitle>
          <DialogContentText sx={{ color: '#BFBFBF', textAlign: 'center' }}>
            Find a certified partner on Cene to add to this Venue
          </DialogContentText>
          <DialogContent sx={{ paddingInline: '1px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Autocomplete
                    id="partner"
                    options={partners}
                    value={formik.values.partner || null}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.company_name || '')}
                    onChange={handleEmailSelect}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Add partner"
                        {...getFieldProps('partner')}
                        error={Boolean(touched.partner && errors.partner)}
                        helperText={touched.partner && errors.partner ? (errors.partner as { company_name: string }).company_name : ''}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF', textAlign: 'center' }}>
            This action is final and cannot be undone
          </DialogContentText>
          <DialogActions sx={{ paddingBlock: 3 }}>
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  onClick={modalToggler}
                  variant="outlined"
                  sx={{
                    color: '#fff',
                    borderColor: '#434343',
                    '&:hover': {
                      backgroundColor: '#121212',
                      color: '#fff',
                      borderColor: '#434343'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => setSuccess(true)}
                  variant="contained"
                  sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                  disabled={!formik.values.partner || Object.keys(formik.values.partner).length === 0}
                >
                  Add Partner to Venue
                </Button>
              </Stack>
            </Grid>
          </DialogActions>
        </>
      )}
    </Grid>
  );
};

export default AssignPartnerForm;
