import { Box, LinearProgress, Stack, Typography } from '@mui/material';

interface StatCardProps {
  icon: React.ReactNode;
  value: number | string;
  label: string;
  percentage?: number;
  progressLabel?: string;
  isPersantage: boolean;
  currency?: string;
}

export const StatCard = ({ icon, value, label, percentage, progressLabel, isPersantage, currency = '' }: StatCardProps) => {
  const formatNumber = (num: number): string => {
    return num.toLocaleString();
  };

  return (
    <Stack sx={{ flexDirection: 'column', gap: 1 }}>
      <Stack sx={{ flexDirection: 'row', gap: 3 }}>
        <Box
          sx={{
            width: '44px',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            backgroundColor: '#B4A2DC'
          }}
        >
          {icon}
        </Box>
        <Stack>
          <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
            {formatNumber(Number(value))} {currency}
          </Typography>
          <Typography sx={{ fontSize: '12px', color: '#8C8C8C' }}>{label}</Typography>
        </Stack>
      </Stack>
      {isPersantage && percentage && (
        <Stack sx={{ justifyContent: 'space-between', gap: 1 }}>
          <Typography sx={{ fontSize: '12px' }}>{progressLabel}</Typography>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <LinearProgress
              variant="determinate"
              value={percentage}
              sx={{
                width: '90%',
                height: '10px',
                backgroundColor: '#E0E0E0',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#9977E2'
                }
              }}
            />
            <Typography>{percentage.toFixed(1)}%</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
