import { PlusOutlined } from '@ant-design/icons';
import { Button, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useState } from 'react';
import GuestlistForm from './guesrlist.form';
import ReusableModal from 'components/modal/modal';
import { Event, EventStatus } from '@ceneteam/namespace';
import { IGuest } from '.';
import { format } from 'date-fns';

// export interface IGuest {
//   firstName: string;
//   lastName: string;
//   ticket_type: string;
//   ticket_count: string;
//   date_assigned: string;
//   invited_by: string;
//   status: string;
//   id: string;
// }

export enum GuestColumnId {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TICKET_TYPE = 'ticketType',
  TICKET_COUNT = 'ticketCount',

  DATE_ASSIGNED = 'dateAssigned',
  ASSIGNED_BY = 'assignedBy'
}

interface TableColumn {
  id: GuestColumnId;
  title: string;
  sortable: boolean;
  cell?: (guest: IGuest) => React.ReactNode;
}

const guestColumns: TableColumn[] = [
  { id: GuestColumnId.FIRST_NAME, title: 'First name', sortable: true, cell: (guest) => guest.firstName },
  { id: GuestColumnId.LAST_NAME, title: 'Last name', sortable: true, cell: (guest) => guest.lastName },
  {
    id: GuestColumnId.TICKET_TYPE,
    title: 'Ticket type',
    sortable: true,
    cell: (guest) => guest.tickets.map((ticket) => ticket.ticket_type).join(', ')
  },
  {
    id: GuestColumnId.TICKET_COUNT,
    title: 'Ticket count',
    sortable: true,
    cell: (guest) => guest.tickets.map((ticket) => ticket.ticket_count).join(', ')
  },

  {
    id: GuestColumnId.DATE_ASSIGNED,
    title: 'Date assigned',
    sortable: true,
    cell: (guest) => format(new Date(guest.date_assigned), 'dd.MM.yyyy HH:mm:ss')
  },
  { id: GuestColumnId.ASSIGNED_BY, title: 'Assigned by', sortable: true, cell: (guest) => guest.invited_by }
];

const getColumnValue = (column: TableColumn, guest: IGuest): React.ReactNode => {
  return column.cell ? column.cell(guest) : '';
};

interface GuestTableRowsProps {
  guests: IGuest[];
  onSuccess?: () => void;
}

const GuestTableRows = ({ guests = [], onSuccess = () => null }: GuestTableRowsProps) => {
  const renderCellContent = (column: TableColumn, guest: IGuest) => {
    return getColumnValue(column, guest);
  };

  return (
    <>
      {guests.map((guest) => (
        <TableRow key={guest.id}>
          {guestColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, guest)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface GuestsTableProps {
  isLoading?: boolean;
  event: Event;
  guests?: IGuest[];
  onSuccess?: () => void;
}

const GuestlistsTable = ({ onSuccess = () => null, event, isLoading = false, guests = [] }: GuestsTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: GuestColumnId; direction: 'asc' | 'desc' }>({
    key: GuestColumnId.FIRST_NAME,
    direction: 'asc'
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 40;

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const { open, modalToggler } = useModalContext();

  const filterGuests = guests.filter((guest) =>
    guestColumns.some((column) => {
      const value = getColumnValue(column, guest);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const totalPages = Math.ceil(filterGuests.length / itemsPerPage);

  const sortedGuests = [...filterGuests].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = guestColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const isDateField = ['date_assigned'].includes(key);

    if (isDateField) {
      const dateA = new Date(valueA as string);
      const dateB = new Date(valueB as string);

      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: GuestColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  // Slice the sorted and filtered guests to show only the current page's items
  const currentGuests = sortedGuests.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>Ticket Assignments List</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search guest..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
        <Button
          disabled={event?.status !== EventStatus.Published}
          onClick={() => modalToggler(null)}
          variant="contained"
          sx={{
            backgroundColor: '#8057DB',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '&:hover': { backgroundColor: '#8157dbab' }
          }}
        >
          <PlusOutlined />
          Create Assignment
        </Button>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<GuestColumnId> columns={guestColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={guestColumns.length}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : guests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={guestColumns.length}>
                  <Stack textAlign="center">No Guestlists yet</Stack>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                <GuestTableRows onSuccess={onSuccess} guests={currentGuests} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
        />
      </MainCard>
      <ReusableModal currentObject={null} open={open} modalToggler={() => modalToggler(null)} content={GuestlistForm} width="488px" />
    </>
  );
};

export default GuestlistsTable;
