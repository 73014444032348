// import { Divider } from '@mui/material';
import SalesAndTransactionsTable from './table';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CircularLoader from 'components/CircularLoader';
import { Stack, Pagination, Typography } from '@mui/material';
import { fetchCustomerName } from 'supabase/util';
import { Partner } from 'types/partner';
import { LocalOrderDetails } from '@ceneteam/namespace';
import ReusableTabs from 'components/tabs/reusableTabs';
import { DebouncedInput } from 'components/third-party/react-table';
import MainCard from 'components/MainCard';

export interface Ticket {
  quantity: number;
  ticket_name: string;
  ticket_name_id: string;
  ticket_is_accessible: boolean | null;
  ticket_description: string;
}

export interface Transaction {
  id: string;
  customer_data: { id: string; first_name: string; last_name: string };
  customer_name?: string;
  orderDate: string;
  tickets: Ticket[];
  amount: number;
  currency: string;
  status: string;
  payment: string;
  tables: string;
  updated_at: string;
  partners: Partner;
  finance_details: LocalOrderDetails;
  provider: string;
}

enum ITab {
  ALL = 'All',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  PENDING = 'Pending'
}

const SalesAndTransactionsPage = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState('');
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, filter]);

  const itemsPerPage = 40;
  const { id } = useParams();

  useEffect(() => {
    if (id) fetchTransactionsById();
  }, [id, currentPage, activeTab, filter]);

  const fetchTransactionsById = async () => {
    try {
      setLoading(true);

      // Fetch transactions with pagination
      let query = supabase
        .from('transactions')
        .select('*, details_data, ticket_ids', { count: 'exact' })
        .eq('details_data->>event_id', id)
        .range((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage - 1);

      if (activeTab !== ITab.ALL) {
        if (activeTab === ITab.SUCCESS) {
          query = query.eq('status', 'success');
        } else if (activeTab === ITab.FAILED) {
          query = query.eq('status', 'failed');
        } else if (activeTab === ITab.PENDING) {
          query = query.eq('status', 'pending');
        }
      }

      const { data: transactions, error, count } = await query;

      if (error) throw error;

      setTotalItems(count || 0);

      if (!transactions || transactions.length === 0) {
        setTransactions([]);
        return;
      }

      // Extract ticket IDs from transactions
      const ticketIds = transactions.flatMap((transaction) => transaction.ticket_ids || []);
      if (ticketIds.length === 0) {
        setTransactions([]);
        return;
      }

      const ticketIdsByTransaction = transactions.reduce<Record<string, Set<string>>>((acc, transaction) => {
        const ticketIds = transaction.ticket_ids || [];
        acc[transaction.id] = new Set(ticketIds);
        return acc;
      }, {});

      // Fetch tickets by ticket IDs
      const { data: tickets, error: ticketsError } = await supabase.from('tickets').select('*').in('id', ticketIds);

      if (ticketsError) throw ticketsError;

      // Group tickets by event, transaction, zone, and table
      const ticketsByEventAndTransaction = Object.keys(ticketIdsByTransaction).reduce<
        Record<string, Record<string, Record<string, Set<string>>>>
      >((acc, transactionId) => {
        const validTicketIds = ticketIdsByTransaction[transactionId] || new Set();
        const filteredTickets = tickets?.filter((ticket) => validTicketIds.has(ticket.id)) || [];

        filteredTickets.forEach((ticket) => {
          const eventId = ticket.event_id;
          const zoneId = ticket?.zone_id || 'Unknown Zone';
          const tableId = ticket?.table_id || 'Unknown Table';

          if (!acc[transactionId]) acc[transactionId] = {};
          if (!acc[transactionId][eventId]) acc[transactionId][eventId] = {};
          if (!acc[transactionId][eventId][zoneId]) acc[transactionId][eventId][zoneId] = new Set();

          acc[transactionId][eventId][zoneId].add(tableId);
        });

        return acc;
      }, {});

      const generateTicketsWithoutZones = (transactionId: string) => {
        const validTicketIds = ticketIdsByTransaction[transactionId] || new Set();
        const filteredTickets = tickets?.filter((ticket) => validTicketIds.has(ticket.id)) || [];
        return filteredTickets.map((ticket) => ticket.title || 'Unnamed Ticket').join(', ');
      };

      // Process data for rendering
      const processedData = await Promise.all(
        transactions.map(async (transaction) => {
          const customerName = await fetchCustomerName(transaction.customer_id);
          const ticketsWithoutZones = generateTicketsWithoutZones(transaction.id);
          // Generate table-zone mapping string
          const tableZoneMapString = Object.entries(ticketsByEventAndTransaction[transaction.id]?.[transaction.details_data.event_id] || {})
            .map(([zoneId, tableIds]) => {
              const tableIdsArray = Array.isArray(tableIds) ? tableIds : Array.from(tableIds || []);
              return `${zoneId}: ${tableIdsArray.join(', ')}`;
            })
            .join('; ');

          return {
            id: transaction.id,
            customer_data: transaction.customer_data || {},
            customer_name: customerName || '',
            orderDate: transaction.created_at,
            tickets: transaction.details_data?.tickets || [],
            amount: transaction.amount,
            currency: transaction.currency,
            status: transaction.status,
            payment: transaction.provider,
            tables: transaction.type === 'cene' ? ticketsWithoutZones : tableZoneMapString
          };
        })
      );

      setTransactions(processedData as Transaction[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const filterTransactions = () => {
    return transactions.filter((transaction) => {
      // Filter by status
      if (activeTab !== ITab.ALL && transaction.status.toLowerCase() !== activeTab.toLowerCase()) {
        return false;
      }

      // Create a string that combines customer name and transaction ID for search
      const searchableString = `${transaction.customer_name} ${transaction.id}`.toLowerCase();

      return searchableString.includes(filter.toLowerCase());
    });
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return isLoading ? (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'center', mt: 4 }}>
      <CircularLoader />
    </Stack>
  ) : (
    <Stack sx={{ py: 3 }}>
      {/* <SalesAndTransactionsStatistic />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} /> */}{' '}
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Sales and transactions</Typography>
      </Stack>
      <MainCard content={false}>
        <Stack sx={{ padding: 3 }}>
          <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} color="#8057DB" />
        </Stack>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 3.5, pl: 3 }}>
          <Stack width={300}>
            <DebouncedInput
              value={filter ?? ''}
              onFilterChange={(value) => setFilter(String(value))}
              placeholder="Search transaction..."
              sx={{ width: '300px !important' }}
            />
          </Stack>
        </Stack>
        <SalesAndTransactionsTable transactions={filterTransactions()} />
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
        />
      </MainCard>
    </Stack>
  );
};

export default SalesAndTransactionsPage;
