import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';

export enum FansColumnId {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  USER_TYPE = 'userType',
  FOLLOWED_AT = 'followedAt',

  BOUGHT_TICKETS = 'boughtTickets',
  GENDER = 'gender',
  DATE_OF_BIRTH = 'Date of Birth'
}

const sampleData = [
  {
    firstName: 'John',
    lastName: 'Doe',
    userType: 'Cene+',
    followedAt: '01.12.2024',
    boughtTickets: 3,
    gender: 'Male',
    dateOfBirth: '15.05.1990'
  },
  {
    firstName: 'Jane',
    lastName: 'Smith',
    userType: 'Cene',
    followedAt: '03.12.2024',
    boughtTickets: 1,
    gender: 'Female',
    dateOfBirth: '22.08.1992'
  },
  {
    firstName: 'Alice',
    lastName: 'Johnson',
    userType: 'Cene+',
    followedAt: '20.12.2024',
    boughtTickets: 2,
    gender: 'Female',
    dateOfBirth: '30.03.1988'
  },
  {
    firstName: 'Bob',
    lastName: 'Williams',
    userType: 'Cene',
    followedAt: '20.12.2024',
    boughtTickets: 5,
    gender: 'Male',
    dateOfBirth: '11.11.1985'
  }
];

const FansTable = () => {
  const columns = [
    { id: FansColumnId.FIRST_NAME, title: 'First Name' },
    { id: FansColumnId.LAST_NAME, title: 'Last Name' },
    { id: FansColumnId.USER_TYPE, title: 'User Type' },
    { id: FansColumnId.FOLLOWED_AT, title: 'Followed At' },
    { id: FansColumnId.BOUGHT_TICKETS, title: 'Bought Tickets' },
    { id: FansColumnId.GENDER, title: 'Gender' },
    { id: FansColumnId.DATE_OF_BIRTH, title: 'Date of Birth' }
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Fans Table</Typography>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sampleData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.firstName}</TableCell>
                  <TableCell>{data.lastName}</TableCell>
                  <TableCell>{data.userType}</TableCell>
                  <TableCell>{data.followedAt}</TableCell>
                  <TableCell>{data.boughtTickets}</TableCell>
                  <TableCell>{data.gender}</TableCell>
                  <TableCell>{data.dateOfBirth}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>
    </Stack>
  );
};

export default FansTable;
