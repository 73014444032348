import { Formik } from 'formik';
import NameStep from './nameStep';
import { useNavigate, useParams } from 'react-router';
import floorplanFormSchema from 'schemas/floorplanFormSchema';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { seatsioInstance } from 'api/seatsio';
import { ROUTE_FLOORPLAN_EDITOR } from 'constants/routes';
import { useState } from 'react';

export interface Table {
  id: string;
  tablesType: string;
  numberOfSeatsPerTable: string;
}

export interface Zone {
  id: number;
  zoneName: string;
  tablesInZone: string;
  tableShapes: string;
  zoneColor: string;
  tablesType: string;
  numberOfSeatsPerTable: string;
  tables: Table[];
  seatsIdentification: string;
}

export interface FormValues {
  name: string;
  description: string;
  zones: Zone[];
  hasStage: boolean;
  includeBestAvailableSeats: boolean;
}

const FloorplanForm = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);

      const { data } = await seatsioInstance.post('/seatsio/charts', {
        name: values.name,
        venueType: 'MIXED'
      });

      await supabase
        .from('floorplans')
        .insert({ id: data.key, venue_id: id, name: values.name, description: values.description })
        .throwOnError();

      enqueueSnackbar('Floorplan created successfully', { variant: 'success' });
      navigate(ROUTE_FLOORPLAN_EDITOR.replace(':id', data.key));
    } catch (error) {
      enqueueSnackbar('Something went wrong. Try again later.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik<FormValues>
      initialValues={{
        name: '',
        description: '',
        zones: [],
        hasStage: false,
        includeBestAvailableSeats: false
      }}
      validationSchema={floorplanFormSchema}
      onSubmit={() => {}}
    >
      {(formik) => {
        return <NameStep isLoading={isLoading} formik={formik} onNext={() => onSubmit(formik.values)} />;
      }}
    </Formik>
  );
};

export default FloorplanForm;
