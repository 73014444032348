import { Alert, Button, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { EventSchema } from '../event.schema';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker, pickersLayoutClasses } from '@mui/x-date-pickers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ArtistCard } from 'components/artistCard/artistCard';
import ShapeButton from 'components/button/button';
import dayjs from 'dayjs';

interface IEventLineup {
  onNext: () => void;
  onPrev: () => void;
  isNew: boolean;
}

export const EventLineup = ({ onNext, onPrev, isNew }: IEventLineup) => {
  const formik = useFormikContext<EventSchema>();
  const inputRef = useRef<HTMLInputElement>();
  const [timeError, setTimeError] = useState<string | null>(null);

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];
  const errors = formik.errors[formType];

  const validationStepForMultipleTypes =
    formik.values.eventType === 'both' &&
    Boolean(
      formik.values.cene.line_up.length === 0 ||
        formik.values['cene+'].line_up.length === 0 ||
        formik.errors.cene?.line_up ||
        formik.errors['cene+']?.line_up
    );

  const validationStep = Boolean(errors?.line_up);

  const cenePlusValidation = Boolean(formik.values['cene+'].line_up.length === 0 || formik.errors['cene+']?.line_up);

  const ceneValidation = Boolean(formik.values.cene.line_up.length === 0 || formik.errors.cene?.line_up);

  const handleAddArtist = (e: any) => {
    if (!inputRef.current) return;

    e.preventDefault();

    values.line_up.push({
      id: uuidv4(),
      starts_at: '',
      title: inputRef.current.value,
      thumbnail_url: [],
      isWithoutTime: false,
      isDoorsClose: false,
      uploadedPictures: []
    });

    formik.setFieldValue(`${formType}.line_up`, values.line_up);

    inputRef.current.value = '';
  };

  const handleAddDoors = () => {
    values.line_up.push({
      id: uuidv4(),
      starts_at: '',
      title: 'Doors close',
      thumbnail_url: [],
      isWithoutTime: false,
      isDoorsClose: true,
      uploadedPictures: []
    });

    formik.setFieldValue(`${formType}.line_up`, values.line_up);
  };

  const renderLineup = values.line_up.map((item, i) => (
    <div key={item.id}>
      <ArtistCard id={item.id as string} />
    </div>
  ));

  const isDoorsCloseLineUp = values.line_up.find((item) => item.isDoorsClose);

  const validateTime = (startTime: Date | null, endTime: Date | null): string | null => {
    if (!startTime || !endTime) return null;

    const diffInMinutes = (endTime.getTime() - startTime.getTime()) / 60000;

    if (diffInMinutes < 30) {
      return 'The interval between Doors Open and Last Entry must be at least 30 minutes.';
    } else if (endTime <= startTime) {
      return 'Last Entry time must be later than Doors Open time.';
    }
    return null;
  };

  const handleDoorsOpenChange = (newValue: Date | null) => {
    const lastEntry = values.lineup_end_at ? new Date(values.lineup_end_at) : null;
    const error = validateTime(newValue, lastEntry);

    if (error) {
      setTimeError(error);
    } else {
      setTimeError(null);
    }

    formik.setFieldValue(`${formType}.lineup_srart_at`, newValue?.toISOString() || null);
  };

  const handleLastEntryChange = (newValue: Date | null) => {
    const doorsOpen = values.lineup_srart_at ? new Date(values.lineup_srart_at) : null;
    const error = validateTime(doorsOpen, newValue);

    if (error) {
      setTimeError(error);
    } else {
      setTimeError(null);
    }

    formik.setFieldValue(`${formType}.lineup_end_at`, newValue?.toISOString() || null);
  };

  useEffect(() => {
    if (values.lineup_srart_at && values.lineup_end_at) {
      const doorsOpen = new Date(values.lineup_srart_at);
      const lastEntry = new Date(values.lineup_end_at);
      const error = validateTime(doorsOpen, lastEntry);

      if (error) {
        setTimeError(error);
      } else {
        setTimeError(null);
      }
    }
  }, [values.lineup_srart_at, values.lineup_end_at]);

  const parseDate = (date: string) => {
    const parsed = dayjs(date, 'DD.MM.YYYY HH:mm');
    return parsed.isValid() ? parsed.toDate() : null;
  };

  const defaultDoorsOpen = useMemo(() => {
    if (values.starts_at) {
      const startsAt = parseDate(values.starts_at);
      return startsAt ? new Date(startsAt) : null;
    }
    return new Date();
  }, [values.starts_at]);

  const defaultLastEntry = useMemo(() => {
    if (values.ends_at) {
      const endsAt = parseDate(values.ends_at);
      return endsAt ? new Date(endsAt) : null;
    }
    return new Date();
  }, [values.ends_at]);

  useEffect(() => {
    if (!values.lineup_srart_at && defaultDoorsOpen) {
      const updatedStartDate = new Date(defaultDoorsOpen);
      const startsAt = parseDate(values.starts_at) || new Date();
      updatedStartDate.setFullYear(startsAt.getFullYear(), startsAt.getMonth(), startsAt.getDate());
      formik.setFieldValue(`${formType}.lineup_srart_at`, updatedStartDate.toISOString());
    }

    if (!values.lineup_end_at && defaultLastEntry) {
      const updatedEndDate = new Date(defaultLastEntry);
      const endsAt = parseDate(values.ends_at) || new Date();
      updatedEndDate.setFullYear(endsAt.getFullYear(), endsAt.getMonth(), endsAt.getDate());
      formik.setFieldValue(`${formType}.lineup_end_at`, updatedEndDate.toISOString());
    }
  }, [
    formik,
    formType,
    values.lineup_srart_at,
    values.lineup_end_at,
    defaultDoorsOpen,
    defaultLastEntry,
    values.starts_at,
    values.ends_at
  ]);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Doors and Lineup
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Time is a precious resource! Share the accurate timing details and performer lineup to ensure {formik.values.name} is a success.
      </Typography>
      <Stack mt={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack gap={2} alignItems="baseline" flexDirection="row">
            <Stack spacing={1}>
              <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                Doors Open
              </InputLabel>
              <TimePicker
                value={new Date(values.lineup_srart_at)}
                onChange={handleDoorsOpenChange}
                format="HH:mm"
                slotProps={{
                  popper: {
                    sx: {
                      [`.${pickersLayoutClasses.root}`]: {
                        marginBlock: '12px'
                      }
                    }
                  }
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                Last Entry
              </InputLabel>
              <TimePicker
                value={values.lineup_end_at ? new Date(values.lineup_end_at) : new Date()}
                onChange={handleLastEntryChange}
                format="HH:mm"
                slotProps={{
                  popper: {
                    sx: {
                      [`.${pickersLayoutClasses.root}`]: {
                        marginBlock: '12px'
                      }
                    }
                  }
                }}
              />
            </Stack>
          </Stack>
        </LocalizationProvider>
      </Stack>

      {timeError && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          {timeError}
        </Alert>
      )}

      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }} htmlFor="date">
          Add Performer to this Event
        </InputLabel>

        <form onSubmit={handleAddArtist}>
          <TextField inputRef={inputRef} fullWidth placeholder={'To add a performer, enter a name and press Enter'} />
        </form>
      </Stack>

      {values.line_up?.length >= 1 && (
        <Stack mt={2} gap={2}>
          {renderLineup}
          <Button
            disabled={Boolean(isDoorsCloseLineUp)}
            sx={{
              marginTop: '10px'
            }}
            onClick={handleAddDoors}
          >
            Add Doors close time
          </Button>
        </Stack>
      )}
      {formik.values.eventType === 'both' && ((formType === 'cene' && cenePlusValidation) || (formType === 'cene+' && ceneValidation)) && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          To continue, you need to fix the errors in the {formType === 'cene' ? 'Cene+' : 'Cene'} form
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={validationStepForMultipleTypes || validationStep || Boolean(timeError)} handleClick={onNext} width="100%">
          {isNew ? 'Next step' : 'Submit'}
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
