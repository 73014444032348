import { useEffect, useState } from 'react';
import { OrderDetails } from '@ceneteam/namespace';
import { Cart } from '.';
import { supabase } from 'supabase/supabase';
import { Event } from '@ceneteam/namespace';
import { Autocomplete, Button, DialogContent, DialogTitle, Divider, Stack, TextField, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import ReusableModal from 'components/modal/modal';
import { ReactComponent as LoaderSVG } from '../../../svg/Loader.svg';
import { formatPriceString } from 'utils/pricing';
import { FeeItem } from './fees';
import { User } from 'types/user';
import { enqueueSnackbar } from 'notistack';

interface ICartModal {
  cart: Cart[];
  event: Event | null;
  onCloseModal: () => void;
  onRemove: (id: string) => void;
  onClearCart: () => void;
}

export const CartModal = ({ cart, onClearCart, event, onRemove, onCloseModal }: ICartModal) => {
  const [isLoading, setLoading] = useState(true);
  const [isCheckout, setCheckout] = useState(false);
  const [orderInformation, setOrderInformation] = useState<OrderDetails | null>(null);
  const [customers, setCustomers] = useState<User[]>([]);
  const [assignUser, setAssignUser] = useState<User | null>(null);

  useEffect(() => {
    if (cart.length === 0) onCloseModal();

    fetchTicketsInformation();
  }, [cart]);

  const onSendPayment = async () => {
    setCheckout(true);

    const filterTables = cart.map((item) =>
      item.selectableObject.objectType === 'Table'
        ? {
            ticket_name_id: item.id,
            full_table: true,
            table_id: item.selectableObject.labels.own
          }
        : {
            ticket_name_id: item.id,
            table_id: item.selectableObject.labels.parent,
            seat_ids: [item.selectableObject.labels.own]
          }
    ) as any;

    if (!event || !assignUser) return;

    try {
      const { data, error } = await supabase.rpc('start_transaction', {
        _event_id: event.id,
        tickets_to_purchase: filterTables,
        parameters: {
          currency: 'NGN',
          provider: 'dashboard',
          customer_id: assignUser.id,
          customer_app: assignUser.app
        }
      });

      if (error) throw Error(error.message);

      await supabase.from('transactions').update({ status: 'success' }).eq('id', data.transaction_id).throwOnError();

      enqueueSnackbar(`Booked successfully`, {
        variant: 'success',
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom'
        }
      });

      onCloseModal();
      onClearCart();
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom'
          }
        });
      }

      console.error('Error:', error);
    } finally {
      setCheckout(false);
    }
  };

  const fetchTicketsInformation = async () => {
    if (!event) return;

    try {
      setLoading(true);

      const { data, error } = await supabase.rpc('build_dashboard_event_order_finance_details', {
        currency: 'NGN',
        event_id: event.id,
        // rpc call falls if no users selected, so we use a dummy app
        customer_app: assignUser?.app || 'cene+',
        ordered_tickets: cart.map((item) => ({
          ticket_name_id: item.id,
          quantity: item.quantity
        }))
      });

      const { data: customersData } = await supabase.from('customers').select('*').eq('app', 'cene+');

      setCustomers((customersData || []) as User[]);

      if (error) throw error;

      setOrderInformation(data as OrderDetails);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renderTablesItems = cart
    .filter((item) => item.selectableObject.objectType === 'Table')
    .map((item) => (
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" key={item.id}>
        <Stack flexDirection="row" gap={2}>
          <div>{item.selectableObject.labels.section}</div>
          <div>Table {item.selectableObject.labels.own}</div>
          <div>Seats {(item.selectableObject as any).seats.map((_: any, i: any) => i + 1).join(',')}</div>
        </Stack>
        <Button
          color="error"
          onClick={() => onRemove((item.selectableObject as any).seatId)}
          className="w-[32px] h-[32px] border border-gray-medium rounded-tl-[6px] rounded-br-[6px] flex justify-center items-center"
        >
          Remove
        </Button>
      </Stack>
    ));

  const renderSeatsItems = cart
    .filter((item) => item.selectableObject.objectType === 'Seat')
    .map((item) => (
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" key={item.id}>
        <Stack flexDirection="row" gap={2}>
          <div>{item.selectableObject.labels.section}</div>
          <div>Table {item.selectableObject.labels.parent}</div>
          <div>Seat {item.selectableObject.labels.own}</div>
        </Stack>
        <Button
          color="error"
          onClick={() => onRemove((item.selectableObject as any).seatId)}
          className="w-[32px] h-[32px] border border-gray-medium rounded-tl-[6px] rounded-br-[6px] flex justify-center items-center"
        >
          Remove
        </Button>
      </Stack>
    ));

  const spinAnimation = {
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(360deg)'
      }
    },
    animation: 'spin 1s linear infinite'
  };

  const fees = [
    {
      label: 'Convenience Fee',
      amount: orderInformation?.service_fee.value
    },
    {
      label: 'Order Processing Fee',
      amount: 0
    },
    {
      label: 'VAT',
      amount: orderInformation?.tax.value
    }
  ];

  const modalContent = () => (
    <>
      <DialogTitle>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Stack>Selected seats & tables</Stack>
          <Button variant="outlined" onClick={onCloseModal}>
            Close
          </Button>
        </Stack>
      </DialogTitle>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogContent sx={{ p: 2.5 }}>
        <>
          {renderTablesItems.length !== 0 && (
            <>
              <div className="mt-[16px]">
                <div className="flex items-center gap-[10px]">
                  <Typography fontSize="18px">{renderTablesItems.length}x Table</Typography>
                </div>
                <div className="flex flex-col mt-[10px] gap-1">{renderTablesItems}</div>
              </div>
              <Divider sx={{ marginBottom: '20px' }} />
            </>
          )}

          {renderSeatsItems.length !== 0 && (
            <>
              <div className="mt-[16px]">
                <div className="flex items-center gap-[10px]">
                  <Typography fontSize="18px">{renderSeatsItems.length} Seats</Typography>
                </div>
                <div className="flex flex-col mt-[10px] gap-1">{renderSeatsItems}</div>
              </div>
              <Divider sx={{ marginBottom: '20px' }} />
            </>
          )}

          {isLoading ? (
            <Stack
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              <Stack sx={{ ...spinAnimation }}>
                <LoaderSVG />
              </Stack>
            </Stack>
          ) : (
            <>
              <Typography fontSize="18px">Fees</Typography>
              {fees.map((fee, index) => (
                <FeeItem key={index} label={fee.label} amount={`${fee.amount}`} />
              ))}
              <Divider sx={{ marginBottom: '20px' }} />
              <Typography fontSize="18px" mb={2}>
                Summary
              </Typography>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography>Amount</Typography>
                <Typography>{`₦ ${formatPriceString(orderInformation?.total_net_amount || 0)}`}</Typography>
              </Stack>
              <Stack mb={1} flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography>Total</Typography>
                <Typography fontSize="22px">{`₦ ${formatPriceString(orderInformation?.total_gross_amount || 0)}`}</Typography>
              </Stack>
              <Autocomplete
                disablePortal
                fullWidth
                value={assignUser}
                onChange={(e, value) => setAssignUser(value)}
                options={customers
                  .filter((item) => item.first_name)
                  .map((item) => ({
                    label: `${item.first_name} ${item.last_name}`,
                    ...item
                  }))}
                sx={{ marginBottom: '20px', marginTop: '20px' }}
                renderInput={(params) => <TextField {...params} label="Assign to customer" />}
              />

              <ShapeButton
                disabled={!assignUser || isCheckout}
                sx={{
                  paddingInline: '20px'
                }}
                backgroundColor="#B6A5DC"
                bgHover="#9b8cbd"
                handleClick={() => onSendPayment()}
                width="100%"
                color="black"
              >
                {isCheckout ? 'Processing...' : 'Book tickets'}
              </ShapeButton>
            </>
          )}
        </>
      </DialogContent>
    </>
  );

  return <ReusableModal currentObject={null} open={true} modalToggler={() => {}} content={modalContent} width="520px" />;
};
