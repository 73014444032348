import { EyeInvisibleOutlined, EyeOutlined, TeamOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {
  Collapse,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { Fragment, useState } from 'react';
import { ReactComponent as EventsSVG } from 'svg/events.svg';

const eventData = [
  {
    event: 'Concert of the Year',
    organiser: 'MusicFest',
    'event date': '2024-10-10',
    guests: 2,
    'ticket types': ['Standard', 'VIP', 'Backstage'],
    guestsDetails: [
      {
        first_name: 'John',
        last_name: 'Doe',
        email: 'john.doe@example.com',
        user_type: 'Cene',
        ticket_type: 'VIP',
        invite_sent: '2024-09-01',
        status: 'Received'
      },
      {
        first_name: 'Jane',
        last_name: 'Smith',
        email: 'jane.smith@example.com',
        user_type: 'Cene+ Guest',
        ticket_type: 'Standard',
        invite_sent: '2024-09-05',
        status: 'Expired'
      }
    ]
  },
  {
    event: 'Art Exhibition',
    organiser: 'Gallery 21',
    'event date': '2024-10-12',
    guests: 2,
    'ticket types': ['General Admission', 'Premium'],
    guestsDetails: [
      {
        first_name: 'Alice',
        last_name: 'Johnson',
        email: 'alice.johnson@example.com',
        user_type: 'Cene+',
        ticket_type: 'Premium',
        invite_sent: '2024-09-07',
        status: 'Received'
      },
      {
        first_name: 'Bob',
        last_name: 'Williams',
        email: 'bob.williams@example.com',
        user_type: 'Cene',
        ticket_type: 'General Admission',
        invite_sent: '2024-09-10',
        status: 'Expired'
      }
    ]
  },
  {
    event: 'Tech Conference',
    organiser: 'InnovateTech',
    'event date': '2024-10-14',
    guests: 2,
    'ticket types': ['Early Bird', 'Standard', 'VIP'],
    guestsDetails: [
      {
        first_name: 'David',
        last_name: 'Miller',
        email: 'david.miller@example.com',
        user_type: 'Cene',
        ticket_type: 'Standard',
        invite_sent: '2024-09-12',
        status: 'Expired'
      },
      {
        first_name: 'Eve',
        last_name: 'Thompson',
        email: 'eve.thompson@example.com',
        user_type: 'Cene+',
        ticket_type: 'VIP',
        invite_sent: '2024-09-13',
        status: 'Received'
      }
    ]
  },
  {
    event: 'Food Festival',
    organiser: 'TasteFest',
    'event date': '2024-10-16',
    guests: 2,
    'ticket types': ['General Admission', 'VIP Table'],
    guestsDetails: [
      {
        first_name: 'Frank',
        last_name: 'White',
        email: 'frank.white@example.com',
        user_type: 'Cene Guest',
        ticket_type: 'General Admission',
        invite_sent: '2024-09-15',
        status: 'Received'
      },
      {
        first_name: 'Grace',
        last_name: 'Black',
        email: 'grace.black@example.com',
        user_type: 'Cene+ Guest',
        ticket_type: 'VIP Table',
        invite_sent: '2024-09-16',
        status: 'Expired'
      }
    ]
  },
  {
    event: 'Comedy Night',
    organiser: 'Laugh Out Loud',
    'event date': '2024-10-18',
    guests: 2,
    'ticket types': ['Standard', 'VIP'],
    guestsDetails: [
      {
        first_name: 'Henry',
        last_name: 'Green',
        email: 'henry.green@example.com',
        user_type: 'Cene',
        ticket_type: 'Standard',
        invite_sent: '2024-09-18',
        status: 'Expired'
      },
      {
        first_name: 'Isla',
        last_name: 'Blue',
        email: 'isla.blue@example.com',
        user_type: 'Cene+',
        ticket_type: 'VIP',
        invite_sent: '2024-09-19',
        status: 'Received'
      }
    ]
  }
];

const Guests = () => {
  const [filter, setFilter] = useState('');
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});

  const toggleRow = (index: number) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Received':
        return '#83ff83';
      case 'Expired':
        return '#ffe482';

      default:
        return '#969696';
    }
  };

  const filteredRows = eventData.filter((event) => event.event.toLowerCase().includes(filter.toLowerCase()));

  return (
    <MainCard title="Guests">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce title="Total Events" count={eventData.length.toString()} icon={<EventsSVG />} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce
            title="Total Organisers"
            count={new Set(eventData.map((event) => event.organiser)).size.toString()}
            icon={<TeamOutlined style={{ fontSize: '32px', color: '#c118ff' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce
            title="Total Guests"
            count={eventData.reduce((acc, event) => acc + event.guests, 0).toString()}
            icon={<UsergroupAddOutlined style={{ fontSize: '32px', color: '#ffcd18' }} />}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} marginTop="16px">
        <DebouncedInput
          fullWidth
          value={filter ?? ''}
          onFilterChange={(value) => setFilter(String(value))}
          placeholder="Search for an event"
        />
      </Grid>
      <TableContainer sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead sx={{ backgroundColor: 'transparent' }}>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Organiser</TableCell>
              <TableCell>Event Date</TableCell>
              <TableCell>Guests</TableCell>
              <TableCell>Ticket Types</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length > 0 ? (
              filteredRows.map((row, index) => (
                <Fragment key={index}>
                  <TableRow
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent !important'
                      }
                    }}
                  >
                    <TableCell>{row.event}</TableCell>
                    <TableCell>{row.organiser}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row['event date']}</TableCell>
                    <TableCell>{row.guests}</TableCell>
                    <TableCell sx={{ maxWidth: '70px' }}>{row['ticket types'].join(', ')}</TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="view" onClick={() => toggleRow(index)}>
                        {openRows[index] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent !important'
                      }
                    }}
                  >
                    <TableCell colSpan={6} sx={{ paddingBlock: 0, paddingInline: '0 !important' }}>
                      <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                        <Table
                          size="small"
                          aria-label="guest-details"
                          sx={{
                            borderBottom: '1px solid #91DCBE',

                            borderCollapse: 'collapse'
                          }}
                        >
                          <TableHead>
                            <TableRow
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'transparent !important'
                                }
                              }}
                            >
                              <TableCell>Guest</TableCell>
                              <TableCell>User Type</TableCell>
                              <TableCell>Ticket Type</TableCell>
                              <TableCell>Invite Sent</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.guestsDetails.map((guest, guestIndex) => (
                              <TableRow
                                key={guestIndex}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: 'transparent !important'
                                  }
                                }}
                              >
                                <TableCell sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <div>
                                    {guest.first_name} {guest.last_name}
                                  </div>{' '}
                                  {guest.email}
                                </TableCell>

                                <TableCell>{guest.user_type}</TableCell>
                                <TableCell>{guest.ticket_type}</TableCell>
                                <TableCell>{guest.invite_sent}</TableCell>
                                <TableCell>
                                  <Stack
                                    sx={{
                                      minWidth: '64px',
                                      width: 'fit-content',
                                      backgroundColor: getStatusColor(guest.status),
                                      color: '#000',
                                      padding: '8px',
                                      borderRadius: '25px',

                                      alignItems: 'center'
                                    }}
                                  >
                                    {guest.status}
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography align="center" color="textSecondary">
                    No events found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default Guests;
