import { SettingOutlined } from '@ant-design/icons';
import { Ticket } from '@ceneteam/namespace';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { DateTimePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ConfirmationDialog from 'components/confirmationDialog/confirmationDialog';
import { useFormik } from 'formik';
import { useState } from 'react';
import { updateTicketSchema } from 'schemas/updateTicketSchema';

export type UpdateTicketFormProps = {
  currentObject: Ticket | null;
  onSuccess: () => void;
  modalToggler?: () => void | null;
};

const UpdateTicketForm = ({ currentObject, onSuccess, modalToggler }: UpdateTicketFormProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: currentObject?.name || '',
      description: currentObject?.description || '',
      quantity: currentObject?.quantity || 0,
      NGN: currentObject?.NGN || 0,
      GBP: currentObject?.GBP || 0,
      USD: currentObject?.USD || 0,
      EUR: currentObject?.EUR || 0,
      sale_starts_at: currentObject?.sale_starts_at || '',
      sale_ends_at: currentObject?.sale_ends_at || '',
      is_accessible: currentObject?.is_accessible || false,
      is_sale_active: currentObject?.is_sale_active || false,
      is_visible: currentObject?.is_visible || false
    },
    validationSchema: updateTicketSchema,
    onSubmit: (values) => {
      console.log('Updated Ticket:', values);
      onSuccess();
    }
  });

  const { getFieldProps, touched, errors, handleSubmit, values, setFieldValue } = formik;

  const activeCurrency = currentObject?.NGN
    ? 'NGN'
    : currentObject?.GBP
    ? 'GBP'
    : currentObject?.USD
    ? 'USD'
    : currentObject?.EUR
    ? 'EUR'
    : '';

  const isFloorplanTicket = currentObject?.name?.includes('Zone');

  const handleSaveClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmChanges = () => {
    formik.handleSubmit();
    setOpenConfirmDialog(false);
  };

  const handleCancelChanges = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid sx={{ paddingBlock: 3, paddingInline: 5 }}>
          <div
            style={{
              margin: 'auto',
              padding: '20px',
              borderRadius: '50%',
              backgroundColor: '#8057DB',
              width: '76px',
              height: '76px',
              border: '1px solid #434343'
            }}
          >
            <SettingOutlined
              style={{
                fontSize: '36px',
                height: '36px'
              }}
            />
          </div>
          <DialogTitle sx={{ fontSize: '24px', color: '#F0F0F0', textAlign: 'center' }}>
            Edit {isFloorplanTicket ? 'Table' : 'Ticket'} Details
          </DialogTitle>
          <DialogContent sx={{ paddingInline: '1px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="ticketName">{isFloorplanTicket ? 'Table' : 'Ticket'} Name</InputLabel>
                  <TextField
                    fullWidth
                    id="ticketName"
                    placeholder="Enter ticket name..."
                    disabled={isFloorplanTicket}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    sx={{
                      '& .MuiOutlinedInput-root.Mui-disabled': {
                        backgroundColor: '#272727',
                        '& .MuiOutlinedInput-input': { opacity: '1', WebkitTextFillColor: '#fff', color: '#fff' }
                      }
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="description">Description</InputLabel>
                  <TextField
                    fullWidth
                    id="description"
                    multiline
                    rows={4}
                    placeholder="Enter ticket description..."
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Stack>
              </Grid>
              {!isFloorplanTicket && (
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="quantity">Quantity</InputLabel>
                    <TextField
                      disabled={true}
                      fullWidth
                      id="quantity"
                      type="number"
                      placeholder="Enter ticket quantity..."
                      {...getFieldProps('quantity')}
                      error={Boolean(touched.quantity && errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                      sx={{
                        '& .MuiOutlinedInput-root.Mui-disabled': {
                          backgroundColor: '#272727',
                          '& .MuiOutlinedInput-input': { opacity: '1', WebkitTextFillColor: '#fff', color: '#fff' }
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              )}
              {activeCurrency && (
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor={`price-${activeCurrency}`}>Price ({activeCurrency})</InputLabel>
                    <TextField
                      fullWidth
                      id={`price-${activeCurrency}`}
                      type="number"
                      placeholder={`Enter price in ${activeCurrency}`}
                      {...getFieldProps(activeCurrency)}
                      error={Boolean(touched[activeCurrency] && errors[activeCurrency])}
                      helperText={touched[activeCurrency] && errors[activeCurrency]}
                    />
                  </Stack>
                </Grid>
              )}

              {!isFloorplanTicket && (
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="sale_starts_at">Sale Starts At</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values.sale_starts_at ? new Date(values.sale_starts_at) : null}
                        onChange={(newValue) => {
                          setFieldValue('sales_starts_at', newValue);
                        }}
                        format="dd.MM.yyyy HH:mm"
                        slotProps={{
                          popper: {
                            sx: {
                              [`.${pickersLayoutClasses.root}`]: {
                                marginBlock: '12px'
                              }
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              )}
              {!isFloorplanTicket && (
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="sale_ends_at">Sale Ends At</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values.sale_ends_at ? new Date(values.sale_ends_at) : null}
                        onChange={(newValue) => {
                          setFieldValue('sales_ends_at', newValue);
                        }}
                        format="dd.MM.yyyy HH:mm"
                        slotProps={{
                          popper: {
                            sx: {
                              [`.${pickersLayoutClasses.root}`]: {
                                marginBlock: '12px'
                              }
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12}>
                {!isFloorplanTicket && (
                  <Stack spacing={1} direction="column" alignItems="flex-start">
                    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Switch
                        checked={formik.values.is_accessible}
                        {...getFieldProps('is_accessible')}
                        sx={{
                          '& .MuiSwitch-track': {
                            borderRadius: 22 / 2,
                            '&::before, &::after': {
                              content: '""',
                              position: 'absolute',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              width: 16,
                              height: 16
                            }
                          },
                          '& .MuiSwitch-switchBase': {
                            '& .MuiSwitch-thumb': {
                              color: '#fff'
                            },
                            '&.Mui-checked': {
                              '& + .MuiSwitch-track': {
                                backgroundColor: '#8057DB',
                                opacity: 1
                              }
                            }
                          }
                        }}
                      />
                      <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF' }}>
                        Allow users with accessibility needs to access this ticket type.
                      </DialogContentText>
                    </Stack>
                  </Stack>
                )}
                {!isFloorplanTicket && (
                  <Stack spacing={1} direction="column" alignItems="flex-start">
                    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Switch
                        checked={formik.values.is_sale_active}
                        {...getFieldProps('is_sale_active')}
                        sx={{
                          '& .MuiSwitch-track': {
                            borderRadius: 22 / 2,
                            '&::before, &::after': {
                              content: '""',
                              position: 'absolute',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              width: 16,
                              height: 16
                            }
                          },
                          '& .MuiSwitch-switchBase': {
                            '& .MuiSwitch-thumb': {
                              color: '#fff'
                            },
                            '&.Mui-checked': {
                              '& + .MuiSwitch-track': {
                                backgroundColor: '#8057DB',
                                opacity: 1
                              }
                            }
                          }
                        }}
                      />
                      <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF' }}>
                        Enable this toggle to make the sale of this ticket type active.
                      </DialogContentText>
                    </Stack>
                  </Stack>
                )}
                <Stack spacing={1} direction="column" alignItems="flex-start">
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Switch
                      checked={formik.values.is_visible}
                      {...getFieldProps('is_visible')}
                      sx={{
                        '& .MuiSwitch-track': {
                          borderRadius: 22 / 2,
                          '&::before, &::after': {
                            content: '""',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: 16,
                            height: 16
                          }
                        },
                        '& .MuiSwitch-switchBase': {
                          '& .MuiSwitch-thumb': {
                            color: '#fff'
                          },
                          '&.Mui-checked': {
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#8057DB',
                              opacity: 1
                            }
                          }
                        }
                      }}
                    />
                    <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF' }}>
                      Toggle to make this {isFloorplanTicket ? 'table' : 'ticket'} visible to users on the platform.
                    </DialogContentText>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ paddingBlock: 2.5 }}>
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="outlined"
                  sx={{
                    color: '#fff',
                    borderColor: '#434343',
                    '&:hover': {
                      backgroundColor: '#121212',
                      color: '#fff',
                      borderColor: '#434343'
                    }
                  }}
                  onClick={modalToggler}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </DialogActions>
        </Grid>
      </form>
      <ConfirmationDialog
        open={openConfirmDialog}
        title="Confirm Changes"
        description="Are you sure you want to save the changes made to this ticket? This action cannot be undone."
        onClose={handleCancelChanges}
        onConfirm={handleConfirmChanges}
      />
    </>
  );
};

export default UpdateTicketForm;
