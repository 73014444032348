import { Box, Grid, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { AttachMoney, ConfirmationNumber, EventSeatOutlined, Group } from '@mui/icons-material';
import { Event } from '@ceneteam/namespace';
import { StatCard } from 'components/statCard/statCard';
import { ISoldTable } from '..';
import { calculateTableStatsCenePlus } from '../calculateTicketStats/cenePlus';

interface IProps {
  event: Event | null;
  soldTables: ISoldTable[];
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string; value: number }[];
}

const OverviewCenePlusStatistic = ({ event, soldTables }: IProps) => {
  const {
    totalSoldTickets,
    totalSoldTables,
    totalAvailableTickets,
    totalAvailableTables,
    totalRevenue,
    percentageTicketsSold,
    percentageTablesSold,
    percentageTicketsAvailable,
    percentageTablesAvailable
  } = calculateTableStatsCenePlus(event as Event, soldTables as ISoldTable[]);

  const tickets = event?.tickets || [];

  const totalQuantity = tickets.reduce((total, ticket) => total + (ticket.quantity || 0), 0);

  const pieDataTables =
    soldTables?.map((soldTable) => ({
      name: soldTable.zone_id,
      value: soldTable.total_tables,
      percentage: totalQuantity > 0 ? (soldTable.total_tables / totalQuantity) * 100 : 0
    })) || [];

  const pieData = pieDataTables;

  const getColor = (index: number) => {
    const colors = ['#6A4AB0', '#B6A5DC', '#9977E2', '#411E8D'];
    return colors[index % colors.length];
  };

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      const totalTables = soldTables?.reduce((acc, entry) => acc + (entry.total_tables || 0), 0) || 0;
      const total = totalTables;

      const percent = ((value / total) * 100).toFixed(1);

      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          <Typography variant="body2" sx={{ color: '#B6A5DC' }}>
            {`${name}: ${value} (${percent}%)`}
          </Typography>
        </div>
      );
    }
    return null;
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>EVENT INSIGHTS</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <Grid container spacing={3} sx={{ height: '100%', alignItems: 'center' }}>
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<ConfirmationNumber style={{ width: '24px', height: '24px' }} />}
                  value={totalSoldTickets}
                  label="Tickets Sold"
                  percentage={percentageTicketsSold}
                  progressLabel="% Sold"
                  isPersantage={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<EventSeatOutlined style={{ width: '24px', height: '24px' }} />}
                  value={totalAvailableTickets}
                  label="Tickets Available"
                  percentage={percentageTicketsAvailable}
                  progressLabel="% Available"
                  isPersantage={true}
                />
              </Grid>
              {event?.floorplan_id !== null && (
                <>
                  <Grid item xs={12} md={6}>
                    <StatCard
                      icon={<ConfirmationNumber style={{ width: '24px', height: '24px' }} />}
                      value={totalSoldTables}
                      label="Tables Sold"
                      percentage={percentageTablesSold}
                      progressLabel="% Sold"
                      isPersantage={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StatCard
                      icon={<EventSeatOutlined style={{ width: '24px', height: '24px' }} />}
                      value={totalAvailableTables}
                      label="Tables Available"
                      percentage={percentageTablesAvailable}
                      progressLabel="% Available"
                      isPersantage={true}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<AttachMoney style={{ width: '24px', height: '24px' }} />}
                  value={totalRevenue}
                  label="Revenue Generated"
                  percentage={0}
                  currency={event?.payments.NGN ? 'NGN' : event?.payments.USD ? 'USD' : ''}
                  progressLabel="% of Sales Target"
                  isPersantage={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StatCard
                  icon={<Group style={{ width: '24px', height: '24px' }} />}
                  value={0}
                  label="Tables Shared"
                  percentage={Number(0)}
                  progressLabel="% Shared"
                  isPersantage={false}
                />
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <MainCard
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
            contentSX={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}
          >
            <Typography sx={{ fontSize: '16px' }}>Sales by Table Zones</Typography>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
              {totalSoldTables === 0 ? (
                <Typography sx={{ color: '#8C8C8C', py: 8 }}>No tables have been sold yet</Typography>
              ) : (
                <>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PieChart width={232} height={232}>
                      <Pie data={pieData} cx={116} cy={116} fill="#8884d8" dataKey="value" labelLine={false}>
                        {pieData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getColor(index)} />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  </Grid>
                  <Grid item>
                    {pieData.map((item, index) => (
                      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, mt: 3 }} key={item.name}>
                        <Box
                          sx={{
                            width: '8px',
                            height: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '12px',
                            backgroundColor: getColor(index)
                          }}
                        ></Box>
                        <Typography>{item.name}</Typography>
                      </Stack>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default OverviewCenePlusStatistic;
