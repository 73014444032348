import { Grid, Stack, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { GroupAddOutlined, PeopleAltOutlined, StarBorderOutlined, ConfirmationNumberOutlined } from '@mui/icons-material';

const FansStatistic = () => {
  const followedCount = 4;
  const ceneCustomers = 2;
  const cenePlusCustomers = 2;
  const totalBoughtTickets = 200;

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Fans Analytics</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Followed Count"
            count={followedCount.toString()}
            icon={<GroupAddOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Cene Customers"
            count={ceneCustomers.toString()}
            icon={<PeopleAltOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Cene+ Customers"
            count={cenePlusCustomers.toString()}
            icon={<StarBorderOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticEcommerce
            title="Bought Tickets"
            count={totalBoughtTickets.toString()}
            icon={<ConfirmationNumberOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default FansStatistic;
