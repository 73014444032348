import { FormHelperText, InputLabel, Stack, Switch, Typography } from '@mui/material';
import { CustomInput } from 'components/customInput/customInput';
import { ExpandedCard } from 'components/expandedCard/expandedCard';
import { useEffect, useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import { EventSchema } from 'pages/eventManagement/event/event.schema';
import { ReactComponent as UploadSVG } from '../../svg/upload-cloud.svg';
import { ICropedPicture, useCrop } from 'hooks/useCrop';
import { PictureCard } from 'components/pictureCard';
import { Crop } from 'react-image-crop';
import ImageCropper from 'components/imageCropper/imageCropper';
import { TimePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { v4 as uuidv4 } from 'uuid';

interface IArtistCard {
  id: string;
  isDoor?: boolean;
}

export const ArtistCard = ({ id, isDoor = false }: IArtistCard) => {
  const formik = useFormikContext<EventSchema>();
  const [isEditPicture, setEditPicture] = useState<ICropedPicture | null>(null);

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];
  const artist = values.line_up.find((artist) => artist.id === id);
  const errors = formik.errors[formType];

  const { choosePictures, initialCrop } = useCrop({
    onChange: (pictures) => {
      updateArtistField('thumbnail_url', pictures);
    }
  });

  const updateArtistField = (field: string, value: any) => {
    formik.setFieldValue(
      `${formType}.line_up`,
      values.line_up.map((artist) => (artist.id === id ? { ...artist, [field]: value } : artist))
    );
  };

  const removeArtist = () => {
    if (window.confirm('Are you sure you want to delete the performer?')) {
      formik.setFieldValue(
        `${formType}.line_up`,
        values.line_up.filter((artist) => artist.id !== id)
      );
    }
  };

  const removePicture = () => {
    if (window.confirm('Are you sure you want to delete picture?')) {
      updateArtistField('thumbnail_url', []);
    }
  };

  const handleCropPicture = async (picture: string, crop: Crop) => {
    const response = await initialCrop(picture, crop);

    updateArtistField('thumbnail_url', [
      {
        id: uuidv4(),
        cropedPicture: response.cropedImage,
        crop: response.crop
      }
    ]);

    setEditPicture(null);
  };

  const gradient = formType === 'cene' ? 'rgba(128,87,219,1)' : 'rgba(184,202,217,1)';
  const isDoors = artist?.isDoorsClose;

  useEffect(() => {
    if (artist && !artist.starts_at && !artist.isWithoutTime) {
      const eventStartDateTime = new Date(values.lineup_srart_at).toISOString();
      updateArtistField('starts_at', eventStartDateTime);
    }
  }, [values.line_up, artist]);

  if (!artist) return null;

  return (
    <>
      <ExpandedCard
        key={id}
        title={artist.title || `Artist`}
        expanded={true}
        isDoor={isDoor}
        onRemove={removeArtist}
        children={
          <Stack flexDirection="row" gap={2}>
            {!isDoors && (
              <Stack mt={1}>
                {artist.thumbnail_url && artist.thumbnail_url.length > 0 ? (
                  <PictureCard
                    onRemove={removePicture}
                    onEdit={() => setEditPicture((artist.thumbnail_url?.[0] as ICropedPicture) || {})}
                    url={artist.thumbnail_url?.[0]?.cropedPicture || ''}
                    alt={artist.thumbnail_url?.[0]?.id || ''}
                  />
                ) : (
                  <Stack
                    onClick={() => choosePictures(1)}
                    sx={{
                      opacity: 0.9,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderTopLeftRadius: '21px',
                      borderBottomRightRadius: '21px',
                      minWidth: '76px',
                      height: '105px',
                      cursor: 'pointer',
                      transition: '300ms',
                      background: `linear-gradient(0deg, transparent 0%, ${gradient} 85%)`,
                      '&:hover': { opacity: 1 }
                    }}
                  >
                    <UploadSVG width="35px" height="35px" />
                  </Stack>
                )}
              </Stack>
            )}
            <Stack width="100%">
              {!isDoors && (
                <Stack spacing={1}>
                  <CustomInput
                    placeholder="Name"
                    required
                    name={`${formType}.line_up[${id}].title`}
                    label="Artist name"
                    value={artist.title}
                    error={getIn(errors, `${formType}.line_up[${id}].title`)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Stack>
              )}
              <Stack spacing={1} mt={isDoors ? 0 : 2}>
                {!isDoors && (
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Switch
                      sx={{ marginLeft: 0 }}
                      checked={artist.isWithoutTime}
                      onChange={() => updateArtistField('isWithoutTime', !artist.isWithoutTime)}
                    />
                    <Typography color="info">Without time</Typography>
                  </Stack>
                )}
                {!artist.isWithoutTime && (
                  <>
                    <InputLabel required>Time</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        value={artist.starts_at ? new Date(artist.starts_at) : new Date()}
                        onChange={(newValue) => {
                          if (newValue) {
                            updateArtistField('starts_at', newValue);
                          }
                        }}
                        format="HH:mm"
                        slotProps={{
                          popper: {
                            sx: {
                              [`.${pickersLayoutClasses.root}`]: { marginBlock: '12px' }
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>

                    {getIn(errors, `line_up[${id}].starts_at`) && getIn(formik.touched, `line_up[${id}].starts_at`) && (
                      <FormHelperText sx={{ color: '#a61d24' }}>{getIn(errors, `line_up[${id}].starts_at`)}</FormHelperText>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        }
      />
      {isEditPicture && (
        <ImageCropper
          key={id}
          crop={isEditPicture.crop}
          src={isEditPicture.originalPicture}
          onClose={() => setEditPicture(null)}
          onCropComplete={(croped) => handleCropPicture(isEditPicture.originalPicture, croped as Crop)}
          aspect={1}
        />
      )}
    </>
  );
};
