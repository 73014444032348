import { TeamOutlined } from '@ant-design/icons';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ReactComponent as TicketSVG } from 'svg/tickets.svg';
import { fetchTransactions } from 'utils/transactionsService';

export interface Purchase {
  updated_at: string;
  event: string;
  organizer: string;
  status: string;
  ticket_type: string;
  tickets_bought: number;
  app: string;
  table: string;
}

const EventsAndPurchases = () => {
  const [filter, setFilter] = useState('');
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const { id } = useParams();

  useEffect(() => {
    if (id)
      fetchTransactions(id)
        .then((data) => setPurchases(data))
        .catch((error) => console.error(error));
  }, []);

  const totalPartners = new Set(purchases.map((event) => event.organizer)).size;
  const totalTicketsBought = purchases.reduce((acc, event) => {
    const sold = event.tickets_bought;
    return acc + (isNaN(sold) ? 0 : sold);
  }, 0);

  const filteredRows = purchases.filter((purchase) => purchase.event.toLowerCase().includes(filter.toLowerCase()));
  const hasZoneInTicketType = filteredRows.some((row) => row.ticket_type.toLowerCase().includes('zone'));
  return (
    <MainCard title="Events & Purchases">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AnalyticEcommerce
            title="Total Partners"
            count={totalPartners.toString()}
            icon={<TeamOutlined style={{ fontSize: '32px', color: '#c118ff' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AnalyticEcommerce title="Total Tickets Bought" count={totalTicketsBought.toString()} icon={<TicketSVG />} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} marginTop="16px">
        <DebouncedInput
          fullWidth
          value={filter ?? ''}
          onFilterChange={(value) => setFilter(String(value))}
          placeholder="Search for an event"
        />
      </Grid>
      <TableContainer sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead sx={{ backgroundColor: 'transparent' }}>
            <TableRow>
              <TableCell>Date Purchased</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Organiser</TableCell>
              {!hasZoneInTicketType && <TableCell>Ticket Types</TableCell>}
              {hasZoneInTicketType && <TableCell>Tables</TableCell>}
              <TableCell>Tickets Bought</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length > 0 ? (
              filteredRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.updated_at}</TableCell>
                  <TableCell>{row.event}</TableCell>
                  <TableCell>{row.organizer}</TableCell>
                  {!hasZoneInTicketType && <TableCell>{row.ticket_type}</TableCell>}
                  {hasZoneInTicketType && <TableCell>{row.table}</TableCell>}

                  <TableCell>{row.tickets_bought}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center" color="textSecondary">
                    No events found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default EventsAndPurchases;
