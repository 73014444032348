import { Divider, Pagination, Stack } from '@mui/material';
import RequestStatistic from './statistic';
import RequestsTable from './table';
import { ModalContextProvider } from 'contexts/ReusableModalContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { supabase } from 'supabase/supabase';
import CircularLoader from 'components/CircularLoader';
import { TicketRequestsStatus, TicketRequestType, Event } from '@ceneteam/namespace';

export interface Ticket {
  name: string;
  ticket_name_id: string;
  quantity: number;
}

const ITEMS_PER_PAGE = 40;

export interface TicketRequest {
  id: string;
  customer_id: string;
  customer_name: string;
  event_id: string;
  app: string;
  details: Ticket[];
  status: TicketRequestsStatus;
  message?: string;
  type: TicketRequestType;
  event?: Event;
  created_at: string;
}

const RequestsPage = () => {
  const { id } = useParams();
  const [requests, setRequests] = useState<TicketRequest[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (id) fetchRequests();
  }, [id]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('ticket_requests').select('*').eq('event_id', id);
      setRequests(data as TicketRequest[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const paginatedEvents = requests.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return isLoading ? (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'center', mt: 4 }}>
      <CircularLoader />
    </Stack>
  ) : (
    <>
      <RequestStatistic requests={requests} />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <ModalContextProvider>
        <Stack>
          <RequestsTable requests={paginatedEvents} onSuccess={fetchRequests} />
          {requests.length !== 0 && (
            <Pagination
              count={Math.ceil(requests.length / ITEMS_PER_PAGE)}
              page={currentPage}
              onChange={handlePageChange}
              sx={{ mt: 3, alignSelf: 'center' }}
            />
          )}
        </Stack>
      </ModalContextProvider>
    </>
  );
};

export default RequestsPage;
