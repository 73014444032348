import { EditOutlined } from '@mui/icons-material';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { ROUTE_FLOORPLAN_EDITOR } from 'constants/routes';
import { Floorplan } from 'types/floorplans';

interface IFlooplansTable {
  floorplans: Floorplan[];
  charts: unknown;
}

export const FloorplansTable = ({ floorplans, charts }: IFlooplansTable) => {
  return (
    <TableContainer sx={{ marginTop: '16px' }}>
      <Table>
        <TableHead sx={{ backgroundColor: 'transparent' }}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Thunbnail</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {floorplans.map((item) => (
            <TableRow
              key={item.id}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent !important'
                }
              }}
            >
              <TableCell>{`${(charts as any)?.find((chart: any) => chart.key === item.id)?.name}`}</TableCell>
              <TableCell>
                <img
                  width={50}
                  height={50}
                  style={{ objectFit: 'contain' }}
                  alt={item.name}
                  src={`${(charts as any)?.find((chart: any) => chart.key === item.id)?.publishedVersionThumbnailUrl}`}
                />
              </TableCell>
              <TableCell align="center">
                <IconButton aria-label="view" onClick={() => window.open(ROUTE_FLOORPLAN_EDITOR.replace(':id', item.id), '_blank', '')}>
                  <EditOutlined />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
