import { InfoCircleOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { HeaderCells } from 'components/commonTable/headerCells';
import MainCard from 'components/MainCard';
import { DebouncedInput } from 'components/third-party/react-table';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useEffect, useState } from 'react';
import ReusableModal from 'components/modal/modal';
import InviteForm from './forms/invite';
import BuyerDetails from './buyerDetails';
import { useParams } from 'react-router';
import { supabase } from 'supabase/supabase';
import { Event } from '@ceneteam/namespace';

export interface ITicketBuyer {
  name: string;
  ticketCategory: string;
  ticketOwned: string;
  ticketsSent: string;
  uniqueGuests: string;
  guestsReceivedQr: string;
  id: string;
}

export enum TicketBuyerColumnId {
  NAME = 'name',
  TICKET_CATEGORY = 'ticketCategory',
  TICKET_OWNED = 'ticketOwned',
  TICKETS_SENT = 'ticketsSent',
  UNIQUE_GUESTS = 'uniqueGuests',
  GUESTS_RECEIVED_QR = 'guestsReceivedQr',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: TicketBuyerColumnId;
  title: string;
  sortable: boolean;
  cell?: (buyer: ITicketBuyer) => React.ReactNode;
}

const ticketBuyerColumns: TableColumn[] = [
  { id: TicketBuyerColumnId.NAME, title: 'Name', sortable: true, cell: (buyer) => buyer.name },
  { id: TicketBuyerColumnId.TICKET_CATEGORY, title: 'Ticket Category', sortable: true, cell: (buyer) => buyer.ticketCategory },
  { id: TicketBuyerColumnId.TICKET_OWNED, title: 'Ticket owned', sortable: true, cell: (buyer) => buyer.ticketOwned },
  { id: TicketBuyerColumnId.TICKETS_SENT, title: 'Ticket sent', sortable: true, cell: (buyer) => buyer.ticketsSent },
  { id: TicketBuyerColumnId.UNIQUE_GUESTS, title: 'Unique guests', sortable: true, cell: (buyer) => buyer.uniqueGuests },
  { id: TicketBuyerColumnId.GUESTS_RECEIVED_QR, title: 'Guests received QR', sortable: true, cell: (buyer) => buyer.guestsReceivedQr },

  { id: TicketBuyerColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const getColumnValue = (column: TableColumn, buyer: ITicketBuyer): React.ReactNode => {
  return column.cell ? column.cell(buyer) : '';
};

interface TicketBuyerTableRowsProps {
  buyers: ITicketBuyer[];
  onSuccess?: () => void;
}

const TicketBuyerTableRows = ({ buyers = [], onSuccess = () => null }: TicketBuyerTableRowsProps) => {
  const [event, setEvent] = useState<Event>();
  const { id } = useParams<{ id: string }>();
  const [openModalBuyer, setModalBuyer] = useState<ITicketBuyer | null>(null);

  useEffect(() => {
    if (id) fetchEventById();
  }, []);

  const fetchEventById = async () => {
    try {
      const event = await supabase.from('events').select('*').eq('id', id).single();

      setEvent(event.data as Event);
      console.log(event);
    } catch (error) {
    } finally {
    }
  };

  const renderCellContent = (column: TableColumn, buyer: ITicketBuyer) => {
    if (column.id === TicketBuyerColumnId.ACTIONS) {
      return (
        <>
          <IconButton onClick={() => setModalBuyer(buyer)}>
            <UserOutlined />
          </IconButton>

          <IconButton>
            <InfoCircleOutlined />
          </IconButton>
        </>
      );
    }
    return getColumnValue(column, buyer);
  };

  return (
    <>
      {buyers.map((buyer) => (
        <TableRow key={buyer.id}>
          {ticketBuyerColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, buyer)}</TableCell>
          ))}
        </TableRow>
      ))}
      {openModalBuyer && (
        <BuyerDetails openModal={Boolean(openModalBuyer)} onClose={() => setModalBuyer(null)} buyer={openModalBuyer} event={event} />
      )}
    </>
  );
};

interface TicketBuyersTableProps {
  isLoading?: boolean;
  buyers?: ITicketBuyer[];
  onSuccess?: () => void;
}

const TicketBuyersTable = ({ onSuccess = () => null, isLoading = false, buyers = [] }: TicketBuyersTableProps) => {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: TicketBuyerColumnId; direction: 'asc' | 'desc' }>({
    key: TicketBuyerColumnId.NAME,
    direction: 'asc'
  });

  const { open, modalToggler } = useModalContext();

  const filterTicketBuyers = buyers.filter((buyer) =>
    ticketBuyerColumns.some((column) => {
      const value = getColumnValue(column, buyer);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedTicketBuyers = [...filterTicketBuyers].sort((a, b) => {
    const { key, direction } = sortConfig;

    const column = ticketBuyerColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: TicketBuyerColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC' }}>ticket buyers</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 3.5 }}>
        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Find Ticket Owner by Name"
            sx={{ width: '300px !important' }}
          />
        </Stack>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          sx={{
            backgroundColor: '#8057DB',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '&:hover': { backgroundColor: '#8157dbab' }
          }}
        >
          <SendOutlined />
          Invite User to Event
        </Button>
      </Stack>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderCells<TicketBuyerColumnId> columns={ticketBuyerColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={ticketBuyerColumns.length}>
                  <CircularLoader />
                </TableCell>
              </TableRow>
            ) : buyers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={ticketBuyerColumns.length}>
                  <Stack textAlign="center">No Ticket Buyers yet</Stack>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                <TicketBuyerTableRows onSuccess={onSuccess} buyers={sortedTicketBuyers} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MainCard>
      <ReusableModal currentObject={null} open={open} modalToggler={() => modalToggler(null)} content={InviteForm} width="488px" />
    </>
  );
};

export default TicketBuyersTable;
