import { ApartmentOutlined, BarsOutlined, ContainerOutlined } from '@ant-design/icons';
import { Grid, Stack, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { IGuest } from '.';

interface Props {
  guests: IGuest[];
  stock: number;
}

const GuestlistsStatisticSection = ({ guests, stock }: Props) => {
  const uniqueUserCount = new Set(guests.map((guest) => guest.customerId)).size;
  const assignedTickets = guests.reduce((total, guest) => {
    const guestTickets = guest.tickets.reduce((sum, ticket) => sum + parseInt(ticket.ticket_count, 10), 0);
    return total + guestTickets;
  }, 0);
  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Assignment Analytics</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce
            title="Customer Assignment Count"
            count={uniqueUserCount.toString()}
            icon={<BarsOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce
            title="Remaining Tickets"
            count={stock.toString()}
            icon={<ApartmentOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnalyticEcommerce
            title="Assigned Tickets"
            count={assignedTickets.toString()}
            icon={<ContainerOutlined style={{ fontSize: '24px', color: '#AA7AE7' }} />}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GuestlistsStatisticSection;
