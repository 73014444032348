import { useEffect } from 'react';

interface ISeatsioPreview {
  containerId?: string;
  chartKey: string;
}

export const SeatsioEditor = ({ containerId = 'seatsio', chartKey = 'a592c5b4-0869-4ff9-81d9-3e9932087834' }: ISeatsioPreview) => {
  useEffect(() => {
    if (window.seatsio) {
      new window.seatsio.SeatingChartDesigner({
        divId: containerId,
        secretKey: `${process.env.REACT_APP_SEATSIO_WORKSPACE_DESIGNER}`,
        chartKey
      }).render();
    }
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10000
        }}
        id={containerId}
      />
    </div>
  );
};
