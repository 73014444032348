import { ThunderboltOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const fans: NavItemType = {
  id: 'group-fans-loading',
  type: 'group',
  children: [
    {
      id: 'fans',
      title: 'Fans',
      type: 'item',
      url: '/fans',
      icon: ThunderboltOutlined,
      breadcrumbs: false
    }
  ]
};

export default fans;
