import { InputLabel, Stack, TextField, Typography } from '@mui/material';
import ShapeButton from 'components/button/button';
import { useFormikContext } from 'formik';
import { EventSchema } from '../event.schema';

interface IEventAboutProps {
  onNext: () => void;
  onPrev: () => void;
  isNew: boolean;
}

export const EventAbout = ({ onNext, onPrev, isNew }: IEventAboutProps) => {
  const formik = useFormikContext<EventSchema>();

  const validationStep =
    !formik.values.name || Boolean(formik.errors.name) || !formik.values.description || Boolean(formik.errors.description);

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography fontWeight="300" lineHeight="64px" fontSize="34px" color="#8057DB" textAlign="center">
        Tell Us About Your Event
      </Typography>
      <Typography maxWidth="689px" fontWeight="300" fontSize="16px" textAlign="center">
        A well-crafted name grabs attention and makes your audience curious, while an intriguing description sets the tone and mood,
        encouraging them to dive deeper into your event.
      </Typography>
      <Stack mt="34px">
        <Stack mb="30px" textAlign="start">
          <InputLabel required sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
            Event Name
          </InputLabel>
          <TextField
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            helperText={formik.errors.name}
            name="name"
            variant="standard"
            placeholder="E.g Elektrik Nights"
            sx={inputStyles}
          />
        </Stack>
        <Stack mb="30px" textAlign="start">
          <InputLabel required sx={{ fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
            Event Description
          </InputLabel>
          <TextField
            value={formik.values.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.description)}
            helperText={formik.errors.description}
            name="description"
            maxRows={100}
            minRows={2}
            multiline
            variant="standard"
            placeholder="Picture this: a stylish venue lit up with neon vibes, where the beats from local DJs keep the energy electric. "
            sx={inputStyles}
          />
        </Stack>
      </Stack>
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="200px" isOutlined>
          {isNew ? 'Previous step' : 'Back to event detail'}
        </ShapeButton>
        <ShapeButton disabled={validationStep} handleClick={onNext} width="300px">
          Next step
        </ShapeButton>
      </Stack>
    </Stack>
  );
};

const inputStyles = {
  width: '460px',
  '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
  '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
  '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
};
