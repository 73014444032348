// import { ITicketsSold } from 'pages/eventManagement/ceneEvents/eventDetails/tabs/overview/cene/table';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { CenePlusRequest } from 'types/user';

export interface ITicketsSold {
  id: string;
  EUR: number;
  GBP: number;
  NGN: number;
  USD: number;
  name: string;
  quantity: number;
  is_visible: boolean;
  description: string;
  is_accessible: boolean;
  is_sale_active: boolean;
  sale_starts_at: string | null;
  sale_ends_at: string | null;
}

interface EventResponse {
  tickets: ITicketsSold[];
}

export const fetchBoughtTickets = async (userId: string) => {
  const { error, data } = await supabase.from('transactions').select('*').eq('details_data->>customer_id', userId);

  if (data) {
    console.log(data);
  } else {
    console.log('error', error);
  }

  return '3';
};

export const fetchCustomerName = async (userId: string) => {
  const { data } = await supabase.from('customers').select('first_name, last_name').eq('user_id', userId);

  if (data && data.length > 0) {
    return data[0].first_name + ' ' + data[0].last_name;
  } else {
    return 'No customer found';
  }
};

export const handleCalculateRevenueGenerated = async (eventId: string | undefined, tickets: ITicketsSold[]) => {
  if (!eventId) return;
  const { data, error } = await supabase.from('tickets').select('*').eq('event_id', eventId).not('customer_id', 'is', null);
  let generatedRevenue = 0;

  if (data) {
    //loop over tickets
    data.forEach((sgnTicket) => {
      const matchingTicket = tickets.find((tkt: ITicketsSold) => tkt.name === sgnTicket.title);

      if (matchingTicket && matchingTicket.id) {
        generatedRevenue += matchingTicket.NGN;
      }
    });

    return generatedRevenue || 0.0;
  } else {
    console.log('err', error);
  }
};

export const handleCalculateAllTicketsCost = async (eventId: string | undefined, tickets: ITicketsSold[]) => {
  if (!eventId) return;
  const { data, error } = await supabase.from('tickets').select('*').eq('event_id', eventId);
  let generatedRevenue = 0;

  if (data) {
    //loop over tickets
    data.forEach((sgnTicket) => {
      const matchingTicket = tickets.find((tkt: ITicketsSold) => tkt.name === sgnTicket.title);

      if (matchingTicket && matchingTicket.id) {
        generatedRevenue += matchingTicket.NGN;
      }
    });

    return generatedRevenue || 0.0;
  } else {
    console.log('err', error);
  }
};

export const handleCalculateAvailableTicketsByName = async (eventId: string | undefined, name: string) => {
  if (!eventId) return;
  // Get tickets sold count
  try {
    const { count: availableTickets } = await supabase
      .from('tickets')
      .select('*', { count: 'exact', head: true })
      .eq('event_id', eventId)
      .eq('title', name)
      .is('customer_id', null);

    return availableTickets || 0;
  } catch (err) {
    console.log('err', err);
  }
};

export const handleCalculateSoldTicketsByName = async (eventId: string | undefined, name: string) => {
  if (!eventId) return;
  // Get tickets sold count
  try {
    const { count: numOfSoldTickets } = await supabase
      .from('tickets')
      .select('*', { count: 'exact', head: true })
      .eq('event_id', eventId)
      .eq('title', name)
      .not('customer_id', 'is', null);

    return numOfSoldTickets;
  } catch (err) {
    console.log('err', err);
  }
};

export const handleCalculateTicketsAvailable = async (eventId: string | undefined, ticketsSold = 0) => {
  if (!eventId) return;
  const { data, error } = await supabase.from('tickets').select('*').eq('event_id', eventId);

  if (data) {
    return data.length - ticketsSold;
  } else {
    console.log('err', error);
    return 0.0;
  }
};

export const handleCalculateAllTickets = async (eventId: string | undefined) => {
  if (!eventId) return;
  const { data, error } = await supabase.from('tickets').select('*').eq('event_id', eventId);

  if (data) {
    return data.length;
  } else {
    console.log('err', error);
    return 0.0;
  }
};

export const fetchTicketsByEventId = async (id: string) => {
  try {
    const { data } = await supabase.from('events').select('tickets').eq('id', id).returns<EventResponse[]>();

    if (data && data[0]?.tickets && typeof data[0].tickets !== 'undefined') {
      return data[0].tickets;
    }
  } catch (error) {
    enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
  }
};

//update customer's tables after approval
export const handleUpdateCustomerRecord = async (user: CenePlusRequest) => {
  // Query cene_plus_requests table - now getting all matching records
  const { error, data: requestsData } = await supabase.from('cene_plus_requests').select('*').eq('id', user.id);

  if (error) {
    throw error;
  }

  if (!requestsData || requestsData.length === 0) {
    console.log('No cene plus requests found');
    return;
  }

  // Check if customer exists with matching email and app
  const { data: customerData, error: customerCheckError } = await supabase.from('customers').select('*').eq('email', user.email);

  if (customerCheckError) throw customerCheckError;

  if (!customerData) {
    console.log('No matching customer found');
    return;
  }

  // Update customer record using the cenePlusRequest data
  const { error: updateError } = await supabase
    .from('customers')
    .update({
      first_name: requestsData[0].first_name,
      last_name: requestsData[0].last_name,
      phone_number: requestsData[0].phone_number,
      birthdate: requestsData[0].birthdate,
      gender: requestsData[0].gender
    })
    .eq('email', user.email)
    .eq('app', 'cene+');

  if (updateError) throw updateError;

  return requestsData[0];
};
