import { Event, Ticket } from '@ceneteam/namespace';
import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useState } from 'react';
import { getStatusStyle } from '../statusStyle/statusStyle';
import ReusableModal from 'components/modal/modal';
import UpdateTicketForm from '../forms/updateTicket/cene';

export enum TicketsColumnId {
  TITLE = 'title',
  TICKETS_RELEASED = 'ticketsReleased',
  AVAILABLE_TICKETS = 'availableTickets',
  PRICE = 'price',
  ACCESSIBLE = 'accessible',
  SELLING_NOW = 'sellingNow',
  ACTIONS = 'actions'
}

const columnToTicketKeyMap: Record<TicketsColumnId, keyof Ticket | null> = {
  [TicketsColumnId.TITLE]: 'name',
  [TicketsColumnId.TICKETS_RELEASED]: 'quantity',
  [TicketsColumnId.AVAILABLE_TICKETS]: 'quantity',
  [TicketsColumnId.PRICE]: 'NGN',
  [TicketsColumnId.ACCESSIBLE]: 'is_accessible',
  [TicketsColumnId.SELLING_NOW]: 'is_sale_active',
  [TicketsColumnId.ACTIONS]: null
};

const columns = [
  { id: TicketsColumnId.TITLE, title: 'Title' },
  { id: TicketsColumnId.TICKETS_RELEASED, title: 'Tickets released' },
  { id: TicketsColumnId.AVAILABLE_TICKETS, title: 'Available tickets' },
  { id: TicketsColumnId.PRICE, title: 'Price' },
  { id: TicketsColumnId.ACCESSIBLE, title: 'Accessible' },
  { id: TicketsColumnId.SELLING_NOW, title: 'Selling now' },
  { id: TicketsColumnId.ACTIONS, title: 'Actions' }
];

interface IProps {
  event: Event;
  onSuccess: () => void;
}

const TicketsInfoTable = ({ event, onSuccess }: IProps) => {
  const tickets = event.tickets;

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<TicketsColumnId>(TicketsColumnId.TITLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);

  const handleRequestSort = (property: TicketsColumnId) => {
    if (property !== TicketsColumnId.ACTIONS) {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleOpenModal = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTicket(null);
  };

  const getAvailableTickets = (ticketId: string) => {
    return event.stock ? event.stock[ticketId] : 0;
  };

  const getPriceWithCurrency = (ticket: Ticket) => {
    let price = 0;
    let currency = '';

    if (event?.payments?.NGN) {
      price = ticket.NGN || 0;
      currency = 'NGN';
    } else if (event?.payments?.USD) {
      price = ticket.USD || 0;
      currency = 'USD';
    } else if (event?.payments?.EUR) {
      price = ticket.EUR || 0;
      currency = 'EUR';
    } else if (event?.payments?.GBP) {
      price = ticket.GBP || 0;
      currency = 'GBP';
    }

    return { price, currency };
  };

  const sortedTickets = [...(tickets as Ticket[])].sort((a, b) => {
    if (orderBy === TicketsColumnId.ACTIONS) {
      return 0;
    }

    const ticketKey = columnToTicketKeyMap[orderBy];

    if (!ticketKey) {
      return 0;
    }

    const aValue = a[ticketKey];
    const bValue = b[ticketKey];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Tickets info</Typography>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (column.id === TicketsColumnId.ACTIONS) {
                    return <TableCell key={column.id}>{column.title}</TableCell>;
                  }

                  return (
                    <TableCell key={column.id}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTickets?.map((ticket) => {
                const ticketStock = getAvailableTickets(ticket.id);
                const ticketsReleased = ticket.quantity || 0;
                const { price, currency } = getPriceWithCurrency(ticket);

                return (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.name}</TableCell>
                    <TableCell>{ticketsReleased}</TableCell>
                    <TableCell>{ticketStock}</TableCell>
                    <TableCell>
                      {price.toLocaleString()} {currency}
                    </TableCell>
                    <TableCell>
                      <Box sx={getStatusStyle(ticket.is_accessible)}>{ticket.is_accessible ? 'Accessible' : 'Not Accessible'}</Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={getStatusStyle(ticket.is_sale_active)}>{ticket.is_sale_active ? 'On Sale' : 'Not On Sale'}</Box>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenModal(ticket)}>
                        <EditOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>

      {selectedTicket && (
        <ReusableModal
          width="600px"
          open={isModalOpen}
          modalToggler={handleCloseModal}
          onSuccess={onSuccess}
          content={UpdateTicketForm}
          currentObject={selectedTicket}
        />
      )}
    </Stack>
  );
};

export default TicketsInfoTable;
