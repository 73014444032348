import { ContainerOutlined } from '@ant-design/icons';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  // MenuItem,
  // Select,
  Stack,
  TextField,
  Autocomplete
} from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { supabase } from 'supabase/supabase';
import { User } from 'types/user';
import { debounce } from 'lodash';

export interface ITicketsSold {
  id: string;
  EUR: number;
  GBP: number;
  NGN: number;
  USD: number;
  name: string;
  quantity: number;
  is_visible: boolean;
  description: string;
  is_accessible: boolean;
  is_sale_active: boolean;
  sale_starts_at: string | null;
  sale_ends_at: string | null;
}

interface Option {
  id: string;
  label: string;
}

type OptionUser = Option & User;

const GuestlistForm = () => {
  const formik = useFormik({
    initialValues: {
      existUser: '',
      ticketStock: '',
      quantity: 1,
      teamMember: ''
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });
  const [assignUser, setAssignUser] = useState<OptionUser | null>(null);
  const [assignTicket, setAssignTicket] = useState<ITicketsSold | null>(null);
  const [, setCustomers] = useState<User[]>([]);
  const [tickets, setTickets] = useState<ITicketsSold[]>([]);
  const { modalToggler } = useModalContext();
  const [isLoading, setIsLoading] = useState(true);
  const { isSubmitting, handleChange } = formik;
  const { id } = useParams();

  //test async autcomplete
  const [loading, setLoading] = useState(false);
  const [, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<OptionUser[]>([]);

  useEffect(() => {
    fetchRequiredDataForModal();
  }, []);

  const fetchUserFromSupabase = debounce(async (searchTerm: string) => {
    if (!searchTerm || searchTerm.length < 2) {
      setOptions([]);
      return;
    }

    try {
      setLoading(true);
      // Replace with your API endpoint
      const { data: response } = await supabase.from('customers').select('*').ilike('first_name', `%${searchTerm}%`);

      if (response && response?.length > 0) {
        // // Transform API response to match Option interface
        const transformedData: OptionUser[] = response.map((item: User) => ({
          label: item.first_name + ' ' + item.last_name,
          ...item
        }));

        setOptions(transformedData);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  }, 300); // 300ms delay

  const fetchRequiredDataForModal = async () => {
    setIsLoading(true);
    try {
      const { data: customersData } = await supabase.from('customers').select('*');

      const filteredCustomers = (customersData as User[]).filter((customer) => customer.onboarding_finished && customer.app === 'cene');

      //only save users that their onboarding_finished and app is cene
      setCustomers(filteredCustomers as User[]);

      //get tickets by eventId
      const { data: eventData } = await supabase.from('events').select('*').eq('id', id).single();

      setTickets(eventData.tickets as ITicketsSold[]);

      //disable loader
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Error fetching all required data, try again later', { variant: 'error' });
      console.log(error);
    }
  };

  const handleAddCustomerToGuestList = async () => {
    const payload = {
      ticket_name_id: assignTicket?.id,
      quantity: formik.values.quantity
    };

    try {
      const { data, error } = await supabase.rpc('start_transaction', {
        _event_id: id,
        tickets_to_purchase: [payload],
        parameters: {
          currency: 'NGN',
          provider: 'dashboard',
          customer_id: assignUser?.id,
          customer_app: assignUser?.app
        }
      });

      console.log({ data, error });

      await supabase.from('transactions').update({ status: 'success' }).eq('id', data.transaction_id);
      enqueueSnackbar(`Booked successfully`, {
        variant: 'success',
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom'
        }
      });
      modalToggler(null);
    } catch (error) {
      console.log({ error }, '**');
      console.error('Error:', error);
    }
  };

  return (
    <Grid sx={{ paddingBlock: 3, paddingInline: 5 }}>
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
          <CircularLoader />
        </div>
      ) : (
        <>
          <div
            style={{
              margin: 'auto',
              padding: '20px',
              borderRadius: '50%',
              backgroundColor: '#8057DB',
              width: '76px',
              height: '76px',
              border: '1px solid #434343'
            }}
          >
            <ContainerOutlined
              style={{
                fontSize: '36px',
                height: '36px'
              }}
            />
          </div>
          <DialogTitle sx={{ fontSize: '24px', color: '#F0F0F0', textAlign: 'center' }}>Create Assignment</DialogTitle>
          <DialogContentText sx={{ color: '#BFBFBF', textAlign: 'center' }}>Search for a Cene or Cene+ user</DialogContentText>
          <Divider />
          <DialogContent sx={{ paddingInline: '1px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <FormControl>
                    {/* <Autocomplete
                      disablePortal
                      fullWidth
                      value={assignUser}
                      onChange={(e, value) => setAssignUser(value)}
                      options={customers.map((item) => ({
                        label: `${item.first_name} ${item.last_name}`,
                        ...item
                      }))}
                      sx={{}}
                      renderInput={(params) => <TextField {...params} label="Assign to customer" />}
                    /> */}
                    <Autocomplete
                      open={open}
                      onOpen={() => setOpen(true)}
                      value={assignUser}
                      onClose={() => setOpen(false)}
                      onChange={(e, value) => setAssignUser(value)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.label}
                      options={options}
                      loading={loading}
                      onInputChange={async (event, newInputValue) => {
                        setInputValue(newInputValue);
                        fetchUserFromSupabase(newInputValue);
                      }}
                      renderInput={(params) => <TextField {...params} label="Assign to customer" />}
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <FormControl>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      value={assignTicket}
                      onChange={(e, value) => setAssignTicket(value)}
                      options={tickets.map((item) => ({
                        label: item.name,
                        ...item
                      }))}
                      sx={{}}
                      renderInput={(params) => <TextField {...params} label="Pick a ticket to assign to guest" />}
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    id="quantity"
                    type="number"
                    placeholder="Enter Quantity from stock Selected"
                    onChange={handleChange}
                    inputProps={{ min: 1 }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: '0 0 0 1px #BBAFDA'
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                          boxShadow: '0 0 0 1px #BBAFDA'
                        }
                      }
                    }}
                  />
                </Stack>
              </Grid>
              {/* <Grid item xs={12}>
            <Stack spacing={1}>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value="Validated by Choose Team Member"
                  onChange={handleChange}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      boxShadow: '0 0 0 1px #BBAFDA'
                    }
                  }}
                >
                  <MenuItem value="Validated by Choose Team Member">Validated by Choose Team Member</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid> */}
            </Grid>
          </DialogContent>
          <DialogContentText sx={{ fontSize: '14px', color: '#BFBFBF', textAlign: 'center' }}>
            This action is final and cannot be undone
          </DialogContentText>
          <DialogActions sx={{ paddingBlock: 2.5 }}>
            <Grid item>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  onClick={() => modalToggler(null)}
                  variant="outlined"
                  sx={{
                    color: '#fff',
                    borderColor: '#434343',
                    '&:hover': {
                      backgroundColor: '#121212',
                      color: '#fff',
                      borderColor: '#434343'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={() => handleAddCustomerToGuestList()}
                  sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
                >
                  Assign Tickets to User
                </Button>
              </Stack>
            </Grid>
          </DialogActions>
        </>
      )}
    </Grid>
  );
};

export default GuestlistForm;
