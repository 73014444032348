import { getStatusBackgroundColor } from '../table';
import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { ReactComponent as LogoSVG } from '../../../../../../../svg/logo.svg';
import { Transaction } from '..';
import { User } from 'types/user';
import { Event } from '@ceneteam/namespace';
import { format } from 'date-fns';

interface Props {
  transaction: Transaction | null;
  event: Event | null;
  customer: User | null;
  isLoading: boolean;
}

const InvoicePage = ({ transaction, customer, isLoading, event }: Props) => {
  if (!transaction?.partners) return <></>;
  const {
    partners: { first_name, last_name, company_name, email, phone_number },
    updated_at,
    id,
    currency
  } = transaction as Transaction;

  if (isLoading) return <CircularProgress />;

  return (
    <Paper sx={{ backgroundColor: '#121212', backgroundImage: 'none', paddingBlock: 10, mt: 3 }}>
      <Container id="invoice-container" sx={{ width: '964px', border: '1px solid #262626', backgroundColor: '#000' }}>
        <Grid container sx={{ height: '76px', justifyContent: 'space-between', alignItems: 'center', padding: 3 }}>
          <Grid item>
            <LogoSVG fill="#fff" />
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: 'end', padding: 3 }}>
          <Grid item md={3} sx={{ textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <Stack sx={{ gap: 0.5 }}>
              <Typography>{company_name}</Typography>
              <Typography>
                {first_name} {last_name}
              </Typography>
              <Typography>{email}</Typography>
              <Typography>{phone_number}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />

        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item md={4}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Customer</Typography>
            <Stack sx={{ gap: 1.5, mt: 4 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Name</Typography>
                <Typography>
                  {customer?.first_name} {customer?.last_name}
                </Typography>
              </Stack>

              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Phone no.</Typography>
                <Typography>{customer?.phone_number ? customer.phone_number : 'N/A'}</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>E-mail</Typography>
                <Typography>{customer?.email}</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>App</Typography>
                <Typography>{customer?.app}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Order Details</Typography>
            <Stack sx={{ gap: 1.5, mt: 4 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Date</Typography>
                <Typography>{updated_at ? format(new Date(updated_at), 'dd.MM.yyyy') : '-'}</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Status</Typography>
                <Typography
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px 12px',
                    fontWeight: 400,
                    fontSize: '12px',
                    backgroundColor: getStatusBackgroundColor(transaction?.status as string),
                    color: '#000',
                    borderRadius: '4px'
                  }}
                >
                  {transaction?.status}
                </Typography>
              </Stack>

              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Payment</Typography>
                <Typography>{transaction.provider}</Typography>
              </Stack>

              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#8C8C8C' }}>Cene Transaction ID</Typography>
                <Typography>{id}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer sx={{ mt: 3 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#000' }}>
              <TableRow>
                <TableCell>Event</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transaction.finance_details.tickets.map((product) => (
                <TableRow key={product.ticket_name_id}>
                  <TableCell>{event?.name}</TableCell>
                  <TableCell>{product.ticket_name}</TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>
                    {product.price} {product.currency}
                  </TableCell>
                  <TableCell>
                    {product.amount} {product.currency}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="body1">Subtotal:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {transaction.finance_details.total_net_amount} {currency}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="body1">Taxes:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {transaction.finance_details.total_gross_amount - transaction.finance_details.total_net_amount} {currency}
                  </Typography>
                </TableCell>
              </TableRow>
              {/* <TableRow>
              <TableCell colSpan={3} align="right">
                <Typography variant="body1">Discount (5%):</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">${discount.toFixed(2)}</Typography>
              </TableCell>
            </TableRow> */}
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Typography variant="h6" sx={{ color: '#B79BF1' }}>
                    Total:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" sx={{ color: '#B79BF1' }}>
                    {transaction.finance_details.total_gross_amount} {currency}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Paper>
  );
};

export default InvoicePage;
