import { Event, PaymentProviders, Ticket } from '@ceneteam/namespace';
import { ISoldTable } from '..';

export const calculateTableStatsCenePlus = (event: Event, soldTables: ISoldTable[]) => {
  if (!event) {
    return {
      totalSoldTickets: 0,
      totalSoldTables: 0,
      totalRevenue: 0,
      totalAvailableTickets: 0,
      totalAvailableTables: 0,
      percentageTicketsSold: 0,
      percentageTablesSold: 0,
      percentageTicketsAvailable: 0,
      percentageTablesAvailable: 0
    };
  }

  let currency: keyof PaymentProviders | null = null;

  if (event.payments.NGN) {
    currency = 'NGN';
  } else if (event.payments.USD) {
    currency = 'USD';
  } else if (event.payments.EUR) {
    currency = 'EUR';
  } else if (event.payments.GBP) {
    currency = 'GBP';
  }

  if (!currency) {
    console.warn('No valid payment currency found in event.');
    return {
      totalSoldTickets: 0,
      totalSoldTables: 0,
      totalRevenue: 0,
      totalAvailableTickets: 0,
      totalAvailableTables: 0,
      percentageTicketsSold: 0,
      percentageTablesSold: 0,
      percentageTicketsAvailable: 0,
      percentageTablesAvailable: 0
    };
  }

  let totalSoldTables = 0;
  let totalAvailableTables = 0;
  let totalSoldTickets = 0;
  let totalAvailableTickets = 0;
  let totalRevenue = 0;
  let totalTables = 0;

  soldTables.forEach((soldTable) => {
    totalSoldTables += soldTable.total_tables;
  });

  event.tickets?.forEach((ticket) => {
    const ticketTables = ticket.floorplan?.tables || 0;
    const remainingStock = event.stock?.[ticket.id] || 0;
    const ticketPrice = Number(ticket[currency as keyof Ticket]) || 0;

    totalTables += ticketTables;

    totalAvailableTickets += remainingStock;

    totalSoldTickets += (ticket.quantity || 0) - remainingStock;

    totalRevenue += ((ticket.quantity || 0) - remainingStock) * ticketPrice;
  });

  totalAvailableTables = totalTables - totalSoldTables;

  const percentageTicketsSold = totalAvailableTickets ? (totalSoldTickets / (totalSoldTickets + totalAvailableTickets)) * 100 : 0;
  const percentageTablesSold = totalTables ? (totalSoldTables / totalTables) * 100 : 0;

  const totalTickets = totalSoldTickets + totalAvailableTickets;
  const percentageTicketsAvailable = totalTickets ? (totalAvailableTickets / totalTickets) * 100 : 0;

  const totalAllTables = totalSoldTables + totalAvailableTables;
  const percentageTablesAvailable = totalAllTables ? (totalAvailableTables / totalAllTables) * 100 : 0;

  return {
    totalSoldTickets,
    totalSoldTables,
    totalRevenue,
    totalAvailableTickets,
    totalAvailableTables,
    percentageTicketsSold,
    percentageTablesSold,
    percentageTicketsAvailable,
    percentageTablesAvailable
  };
};
