import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Venue } from '..';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { supabase } from 'supabase/supabase';
import Loader from 'components/Loader';
import { FloorplansTable } from './floorplans.table';
import { Floorplan } from 'types/floorplans';
import { ROUTER_VENUE_NEW_FLOORPLAN } from 'constants/routes';
import { seatsioInstance } from 'api/seatsio';
import ReusableModal from 'components/modal/modal';
import AssignPartnerForm from './forms/assignPartner';
import PartnersSection from './partners';
import { ModalContextProvider } from 'contexts/ReusableModalContext';

const VenueDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [venue, setVenue] = useState<Venue | null>(null);
  const [charts, setCharts] = useState<unknown>([]);
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const modalToggler = () => setOpen(!open);

  useEffect(() => {
    fetchVenues();
  }, []);

  const fetchVenues = async () => {
    try {
      const { data } = await supabase.from('venues').select('*,floorplans(*)').eq('id', id).single();

      const { data: charts } = await seatsioInstance.get('/seatsio/charts');

      setCharts(charts.items);
      console.log(charts, 'charts');

      setVenue(data as Venue);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const gotoCreateFloorplan = useCallback(() => navigate(ROUTER_VENUE_NEW_FLOORPLAN.replace(':id', `${id}`)), []);

  if (isLoading) return <Loader />;

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '30px' }}>{venue?.name}</Typography>

        {activeTabIndex === 0 && (
          <Button
            onClick={() => {}}
            variant="contained"
            startIcon={<EditOutlined />}
            sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
          >
            Edit Venue
          </Button>
        )}
        {activeTabIndex === 1 && (
          <Button
            onClick={gotoCreateFloorplan}
            variant="contained"
            startIcon={<PlusOutlined />}
            sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
          >
            New floorplan
          </Button>
        )}
        {activeTabIndex === 2 && (
          <Button
            onClick={modalToggler}
            variant="contained"
            startIcon={<PlusOutlined />}
            sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
          >
            Assign Floorplan to Partner
          </Button>
        )}
      </Stack>
      <Box mt={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTabIndex} onChange={(e, value) => setActiveTabIndex(value)}>
          <Tab label="OVERVIEW" />
          <Tab label="FLOORPLANS" />
          <Tab label="PARTNERS" />
        </Tabs>
      </Box>
      {activeTabIndex === 0 && (
        <>
          <Stack sx={{ gap: 1, mb: 2, mt: 2 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 500, opacity: 0.5 }}>VENUE OVERVIEW</Typography>
            <Typography>{venue?.capacity_details}</Typography>
          </Stack>
          <Stack sx={{ gap: 1, mb: 2 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 500, opacity: 0.5 }}>LOCATION</Typography>
            <Typography>{venue?.address}</Typography>
          </Stack>
          <Stack sx={{ gap: 1, mb: 2 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 500, opacity: 0.5 }}>FLOORSPACE</Typography>
            <Typography>{venue?.floorspace}</Typography>
          </Stack>
        </>
      )}
      {activeTabIndex === 1 && <FloorplansTable charts={charts} floorplans={venue?.floorplans as Floorplan[]} />}
      {activeTabIndex === 2 && (
        <ModalContextProvider>
          <PartnersSection />
        </ModalContextProvider>
      )}

      <ReusableModal currentObject={null} open={open} modalToggler={modalToggler} content={AssignPartnerForm} width="600px" />
    </>
  );
};

export default VenueDetails;
