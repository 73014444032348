import { Event, PaymentProviders, Ticket } from '@ceneteam/namespace';

export const calculateTicketStatsCene = (event: Event) => {
  if (!event || !event.tickets) {
    return {
      totalSold: 0,
      percentageSold: 0,
      totalRevenue: 0,
      totalAvailable: 0,
      percentageAvailable: 0
    };
  }

  let currency: keyof PaymentProviders | null = null;

  if (event.payments.NGN) {
    currency = 'NGN';
  } else if (event.payments.USD) {
    currency = 'USD';
  } else if (event.payments.EUR) {
    currency = 'EUR';
  } else if (event.payments.GBP) {
    currency = 'GBP';
  }

  if (!currency) {
    console.warn('No valid payment currency found in event.');
    return {
      totalSold: 0,
      percentageSold: 0,
      totalRevenue: 0,
      totalAvailable: 0,
      percentageAvailable: 0
    };
  }

  let totalSold = 0;
  let totalAvailable = 0;
  let totalRevenue = 0;

  event.tickets.forEach((ticket) => {
    const ticketStock = event.stock?.[ticket.id] ?? 0;
    const ticketsSold = ticket.quantity ? ticket.quantity - ticketStock : 0;

    totalSold += ticketsSold;
    totalAvailable += ticketStock;

    const ticketPrice = Number(ticket[currency as keyof Ticket]) || 0;

    totalRevenue += ticketsSold * ticketPrice;
  });

  const totalTickets = event.tickets.reduce((sum, ticket) => sum + (ticket.quantity || 0), 0) || 0;

  const percentageSold = totalTickets ? (totalSold / totalTickets) * 100 : 0;
  const percentageAvailable = totalTickets ? (totalAvailable / totalTickets) * 100 : 0;

  return {
    totalSold,
    percentageSold,
    totalRevenue,
    totalAvailable,
    percentageAvailable
  };
};
