import { useParams } from 'react-router';
import { SeatsioEditor } from 'seatsio/editor';

export const FloorplanEditor = () => {
  const { id } = useParams();

  return (
    <>
      <SeatsioEditor chartKey={`${id}`} />
    </>
  );
};
