import { BarChartOutlined, CalendarOutlined, ClockCircleOutlined, FileOutlined } from '@ant-design/icons';
import { Grid } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { Event } from '@ceneteam/namespace';
import { parseISO } from 'date-fns';

interface IProps {
  events: Event[];
}

const EventsStatistic = ({ events }: IProps) => {
  const totalEvents = events.length;
  const ceneEvents = events.filter((event) => event.type === 'cene').length;
  const cenePlusEvents = events.filter((event) => event.type === 'cene+').length;
  const activeEvents = events.filter((event) => {
    const currentDate = new Date();
    const startDate = parseISO(event.starts_at as string);
    const endDate = parseISO(event.ends_at as string);

    return currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime() && event.status === 'published';
  }).length;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Total Events"
          count={totalEvents.toString()}
          icon={<BarChartOutlined style={{ fontSize: '24px', color: '#2196f3' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Active Events"
          count={activeEvents.toString()}
          icon={<CalendarOutlined style={{ fontSize: '24px', color: '#ffcd18' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Cene"
          count={ceneEvents.toString()}
          icon={<FileOutlined style={{ fontSize: '24px', color: '#4caf50' }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AnalyticEcommerce
          title="Cene+"
          count={cenePlusEvents.toString()}
          icon={<ClockCircleOutlined style={{ fontSize: '24px', color: '#ff9800' }} />}
        />
      </Grid>
    </Grid>
  );
};

export default EventsStatistic;
