import { Alert, Autocomplete, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import { timezones } from 'constants/timezones';
import { EventSchema } from '../event.schema';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import ShapeButton from 'components/button/button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

interface IWhenAndWhereStep {
  onNext: () => void;
  onPrev: () => void;
}

export const WhenAndWhereStep = ({ onNext, onPrev }: IWhenAndWhereStep) => {
  const formik = useFormikContext<EventSchema>();
  const [places, setPlaces] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState<string | null>(null);
  const minDate = new Date();

  const fetchPlace = useCallback(
    _.debounce(async (q: string | null) => {
      if (!q) return setPlaces([]);

      try {
        setLoading(true);
        const { data } = await axios.get(`https://nominatim.openstreetmap.org/search?q=${q}&format=json`);

        setPlaces([...(data.map((item: any) => ({ ...item, label: item.display_name })) as [])]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 1000),
    []
  );

  const formType = formik.values.eventType === 'both' ? formik.values.formType : formik.values.eventType;

  const values = formik.values[formType];
  const errors = formik.errors[formType];

  const validationStepForMultipleTypes =
    formik.values.eventType === 'both' &&
    (!formik.values['cene'].location ||
      !formik.values['cene+'].location ||
      Boolean(formik.errors['cene+']?.location) ||
      Boolean(formik.errors['cene']?.location));

  const validationStep = !values.location || Boolean(errors?.location);

  const isLocationCenePlusError = Boolean(formType === 'cene' && formik.errors['cene+']?.location);
  const isLocationCeneError = Boolean(formType === 'cene+' && formik.errors['cene']?.location);

  const handleStartDateChange = (newValue: Date | null) => {
    if (newValue) {
      const startDate = dayjs(newValue);
      const endDate = dayjs(values.ends_at);

      if (endDate.isValid() && startDate.isAfter(endDate)) {
        setDateError('Event start date cannot be later than or equal to the end date.');
      } else {
        setDateError(null);
      }

      formik.setFieldValue(`${formType}.starts_at`, startDate.format('DD.MM.YYYY HH:mm'));
    } else {
      formik.setFieldValue(`${formType}.starts_at`, null);
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue) {
      formik.setFieldValue(`${formType}.ends_at`, dayjs(newValue).format('DD.MM.YYYY HH:mm'));
      formik.setTouched({ [`${formType}.ends_at`]: true });
    }
  };

  const parseDate = (date: string) => {
    return dayjs(date, 'DD.MM.YYYY HH:mm');
  };

  useEffect(() => {
    if (values.starts_at && values.ends_at) {
      const startDate = parseDate(values.starts_at);
      const endDate = parseDate(values.ends_at);

      const diffInMinutes = endDate.diff(startDate, 'minute');

      if (!startDate.isValid() || !endDate.isValid()) {
        setDateError('Invalid date format.');
      } else if (endDate.isSameOrBefore(startDate)) {
        setDateError('Event end date must be later than the start date.');
      } else if (diffInMinutes < 30) {
        setDateError('The event duration must be at least 30 minutes.');
      } else {
        setDateError(null);
      }
    }
  }, [values.starts_at, values.ends_at]);

  return (
    <Stack>
      <Typography mb="16px" fontWeight="300" fontSize="28px" color="#8057DB">
        Event Details - When and Where
      </Typography>
      <Typography fontWeight="300" fontSize="16px">
        Help us set the stage for your event. Please provide the following details:
      </Typography>
      <Stack mt={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack gap={2} alignItems="baseline" flexDirection="row">
            <Stack spacing={1}>
              <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                Event Start
              </InputLabel>
              <DateTimePicker
                value={values.starts_at ? dayjs(values.starts_at, 'DD.MM.YYYY HH:mm').toDate() : null}
                onChange={handleStartDateChange}
                minDate={minDate}
                format="dd.MM.yyyy HH:mm"
                slotProps={{
                  popper: {
                    sx: {
                      [`.${pickersLayoutClasses.root}`]: {
                        marginBlock: '12px'
                      }
                    }
                  }
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                Event End
              </InputLabel>
              <DateTimePicker
                minDate={minDate}
                value={values.ends_at ? dayjs(values.ends_at, 'DD.MM.YYYY HH:mm').toDate() : null}
                onChange={handleEndDateChange}
                format="dd.MM.yyyy HH:mm"
                slotProps={{
                  popper: {
                    sx: {
                      [`.${pickersLayoutClasses.root}`]: {
                        marginBlock: '12px'
                      }
                    }
                  }
                }}
              />
            </Stack>
          </Stack>
        </LocalizationProvider>
      </Stack>

      {dateError && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          {dateError}
        </Alert>
      )}

      <Stack spacing={1} mt={2}>
        <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
          Timezone of the Event
        </InputLabel>
        <Select
          onChange={(e) => {
            formik.setFieldValue(`${formType}.timezone`, e.target.value);
          }}
          value={values.timezone}
          displayEmpty
        >
          {timezones.map((time) => (
            <MenuItem key={time.value} value={time.value}>
              {time.label} ({time.value})
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack spacing={1} mt={2}>
        <InputLabel sx={{ color: '#9983C9' }} required>
          What is the Event Venue Address?
        </InputLabel>
        <Autocomplete
          loading={loading}
          filterOptions={(options) => options}
          key={formType}
          value={values.eventLocationName}
          onChange={(e, value: any) => {
            formik.setFieldValue(`${formType}.eventLocationName`, value ? value.label : '');
            formik.setFieldValue(`${formType}.location`, value ? `(${value?.lat},${value?.lon})` : '');
          }}
          onInputChange={(_, value) => {
            fetchPlace(value);
          }}
          options={places as []}
          renderInput={(params) => (
            <TextField
              value={values.eventLocationName}
              onBlur={() => formik.handleBlur(`${formType}.location`)}
              error={Boolean(errors?.location)}
              helperText={errors?.location}
              placeholder="Event location"
              sx={{ width: '100%' }}
              {...params}
            />
          )}
        />
      </Stack>
      {formik.values.eventType === 'both' && (isLocationCenePlusError || isLocationCeneError) && (
        <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
          Event location for {isLocationCeneError ? 'Cene' : 'Cene+'} is required
        </Alert>
      )}
      <Stack mt={4} flexDirection="row" gap={2}>
        <ShapeButton handleClick={onPrev} width="100%" isOutlined>
          Previous step
        </ShapeButton>
        <ShapeButton disabled={validationStepForMultipleTypes || validationStep || Boolean(dateError)} handleClick={onNext} width="100%">
          Next step
        </ShapeButton>
      </Stack>
    </Stack>
  );
};
