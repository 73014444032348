import { MessageOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const commsHub: NavItemType = {
  id: 'group-comms-hub-loading',
  type: 'group',
  children: [
    {
      id: 'comms-hub',
      title: 'Comms Hub',
      type: 'item',
      url: '/comms-hub',
      icon: MessageOutlined,
      breadcrumbs: false
    }
  ]
};

export default commsHub;
