import { Divider, Stack } from '@mui/material';
import GuestlistsStatisticSection from './guestlistsStatistic';
import GuestlistsTable from './guestlists.table';
import { ModalContextProvider } from 'contexts/ReusableModalContext';
import { useParams } from 'react-router';
import { supabase } from 'supabase/supabase';
import { useEffect, useState } from 'react';
import { Event } from '@ceneteam/namespace';
import { enqueueSnackbar } from 'notistack';
import CircularLoader from 'components/CircularLoader';

interface ITicket {
  ticket_name: string;
  ticket_name_id: string;
  quantity: number;
  ticket_description: string;
  ticket_is_accessible: string;
}

export interface IGuest {
  firstName: string;
  lastName: string;
  customerId: string;
  tickets: { ticket_type: string; ticket_count: string }[];
  date_assigned: string;
  invited_by: string;
  status: string;
  id: string;
}

const GuestlistsPage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [guests, setGuests] = useState<IGuest[]>([]);
  const totalStock = event?.stock ? Object.values(event.stock).reduce((sum, value) => sum + value, 0) : 0;

  useEffect(() => {
    fetchEvent();
    if (id) fetchAssignment();
  }, []);

  const fetchEvent = async () => {
    try {
      setLoading(true);
      const { data } = await supabase.from('events').select('*').eq('id', id).single().throwOnError();
      setEvent(data);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(id);

  const fetchAssignment = async () => {
    try {
      const { data, error } = await supabase
        .from('transactions')
        .select(
          `
        *,
        partner:partners(email)
      `
        )
        .eq('provider', 'dashboard')
        .eq('details_data->>event_id', id);

      if (error) {
        console.error(error);
        return;
      }

      const guests = data.map((transaction) => ({
        firstName: transaction.customer_data.first_name,
        lastName: transaction.customer_data.last_name,
        customerId: transaction.customer_data.id,
        tickets: transaction.details_data.tickets.map((ticket: ITicket) => ({
          ticket_type: ticket.ticket_name,
          ticket_count: ticket.quantity.toString()
        })),
        date_assigned: transaction.created_at,
        invited_by: transaction.partner?.email || 'Partner without e-mail',
        status: transaction.details_data.status,
        id: transaction.id
      }));

      setGuests(guests);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  if (loading)
    return (
      <Stack sx={{ flexDirection: 'row', justifyContent: 'center', mt: 4 }}>
        <CircularLoader />
      </Stack>
    );

  return (
    <>
      <GuestlistsStatisticSection guests={guests} stock={totalStock} />
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <ModalContextProvider>
        <GuestlistsTable event={event as Event} guests={guests} />
      </ModalContextProvider>
    </>
  );
};

export default GuestlistsPage;
