import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

const FloorplanTable = () => {
  const floorplans = [
    {
      name: 'Main Hall',
      event: 'Nigerian Music Festival',
      location: 'Lagos, Nigeria',
      dateTime: 'March 1, 2025, 18:00',
      totalSeats: 300,
      reservedSeats: 150,
      availableSeats: 150
    },
    {
      name: 'Conference Room 1',
      event: 'Technology Summit',
      location: 'Abuja, Nigeria',
      dateTime: 'April 10, 2025, 10:00',
      totalSeats: 200,
      reservedSeats: 100,
      availableSeats: 100
    },
    {
      name: 'Arena 3',
      event: 'Sports Championship',
      location: 'Port Harcourt, Nigeria',
      dateTime: 'May 15, 2025, 14:00',
      totalSeats: 500,
      reservedSeats: 300,
      availableSeats: 200
    }
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Floorplan Name</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>City / Location</TableCell>
            <TableCell>Date & Time</TableCell>
            <TableCell>Total Seats</TableCell>
            <TableCell>Reserved Seats</TableCell>
            <TableCell>Available Seats</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {floorplans.map((floorplan, index) => (
            <TableRow key={index}>
              <TableCell>{floorplan.name}</TableCell>
              <TableCell>{floorplan.event}</TableCell>
              <TableCell>{floorplan.location}</TableCell>
              <TableCell>{floorplan.dateTime}</TableCell>
              <TableCell>{floorplan.totalSeats}</TableCell>
              <TableCell>{floorplan.reservedSeats}</TableCell>
              <TableCell>{floorplan.availableSeats}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FloorplanTable;
