import * as Yup from 'yup';

const venueFormSchema = Yup.object({
  name: Yup.string().required('Venue name is required'),
  address: Yup.string().required('Venue address is required'),
  floorspace: Yup.number().positive('Floorspace must be a positive number').required('Floorspace is required'),
  capacity_details: Yup.string().required('Capacity details are required'),
  location: Yup.string().required('Location is required')
});

export default venueFormSchema;
