import { Event } from '@ceneteam/namespace';

export enum SubscribePlan {
  STARTER = 'Starter',
  PRO = 'Pro',
  PREMIUM = 'Premium'
}

export interface SocialMedia {
  twitter?: string;
  instagram?: string;
  facebook?: string;
  tiktok?: string;
}
export interface PartnerProfile {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  events?: Event[];
  phone_number: string;
  company_name: string;
  date_founded: string;
  updated_at?: string;
  subscription_plan_id: SubscribePlan;
  description: string;
  social_handles: SocialMedia;
  profile_picture_url?: string;
}
