export const getStatusStyle = (status: boolean | null) => {
  if (status === true) {
    return {
      width: 'fit-content',
      minWidth: '50%',
      textAlign: 'center',
      backgroundColor: '#4CAF50',
      color: '#000',
      border: '1px solid #4CAF50',
      padding: '4px 8px',
      borderRadius: '16px',
      fontWeight: 500,
      fontSize: '14px'
    };
  }
  if (status === false) {
    return {
      width: 'fit-content',
      minWidth: '50%',
      textAlign: 'center',
      backgroundColor: '#F44336',
      color: '#000',
      border: '1px solid #F44336',
      padding: '4px 8px',
      borderRadius: '16px',
      fontWeight: 500,
      fontSize: '14px'
    };
  }
  return {
    width: 'fit-content',
    minWidth: '50%',
    textAlign: 'center',
    backgroundColor: '#8C8C8C',
    color: '#fff',
    border: '1px solid #8C8C8C',
    padding: '4px 8px',
    borderRadius: '16px',
    fontWeight: 500,
    fontSize: '14px'
  };
};
