import { Event, Ticket } from '@ceneteam/namespace';
import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import MainCard from 'components/MainCard';
import { ISoldTable } from '..';
import { useState } from 'react';
import { getStatusStyle } from '../statusStyle/statusStyle';
import { getPercentageStatusStyle } from '../statusStyle/percentageStyle';
import ReusableModal from 'components/modal/modal';
import UpdateTicketForm from '../forms/updateTicket/cene';

export enum TablesColumnId {
  ZONE_NAME = 'zoneName',
  TABLES = 'tables',
  SEATS = 'seats',
  TICKET_STATUS = 'ticketStatus',
  TABLES_SOLD = 'tablesSold',
  SEATS_SOLD = 'seatsSold',
  SOLD_PERCENTAGE = 'soldPercentage',

  ACTIONS = 'actions'
}

const columns = [
  { id: TablesColumnId.ZONE_NAME, title: 'Zone name' },
  { id: TablesColumnId.TABLES, title: 'Tables' },
  { id: TablesColumnId.SEATS, title: 'Seats' },
  { id: TablesColumnId.TICKET_STATUS, title: 'Ticket status' },
  { id: TablesColumnId.TABLES_SOLD, title: 'Tables sold' },
  { id: TablesColumnId.SEATS_SOLD, title: 'Seats sold' },
  { id: TablesColumnId.SOLD_PERCENTAGE, title: 'Sold percentage' },
  { id: TablesColumnId.ACTIONS, title: 'Actions' }
];

interface IProps {
  event: Event | null;
  soldTables: ISoldTable[];
  onSuccess: () => void;
}

const TablesInfoTable = ({ event, soldTables, onSuccess }: IProps) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<TablesColumnId>(TablesColumnId.ZONE_NAME);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);

  const tickets = event?.tickets;

  const handleRequestSort = (property: TablesColumnId) => {
    if (property === TablesColumnId.ACTIONS) return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenModal = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTicket(null);
  };

  const calculateSoldPercentage = (tablesSold: number, totalTables: number) => {
    return totalTables === 0 ? 0 : (tablesSold / totalTables) * 100;
  };

  const getSortingValue = (ticket: any, columnId: TablesColumnId) => {
    switch (columnId) {
      case TablesColumnId.ZONE_NAME:
        return ticket.name;
      case TablesColumnId.TABLES:
        return ticket.floorplan?.tables || 0;
      case TablesColumnId.SEATS:
        return ticket.floorplan?.seats || 0;
      case TablesColumnId.TICKET_STATUS:
        return ticket.is_visible;
      case TablesColumnId.TABLES_SOLD:
        return soldTables.find((soldTable) => soldTable.zone_id === ticket.name)?.total_tables || 0;
      case TablesColumnId.SEATS_SOLD:
        return soldTables.find((soldTable) => soldTable.zone_id === ticket.name)?.total_seats || 0;
      case TablesColumnId.SOLD_PERCENTAGE:
        const tablesInZone = ticket.floorplan?.tables || 0;
        const tablesSold = soldTables.find((soldTable) => soldTable.zone_id === ticket.name)?.total_tables || 0;
        return calculateSoldPercentage(tablesSold, tablesInZone);
      default:
        return '';
    }
  };

  const sortedTickets = [...(tickets || [])].sort((a, b) => {
    const aValue = getSortingValue(a, orderBy);
    const bValue = getSortingValue(b, orderBy);

    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <Stack sx={{ mt: 3 }}>
      <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#B6A5DC', mb: 3 }}>Tickets info</Typography>
      <MainCard content={false}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns
                  .filter((column) => column.id !== TablesColumnId.ACTIONS)
                  .map((column) => (
                    <TableCell key={column.id}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                <TableCell>{columns.find((column) => column.id === TablesColumnId.ACTIONS)?.title}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTickets?.map((ticket, index) => {
                const floorplan = ticket.floorplan;
                const tablesInZone = floorplan?.tables || 0;
                const seatsInZone = floorplan?.seats || 0;

                const soldData = soldTables.find((soldTable) => soldTable.zone_id === ticket.name);

                const tablesSold = soldData?.total_tables || 0;

                const seatsSold = soldData?.total_seats || 0;

                const soldPercentage = calculateSoldPercentage(tablesSold, tablesInZone);

                return (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.name}</TableCell>
                    <TableCell>{tablesInZone}</TableCell>
                    <TableCell>{seatsInZone}</TableCell>

                    <TableCell>
                      <Box sx={getStatusStyle(ticket.is_visible)}>{ticket.is_visible ? 'Visible' : 'Hidden'}</Box>
                    </TableCell>
                    <TableCell>
                      {tablesSold}/{tablesInZone}
                    </TableCell>
                    <TableCell>
                      {seatsSold}/{seatsInZone}
                    </TableCell>
                    <TableCell>
                      <Box sx={getPercentageStatusStyle(soldPercentage)}>{soldPercentage.toFixed(2)}%</Box>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenModal(ticket)}>
                        <EditOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>
      {selectedTicket && (
        <ReusableModal
          width="600px"
          open={isModalOpen}
          modalToggler={handleCloseModal}
          onSuccess={onSuccess}
          content={UpdateTicketForm}
          currentObject={selectedTicket}
        />
      )}
    </Stack>
  );
};

export default TablesInfoTable;
