import { CropedPicture } from 'hooks/useCroper';
import { supabase } from 'supabase/supabase';
import { Buffer } from 'buffer';
import { ICropedPicture } from 'hooks/useCrop';
import { v4 as uuidv4 } from 'uuid';

const ataUrlToFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl?.split(',');
  if (arr.length < 2) {
    return undefined;
  }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return undefined;
  }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], 'base64');
  return new File([buff], filename, { type: mime });
};

// OLD
export const uploadPictures = async (pictures: CropedPicture[]) => {
  const session = await supabase.auth.getSession();

  const token = session.data.session?.access_token;

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const formdata = new FormData();

  pictures?.forEach((item) => {
    const base64Str = item?.croped;

    const file = ataUrlToFile(base64Str, item.file.name);

    if (file) formdata.append('files', file, 'images.jpeg');
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
  };

  const imagesAssetsResult = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/assets`, requestOptions);

  return imagesAssetsResult.json();
};

// New
export const uploadPairPictures = async (pictures: ICropedPicture[]): Promise<string[]> => {
  const session = await supabase.auth.getSession();

  const token = session.data.session?.access_token;

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const formdata = new FormData();

  pictures?.forEach(async (item) => {
    const cropedPicture = item?.cropedPicture;
    const originalPicture = item?.originalPicture;

    const file = ataUrlToFile(cropedPicture, uuidv4() + '.jpeg');
    const file2 = ataUrlToFile(originalPicture, uuidv4() + '.jpeg');

    if (file) formdata.append('files', file, 'cropedPicture.jpeg');
    if (file2) formdata.append('files', file2, 'originalPicture.jpeg');
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata
  };

  const imagesAssetsResult = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/assets`, requestOptions);

  return imagesAssetsResult.json();
};

export const convertToBase64 = async (url: string): Promise<string> =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as any);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
