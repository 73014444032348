import { PlusOutlined } from '@ant-design/icons';
import { Button, Stack, Typography } from '@mui/material';
import { DebouncedInput } from 'components/third-party/react-table';
import { useEffect, useState } from 'react';
import MemberTable from '../members/members.table';
import MemberInvitesTable from '../memberInvites/memberInvites.table';
import { Member, MemberInvite } from 'types/member';
import usePartnerId from 'hooks/usePartnerId';
import { enqueueSnackbar } from 'notistack';
import { supabase } from 'supabase/supabase';
import {
  MemberInviteModalContextProvider,
  TeamUserModalContextProvider,
  useMemberInviteModalContext
} from 'contexts/modal/SpecialModalContexts';
import ReusableModal from 'components/modal/modal';
import MemberInviteForm from '../memberInvites/memberInvite.form';
import ReusableTabs from 'components/tabs/reusableTabs';

enum ITab {
  ALL = 'All',
  PENDING_INVITES = 'Pending invites'
}

const TeamPage = () => {
  const [filter, setFilter] = useState('');
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const [teamUsers, setTeamUsers] = useState<Member[]>([]);
  const [membersInvites, setMembersInvites] = useState<MemberInvite[]>([]);

  const [loadingTeamUsers, setLoadingTeamUsers] = useState(true);
  const [loadingInvites, setLoadingInvites] = useState(true);
  const { modalToggler, open } = useMemberInviteModalContext();

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
  };

  const partnerId = usePartnerId();

  useEffect(() => {
    if (partnerId) {
      fetchTeamUsers();
      fetchMemberInvites();
    }
  }, [partnerId]);

  const fetchTeamUsers = async () => {
    try {
      setLoadingTeamUsers(true);

      const { data } = await supabase.from('partner_users').select('*').eq('partner_id', partnerId);

      const filterMembers = data?.filter((item) => item.id !== '00000000-0000-0000-0000-000000000000');

      setTeamUsers(filterMembers as Member[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoadingTeamUsers(false);
    }
  };

  const fetchMemberInvites = async () => {
    try {
      setLoadingInvites(true);
      const { data } = await supabase.from('admin_invitations').select('*').eq('partner_id', partnerId);

      setMembersInvites(data as MemberInvite[]);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoadingInvites(false);
    }
  };

  const concatenateFields = (member: Member | MemberInvite) => {
    const metadataFields = Object.values(member.metadata).join(' ');
    const otherFields = 'email' in member ? member.email : '';
    return `${metadataFields} ${otherFields}`.toLowerCase();
  };

  const filteredTeamUsers = teamUsers.filter((member) => concatenateFields(member).includes(filter.toLowerCase()));

  const filteredInvites = membersInvites.filter((invite) => concatenateFields(invite).includes(filter.toLowerCase()));

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '24px', color: '#b3b3b3' }}>
          Manage your team members and their account permissions here.
        </Typography>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          sx={{
            backgroundColor: '#8057DB',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '&:hover': { backgroundColor: '#8157dbab' }
          }}
        >
          <PlusOutlined />
          Invite Team Member
        </Button>
      </Stack>

      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: '28px' }}>
        <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} />

        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search team member..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>

      <Stack sx={{ mt: 3 }}>
        {activeTab === ITab.ALL && (
          <TeamUserModalContextProvider>
            <MemberTable members={filteredTeamUsers} isLoading={loadingTeamUsers} onSuccess={fetchTeamUsers} />
          </TeamUserModalContextProvider>
        )}
        {activeTab === ITab.PENDING_INVITES && (
          <MemberInviteModalContextProvider>
            <MemberInvitesTable memberInvites={filteredInvites} isLoading={loadingInvites} onSuccess={fetchMemberInvites} />
          </MemberInviteModalContextProvider>
        )}

        <ReusableModal
          currentObject={null}
          open={open}
          modalToggler={() => modalToggler(null)}
          content={MemberInviteForm}
          width="600px"
          onSuccess={fetchMemberInvites}
        />
      </Stack>
    </>
  );
};

const TeamPageContext = () => {
  return (
    <MemberInviteModalContextProvider>
      <TeamPage />
    </MemberInviteModalContextProvider>
  );
};

export default TeamPageContext;
