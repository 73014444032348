import { EventSchema, eventSchema } from './event.schema';
import { EventType } from './steps/eventType';
import { Formik } from 'formik';
import { EventAbout } from './steps/eventAbout';
import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Steps } from './event.steps';
import { EventPreview } from './event.preview';
import { ChooseEventType } from './event.type';
import { WhenAndWhereStep } from './steps/whenAndWhere';
import dayjs from 'dayjs';
import { EventAudience } from './steps/eventAudience';
import { categories } from 'constants/categories';
import { EventLineup } from './steps/eventLineup';
import { EventPaymentMethods } from './steps/eventPaymentMethods';
import { EventTickets } from './steps/tickets';
import { EventLoading } from './event.loading';
import { uploadPairPictures } from 'utils/bucket';
import { ICropedPicture } from 'hooks/useCrop';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { ROUTE_EVENT } from 'constants/routes';
import { v4 as uuidv4 } from 'uuid';
import { Event as EventsType, Image } from '@ceneteam/namespace';

export const Event = () => {
  const { id } = useParams();

  const isNew = id === 'new';

  const [step, setStep] = useState(isNew ? 0 : 1);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingEvent, setLoadingEvent] = useState(!isNew);
  const [event, setEvent] = useState<EventsType | null>(null);

  const navigation = useNavigate();

  const handleNext = useCallback(() => setStep((prev) => prev + 1), []);
  const handlePrev = useCallback(() => setStep((prev) => prev - 1), []);

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    try {
      const { data } = await supabase.from('events').select('*').eq('id', id).single();

      setEvent(data as EventsType);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingEvent(false);
    }
  };

  const DTOEvent = (
    type: 'cene' | 'cene+',
    values: EventSchema,
    partner: any,
    pictures: Image[],
    lineupPictures: EventSchema['cene+']['line_up'],
    isCreate = true
  ) => ({
    partner_id: partner.partner_id,
    ...(partner.partners
      ? {
          partner_info: {
            id: partner.partners.id,
            name: `${partner.partners.first_name} ${partner.partners.last_name}`,
            description: partner.partners.description,
            social_handles: partner.partners.social_handles,
            profile_picture_url: partner.partners.profile_picture_url
          }
        }
      : {}),
    name: values.name,
    group_id: null as unknown as string,
    description: values.description,
    status: 'draft',
    type: type,
    ...(isCreate ? { images: pictures } : { images: [...(event?.images || []), ...pictures] }),
    ...(isCreate ? { featured_image: pictures[0] } : {}),
    line_up: [
      { title: 'Doors open', starts_at: dayjs(values[type].lineup_srart_at).toISOString() },

      ...values[type].line_up.map((item, index) => ({
        title: item.title,

        thumbnail_url: lineupPictures[index].uploadedPictures[0] ? lineupPictures[index].uploadedPictures[0] : [],

        ...(item.isWithoutTime
          ? {}
          : {
              starts_at: dayjs(item.starts_at).toISOString()
            })
      })),

      { title: 'Last Entry', starts_at: dayjs(values[type].lineup_end_at).toISOString() }
    ].sort((a, b) => {
      return dayjs(a.starts_at).isAfter(dayjs(b.starts_at)) ? 1 : -1;
    }),
    location: values[type].location,
    address: values[type].eventLocationName,
    starts_at: dayjs(values[type].starts_at, 'DD.MM.YYYY HH:mm').toISOString(),
    ends_at: dayjs(values[type].ends_at, 'DD.MM.YYYY HH:mm').toISOString(),
    category: values[type].category,
    interest: values[type].interest,
    genres: values[type].genres,
    ...(type === 'cene+'
      ? {
          floorplan_id: values[type].floorplan_id
        }
      : {}),
    stock: Object.fromEntries(
      [...values[type].tickets, ...(values[type].isSimpleTicketsForCenePlus ? values[type].simpleTickets : [])].map((k) => [
        k.id,
        k.quantity
      ])
    ),
    ...(isCreate
      ? {
          tickets: [...values[type].tickets, ...(values[type].isSimpleTicketsForCenePlus ? values[type].simpleTickets : [])].map(
            (ticket) => ({
              id: ticket.id,
              name: ticket.name,
              ...((type === 'cene+' && ticket.isSimple) || type === 'cene'
                ? {
                    sale_starts_at: dayjs(ticket.sale_starts_at).toISOString(),
                    sale_ends_at: dayjs(ticket.sale_ends_at).toISOString()
                  }
                : {}),
              ...((type === 'cene+' && ticket.isSimple) || type === 'cene'
                ? {
                    quantity: ticket.quantity
                  }
                : {}),
              description: ticket.description,
              is_accessible: ticket.is_accessible,
              is_sale_active: ticket.is_sale_active,
              is_visible: true,
              NGN: ticket.price_naira,
              GBP: Math.round(ticket.price_pound * 100),
              USD: Math.round(ticket.price_dollar * 100),
              EUR: Math.round(ticket.price_euro * 100),
              ...(!ticket.isSimple && type === 'cene+'
                ? {
                    floorplan: {
                      tables: ticket.floorplan.tables,
                      seats: ticket.floorplan.seats,
                      ...(ticket.instructions ? { instructions: ticket.instructions } : {}),
                      ...(ticket.food_options ? { food_options: ticket.food_options } : {})
                    }
                  }
                : {})
            })
          )
        }
      : {}),
    ...(values[type].isAge
      ? {
          age_min: values[type].age_min,
          age_max: values[type].age_max
        }
      : {}),
    payments: {
      ...(values[type].isPaymentNaira ? { [`${values[type].paymentNaira}`]: 'stripe' } : {}),
      ...(values[type].isPaymentOutsideNigeria ? { NGN: values[type].paymentOutsideNigeria } : {})
    },
    tags: values[type].tags
  });

  const uploadPictures = async (eventPictures: ICropedPicture[], isCenePlus: boolean = false) => {
    try {
      setLoadingMessage(`Uploading assets for ${isCenePlus ? 'CENE+' : 'CENE'} event...`);
      const uploadedCeneAssets = await uploadPairPictures(eventPictures);

      const convertPictures = uploadedCeneAssets
        .filter((_, index) => index % 2 === 0)
        .map((original, i) => ({
          url: original,
          original_url: uploadedCeneAssets[i * 2 + 1]
        }));

      return convertPictures;
    } catch (error) {
      console.log(error);
    }
  };

  const uploadLineupPictures = async (lineup: EventSchema['cene']['line_up'], isCenePlus: boolean = false) => {
    try {
      setLoadingMessage(`Uploading assets for ${isCenePlus ? 'CENE+' : 'CENE'} lineup...`);

      const result = await Promise.allSettled(
        lineup.map(async (item) => {
          if (item?.thumbnail_url && item?.thumbnail_url[0]) {
            const assetsLineup = await uploadPairPictures([item.thumbnail_url[0]]);

            item.uploadedPictures = assetsLineup;
          }

          return item;
        })
      );

      const successfulResults = result.filter((res: any) => res.status === 'fulfilled').map((res: any) => res.value);

      return successfulResults;
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditEvent = async (values: EventSchema) => {
    try {
      setLoading(true);

      setLoadingMessage('Applying changes...');

      if (!event) return;

      const isCene = event?.type === 'cene';

      let cenePictures: Image[] = [];
      let ceneLineupPictures: EventSchema['cene']['line_up'] = [];

      let cenePlusPictures: Image[] = [];
      let cenePlusLineupPictures: EventSchema['cene+']['line_up'] = [];

      if (isCene) {
        cenePictures = (await uploadPictures(
          values.cene.event_pictures?.filter((item) => item?.cropedPicture?.includes('base64')) as ICropedPicture[]
        )) as Image[];

        // ceneLineupPictures = (await uploadLineupPictures(
        //   values.cene.line_up.filter((item) => item?.thumbnail_url && item?.thumbnail_url[0]?.cropedPicture?.includes('base64'))
        // )) as string[];
      } else {
        cenePlusPictures = (await uploadPictures(
          values['cene+'].event_pictures?.filter((item) => item?.cropedPicture?.includes('base64')) as ICropedPicture[]
        )) as Image[];

        //   cenePlusLineupPictures = (await uploadLineupPictures(
        //     values['cene+'].line_up.filter((item) => item?.thumbnail_url && item?.thumbnail_url[0]?.cropedPicture?.includes('base64'))
        //   )) as string[];
      }

      const session = await supabase.auth.getSession();
      const partner = await supabase
        .from('partner_users')
        .select('partner_id, partners(*)')
        .eq('user_id', session.data.session?.user.id)
        .single();

      const { error, data } = await supabase
        .from('events')
        .update(
          isCene
            ? DTOEvent('cene', values, partner.data, cenePictures as Image[], ceneLineupPictures as EventSchema['cene']['line_up'], false)
            : DTOEvent(
                'cene+',
                values,
                partner.data,
                cenePlusPictures as Image[],
                cenePlusLineupPictures as EventSchema['cene+']['line_up'],
                false
              )
        )
        .eq('id', event.id)
        .select('*');

      if (error) throw error;

      enqueueSnackbar(`Event ${values.name} edited successfully`, {
        variant: 'success',
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        }
      });

      navigation(ROUTE_EVENT.replace(':id', data[0].id));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values: EventSchema) => {
    try {
      setLoading(true);
      let cenePictures: Image[] = [];
      let ceneLineupPictures: EventSchema['cene']['line_up'] = [];

      let cenePlusPictures: Image[] = [];
      let cenePlusLineupPictures: EventSchema['cene+']['line_up'] = [];

      const isCenePlus = values.eventType === 'both' || values.eventType !== 'cene';
      const isCene = values.eventType === 'both' || values.eventType !== 'cene+';

      if (isCene) {
        cenePictures = (await uploadPictures(values.cene.event_pictures as ICropedPicture[])) as Image[];
        ceneLineupPictures = (await uploadLineupPictures(values.cene.line_up)) as EventSchema['cene']['line_up'];
      }

      if (isCenePlus) {
        cenePlusPictures = (await uploadPictures(values['cene+'].event_pictures as ICropedPicture[], true)) as Image[];
        cenePlusLineupPictures = (await uploadLineupPictures(values['cene+'].line_up, true)) as EventSchema['cene+']['line_up'];
      }

      const session = await supabase.auth.getSession();
      const partner = await supabase
        .from('partner_users')
        .select('partner_id, partners(*)')
        .eq('user_id', session.data.session?.user.id)
        .single();

      setLoadingMessage('Event for is being created...');

      const events = [
        isCene && DTOEvent('cene', values, partner.data, cenePictures as Image[], ceneLineupPictures as EventSchema['cene']['line_up']),
        isCenePlus &&
          DTOEvent('cene+', values, partner.data, cenePlusPictures as Image[], cenePlusLineupPictures as EventSchema['cene+']['line_up'])
      ].filter((item) => !!item);

      if (events.length === 2) {
        const groupId = uuidv4();

        events.forEach((event) => {
          if (typeof event === 'object') {
            event.group_id = groupId;
          }
        });
      }

      const { error, data } = await supabase.from('events').insert(events).select('*');

      if (error) throw error;

      enqueueSnackbar(`${values.name} created successfully`, {
        variant: 'success',
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        }
      });

      //hard reload to fetch the right data
      navigation(ROUTE_EVENT.replace(':id', data[0].id));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (!isNew && loadingEvent && !event) return null;

  const initialValues = {
    timezone: 'WAT',
    starts_at: dayjs(event?.starts_at || dayjs()).format('DD.MM.YYYY HH:mm'),
    ends_at: dayjs(event?.ends_at || dayjs()).format('DD.MM.YYYY HH:mm'),
    location: event?.location || '',
    event_pictures:
      event?.images.map(
        (item) =>
          ({
            id: uuidv4(),
            crop: {},
            cropedPicture: item.url,
            originalPicture: item.original_url
          }) as ICropedPicture
      ) || [],
    category: event?.category || categories[0],
    genres: event?.genres || [],
    interest: event?.interest || [],
    isAge: Boolean(event?.age_min || event?.age_max),
    age_min: event?.age_min || 10,
    age_max: event?.age_max || 80,
    tags: event?.tags || [],
    eventLocationName: event?.address || '',
    lineup_srart_at: '',
    lineup_end_at: '',
    line_up:
      event?.line_up.slice(1, event?.line_up.length - 1).map((item) => ({
        id: uuidv4(),
        starts_at: dayjs(item.starts_at || dayjs()).toISOString(),
        title: item.title,
        isWithoutTime: false,
        isDoorsClose: false,
        uploadedPictures: [],
        thumbnail_url: item.thumbnail_url
          ? [
              {
                id: uuidv4(),
                crop: {},
                cropedPicture: item.thumbnail_url,
                originalPicture: item.thumbnail_url
              }
            ]
          : []
      })) || [],
    isPaymentNaira: !isNew,
    isPaymentOutsideNigeria: false,
    paymentNaira: 'USD',
    paymentOutsideNigeria: 'paystack',
    tickets:
      (event?.tickets?.map((item) => ({
        id: item.id,
        name: item.name || '',
        sale_starts_at: dayjs(item.sale_starts_at, 'DD.MM.YYYY HH:mm'),
        sale_ends_at: dayjs(item.sale_ends_at, 'DD.MM.YYYY HH:mm'),
        quantity: item.quantity || 0,
        price_naira: item.NGN || 0,
        price_dollar: item.USD || 0,
        price_euro: item.EUR || 0,
        price_pound: item.GBP || 0,
        is_accessible: item.is_accessible,
        is_sale_active: item.is_sale_active,
        is_visible: item.is_visible
      })) as any) || [],
    simpleTickets: [], // Only for cene+ tickets,
    isSimpleTicketsForCenePlus: false,
    isFloorplan: true
  };

  const isDisabled = !isNew && event?.status === 'published';

  return (
    <Formik<EventSchema>
      initialValues={{
        name: event?.name || '',
        description: event?.description || '',
        eventType: 'cene',
        formType: event?.type || 'cene',
        floorplans: [],
        zones: [],
        cene: initialValues,
        'cene+': initialValues,
        isShowFullLineup: false
      }}
      validationSchema={eventSchema}
      onSubmit={() => {}}
    >
      {({ values }) =>
        loading || loadingEvent ? (
          <EventLoading title={loadingEvent ? 'Event loading...' : loadingMessage} />
        ) : (
          <Stack>
            <Steps activeStep={step} onClickLabel={() => null} />
            {(step === 0 || step === 1) && (
              <Stack mt={2}>
                {step === 0 && <EventType onNext={handleNext} />}
                {step === 1 && (
                  <EventAbout
                    onNext={handleNext}
                    onPrev={() => (isNew ? handlePrev() : navigation(ROUTE_EVENT.replace(':id', id as string)))}
                    isNew={isNew}
                  />
                )}
              </Stack>
            )}
            <Stack mt={4} flexDirection="row" gap={2}>
              <Stack width="100%" maxWidth="450px">
                {values.eventType === 'both' && step >= 2 && <ChooseEventType />}

                {step === 2 && <WhenAndWhereStep onNext={handleNext} onPrev={handlePrev} />}
                {step === 3 && <EventAudience onNext={handleNext} onPrev={handlePrev} />}
                {step === 4 && (
                  <EventLineup onNext={isNew ? handleNext : () => handleEditEvent(values)} onPrev={handlePrev} isNew={isNew} />
                )}
                {step === 5 && isNew && <EventPaymentMethods onNext={handleNext} onPrev={handlePrev} />}
                {step === 6 && isNew && (
                  <EventTickets
                    onEdit={() => handleEditEvent(values)}
                    disabled={isDisabled}
                    onSubmit={() => onSubmit(values)}
                    onPrev={handlePrev}
                  />
                )}
              </Stack>
              {step >= 2 && (
                <Stack width="100%">
                  <EventPreview />
                </Stack>
              )}
            </Stack>
          </Stack>
        )
      }
    </Formik>
  );
};
