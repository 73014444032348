export const getPercentageStatusStyle = (percentage: number) => {
  if (percentage === 100) {
    return {
      width: 'fit-content',
      minWidth: '50%',
      textAlign: 'center',
      backgroundColor: 'rgb(65, 30, 141)',
      color: '#000',
      border: '1px solid rgb(65, 30, 141)',
      padding: '4px 8px',
      borderRadius: '16px',
      fontWeight: 500,
      fontSize: '14px'
    };
  }
  if (percentage >= 50) {
    return {
      width: 'fit-content',
      minWidth: '50%',
      textAlign: 'center',
      backgroundColor: 'rgb(114, 56, 181)',
      color: '#000',
      border: '1px solid rgb(114, 56, 181)',
      padding: '4px 8px',
      borderRadius: '16px',
      fontWeight: 500,
      fontSize: '14px'
    };
  }
  return {
    width: 'fit-content',
    minWidth: '50%',
    textAlign: 'center',
    backgroundColor: 'rgb(160, 93, 213)',
    color: '#000',
    border: '1px solid rgb(160, 93, 213)',
    padding: '4px 8px',
    borderRadius: '16px',
    fontWeight: 500,
    fontSize: '14px'
  };
};
