import MainCard from 'components/MainCard';
import { useState } from 'react';

import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { HeaderCells } from 'components/commonTable/headerCells';

import CircularLoader from 'components/CircularLoader';
import { format, parseISO } from 'date-fns';
import { EyeOutlined } from '@ant-design/icons';
import { User } from 'types/user';
import { useNavigate } from 'react-router';

const getStatusStyles = (status: boolean) => {
  const statusStyles: Record<string, { backgroundColor: string; color: string }> = {
    true: { backgroundColor: '#9175D0', color: '#fff' },
    false: { backgroundColor: '#B1A9C2', color: '#000' }
  };

  return status ? statusStyles['true'] : statusStyles['false'];
};

export enum CustomerColumnId {
  NAME = 'contactName',
  USER_TYPE = 'userType',
  JOINED_ON = 'joinedOn',
  EVENTS = 'events',
  BOUGHT_TICKETS = 'boughtTickets',
  VERIFIED_ACCOUNT = 'onboarding_finished',
  FOLLOWS = 'follows',
  ACTIONS = 'actions',
  GENDER = 'gender',
  DATE_OF_BIRTH = 'Date of Birth'
}

interface TableColumn {
  id: CustomerColumnId;
  title: string;
  sortable: boolean;
  cell?: (customer: User) => React.ReactNode;
}

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const customerColumns: TableColumn[] = [
  { id: CustomerColumnId.NAME, title: 'Name', sortable: true, cell: (customer) => `${customer.first_name} ${customer.last_name}` },
  { id: CustomerColumnId.USER_TYPE, title: 'User type', sortable: true, cell: (customer) => capitalize(customer.app) },
  {
    id: CustomerColumnId.JOINED_ON,
    title: 'Joined on',
    sortable: true,
    cell: (customer) => format(parseISO(customer.created_at as string), 'dd.MM.yyyy')
  },
  { id: CustomerColumnId.GENDER, title: 'Gender', sortable: true, cell: (customer) => (customer.gender ? customer.gender : '-') },
  {
    id: CustomerColumnId.DATE_OF_BIRTH,
    title: 'Date of Birth',
    sortable: true,
    cell: (customer) => (customer.birthdate ? format(parseISO(customer.birthdate as string), 'dd.MM.yyyy') : '-')
  },
  {
    id: CustomerColumnId.VERIFIED_ACCOUNT,
    title: 'Verified Account',
    sortable: true,
    cell: (customer) => {
      const status = customer.onboarding_finished;
      const statusStyle = getStatusStyles(Boolean(customer.onboarding_finished));
      return (
        <Stack
          sx={{
            minWidth: '64px',
            width: 'fit-content',
            backgroundColor: statusStyle.backgroundColor,
            color: statusStyle.color,
            padding: '8px',
            borderRadius: '25px',

            alignItems: 'center'
          }}
        >
          {status ? 'Verified' : 'Pending'}
        </Stack>
      );
    }
  },
  // { id: CustomerColumnId.FOLLOWS, title: 'Follows', sortable: true, cell: () => 3 },
  {
    id: CustomerColumnId.ACTIONS,
    title: 'Actions',
    sortable: false
  }
];

const getColumnValue = (column: TableColumn, customer: User): React.ReactNode => {
  return column.cell ? column.cell(customer) : '';
};

interface CustomersTableRowsProps {
  customers?: User[];
  onSuccess?: () => void;
}

const CustomerTableRows = ({ customers = [], onSuccess = () => null }: CustomersTableRowsProps) => {
  const navigate = useNavigate();

  const handleProfileOpen = (id: string) => {
    navigate(`/customer/${id}`, { replace: false });
  };

  const renderCellContent = (column: TableColumn, customer: User) => {
    if (column.id === CustomerColumnId.ACTIONS) {
      return (
        <IconButton onClick={() => handleProfileOpen(customer.id)}>
          <EyeOutlined />
        </IconButton>
      );
    }
    return getColumnValue(column, customer);
  };

  return (
    <>
      {customers.map((customer) => (
        <>
          <TableRow key={customer.id}>
            {customerColumns.map((column) => (
              <TableCell key={column.id}>{renderCellContent(column, customer)}</TableCell>
            ))}
          </TableRow>
        </>
      ))}
    </>
  );
};

interface CustomersTableProps {
  isLoading?: boolean;
  customers?: User[];
  onSuccess?: () => void;
}

const CustomersTable = ({ onSuccess = () => null, isLoading = false, customers = [] }: CustomersTableProps) => {
  const [sortConfig, setSortConfig] = useState<{ key: CustomerColumnId; direction: 'asc' | 'desc' }>({
    key: CustomerColumnId.NAME,
    direction: 'asc'
  });

  const sortedRequests = [...customers].sort((a, b) => {
    const { key, direction } = sortConfig;
    const column = customerColumns.find((col) => col.id === key);

    const valueA = column ? getColumnValue(column, a) : '';
    const valueB = column ? getColumnValue(column, b) : '';

    const compareValues = (valA: string | number, valB: string | number) => {
      if (typeof valA === 'number' && typeof valB === 'number') {
        return direction === 'asc' ? valA - valB : valB - valA;
      }
      return direction === 'asc' ? String(valA).localeCompare(String(valB)) : String(valB).localeCompare(String(valA));
    };

    if (key === CustomerColumnId.NAME) {
      const fullNameA = `${a.first_name} ${a.last_name}`.trim().toLowerCase();
      const fullNameB = `${b.first_name} ${b.last_name}`.trim().toLowerCase();
      return direction === 'asc' ? fullNameA.localeCompare(fullNameB) : fullNameB.localeCompare(fullNameA);
    }

    if (key === CustomerColumnId.JOINED_ON) {
      const dateA = new Date(a.created_at as string);
      const dateB = new Date(b.created_at as string);
      return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    }

    return compareValues(valueA as string | number, valueB as string | number);
  });

  const requestSort = (key: CustomerColumnId) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <MainCard content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<CustomerColumnId> columns={customerColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={7}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : customers.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7}>
                <Stack textAlign="center">No customers yet</Stack>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <CustomerTableRows onSuccess={onSuccess} customers={sortedRequests} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default CustomersTable;
