import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { Grid, Typography } from '@mui/material';

import navigation from 'menu-items';
import partnerEvents from 'menu-items/partnerEvents';
import commsHub from 'menu-items/commsHub';
import fans from 'menu-items/fans';

import { NavItemType } from 'types/menu';
import { useSupabase } from 'contexts/supabase';

interface Props {
  card?: boolean;
  custom?: boolean;
  heading?: string;
  title?: boolean;
  sx?: object;
}

const Breadcrumbs = ({ card = false, custom = false, heading, title = true, sx }: Props) => {
  const location = useLocation();
  const { role } = useSupabase();

  const [item, setItem] = useState<NavItemType | null>(null);
  const [menuItems, setMenuItems] = useState<NavItemType[]>([]);

  const customLocation = location.pathname;

  const filterMenuItems = (role: string) => {
    let filteredMenuItems = navigation.items.filter((item) => {
      if (
        role === 'Partner' &&
        (item.id === 'group-partners-loading' || item.id === 'group-assets-loading' || item.id === 'group-events-loading')
      ) {
        return false;
      }
      return true;
    });

    if (role === 'Partner') {
      const partnerEventsIndex = filteredMenuItems.findIndex((item) => item.id === 'group-partner-events-loading');
      const commsHubIndex = filteredMenuItems.findIndex((item) => item.id === 'group-comms-hub-loading');
      const fansIndex = filteredMenuItems.findIndex((item) => item.id === 'group-fans-loading');

      if (partnerEventsIndex !== -1) {
        filteredMenuItems.splice(partnerEventsIndex + 1, 0, partnerEvents);
      } else {
        filteredMenuItems.splice(2, 0, partnerEvents);
      }

      if (commsHubIndex !== -1) {
        filteredMenuItems.splice(commsHubIndex + 1, 0, commsHub);
      } else {
        filteredMenuItems.splice(3, 0, commsHub);
      }

      if (fansIndex !== -1) {
        filteredMenuItems.splice(fansIndex + 1, 0, fans);
      } else {
        filteredMenuItems.splice(3, 0, fans);
      }
    }

    setMenuItems(filteredMenuItems);
  };

  useEffect(() => {
    filterMenuItems(role);
  }, [role]);

  const findBreadcrumb = (menuItems: NavItemType[], currentUrl: string): NavItemType | null => {
    for (const menu of menuItems) {
      if (menu.url === currentUrl) {
        return menu;
      }
      if (menu.children) {
        const childItem = findBreadcrumb(menu.children, currentUrl);
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const foundItem = findBreadcrumb(menuItems, customLocation);
    setItem(foundItem);
  }, [customLocation, menuItems]);

  return (
    <Grid container direction="column" spacing={2} sx={{ mb: 3, ...sx }}>
      {title && item && (
        <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
          <Typography variant="h2">{custom ? heading : item?.title}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Breadcrumbs;
