import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField
} from '@mui/material';
import { CustomInput } from 'components/customInput/customInput';
import { useModalContext } from 'contexts/ReusableModalContext';
import { useFormik } from 'formik';
import axios from 'axios';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import venueFormSchema from 'schemas/venueFormSchema';

interface FormProps {
  onSuccess: () => void;
}

const VenueForm = ({ onSuccess }: FormProps) => {
  const [places, setPlaces] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [submittingForm, setSubmittinForm] = useState(false);

  const formik = useFormik({
    initialValues: { name: '', address: '', floorspace: '', capacity_details: '', location: '' },
    validationSchema: venueFormSchema,
    onSubmit: async (values) => {
      setSubmittinForm(true);

      try {
        const { error } = await supabase.from('venues').insert([
          {
            name: values.name,
            address: values.address,
            floorspace: values.floorspace,
            capacity_details: values.capacity_details,
            location: values.location
          }
        ]);

        if (error) {
          throw new Error(error.message);
        }

        enqueueSnackbar('Venue successfully created!', { variant: 'success' });
        onSuccess();
        modalToggler(null);
      } catch (error) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      } finally {
        setSubmittinForm(false);
      }
    }
  });

  const { modalToggler } = useModalContext();

  const fetchPlace = useCallback(
    _.debounce(async (q: string | null) => {
      if (!q) return setPlaces([]);

      try {
        setLoading(true);
        const { data } = await axios.get(`https://nominatim.openstreetmap.org/search?q=${q}&format=json`);

        setPlaces([...(data.map((item: any) => ({ ...item, label: item.display_name })) as [])]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 1000),
    []
  );

  const { values, errors, handleChange, getFieldProps, touched } = formik;

  return (
    <Grid>
      <DialogTitle>Create New Venue</DialogTitle>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="name">Venue Name</InputLabel>
              <TextField
                id="name"
                placeholder="Enter name of the venue..."
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="address">Venue Address</InputLabel>
              <Autocomplete
                loading={loading}
                filterOptions={(options) => options}
                value={values.address}
                onChange={(e, value: any) => {
                  formik.setFieldValue(`address`, value ? value.label : '');
                  formik.setFieldValue(`location`, value ? `(${value?.lat},${value?.lon})` : '');
                }}
                onInputChange={(_, value) => {
                  fetchPlace(value);
                }}
                options={places as []}
                renderInput={(params) => (
                  <TextField
                    value={values.address}
                    onBlur={() => formik.handleBlur(`location`)}
                    error={Boolean(errors?.location)}
                    helperText={errors?.location}
                    placeholder="Venue location"
                    sx={{ width: '100%' }}
                    {...params}
                  />
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="floorspace">Floorspace</InputLabel>
              <TextField
                id="floorspace"
                type="number"
                placeholder="Enter floorspace..."
                {...getFieldProps('floorspace')}
                error={Boolean(touched.floorspace && errors.floorspace)}
                helperText={touched.floorspace && errors.floorspace}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <CustomInput
                name="capacity_details"
                label="Capacity Details"
                placeholder="Enter Capacity Details"
                multiline
                value={values.capacity_details}
                error={errors.capacity_details}
                onChange={handleChange}
                onBlur={formik.handleBlur}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider sx={{ backgroundColor: '#262626' }} />
      <DialogActions sx={{ paddingBlock: 2.5 }}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              onClick={() => {
                modalToggler(null);
              }}
              variant="outlined"
              sx={{
                color: '#fff',
                borderColor: '#434343',
                '&:hover': {
                  backgroundColor: '#121212',
                  color: '#fff',
                  borderColor: '#434343'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={submittingForm}
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
              variant="contained"
              sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
            >
              Create Venue
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default VenueForm;
