import { NavItemType } from 'types/menu';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const assets: NavItemType = {
  id: 'group-assets-loading',
  type: 'group',
  children: [
    {
      permissions: ['*'],
      id: 'assets',
      title: 'Asset Library',
      type: 'item',
      url: '/asset-library',
      icon: LibraryBooksIcon
    }
  ]
};

export default assets;
