import * as Yup from 'yup';

const ticketsSchema = Yup.array(
  Yup.object({
    id: Yup.string(),
    name: Yup.string().required('Name is required'),
    sale_starts_at: Yup.string(),
    sale_ends_at: Yup.string(),
    description: Yup.string(),
    quantity: Yup.number().min(0, 'Invalid quantity'),
    price_naira: Yup.number().required('Price naira is required').min(0, 'Invalid price naira').default(0),
    price_dollar: Yup.number().required('Price dollar is required').min(0, 'Invalid price dollar').default(0),
    price_euro: Yup.number().required('Price euro is required').min(0, 'Invalid price euro').default(0),
    price_pound: Yup.number().required('Price pound is required').min(0, 'Invalid price pound').default(0),
    is_accessible: Yup.boolean(),
    is_sale_active: Yup.boolean(),
    is_visible: Yup.boolean(),

    instructions: Yup.string(),
    food_options: Yup.string(),
    addons: Yup.string(),

    isDescription: Yup.boolean(),
    isInstructions: Yup.boolean(),
    isFoodOptions: Yup.boolean(),
    isAddons: Yup.boolean(),

    isSimple: Yup.boolean(),
    floorplan: Yup.object({
      seats: Yup.number(),
      tables: Yup.number()
    })
  }).required()
).required();

const generalSchema = Yup.object({
  timezone: Yup.string().required('Timezone is a required field'),
  starts_at: Yup.string().required('Event date is a required field'),
  ends_at: Yup.string().required('Event date is a required field'),
  location: Yup.string().required('Event location is a required field'),
  event_pictures: Yup.array(),
  category: Yup.string().required(),
  tags: Yup.array(Yup.string()).min(1, 'Please add min 1 tag').max(3, 'You can add max 3 tags').required(),
  interest: Yup.array(Yup.string()).min(1, 'Please choose min 1 interest').max(5, 'You can choose max 5 item').required(),
  genres: Yup.array(Yup.string()).min(1, 'Please choose min 1 genre').max(5, 'You can choose max 5 item').required(),
  age_min: Yup.number(),
  age_max: Yup.number(),
  isAge: Yup.boolean(),
  lineup_srart_at: Yup.string().required(),
  lineup_end_at: Yup.string().required(),

  line_up: Yup.array(
    Yup.object({
      id: Yup.string(),
      starts_at: Yup.string(),
      title: Yup.string().required('Artist name is required'),
      thumbnail_url: Yup.array(),
      isWithoutTime: Yup.boolean().default(false),
      isDoorsClose: Yup.boolean().default(false),
      uploadedPictures: Yup.array().optional().default([])
    }).required()
  ).required(),
  tickets: ticketsSchema,
  simpleTickets: ticketsSchema,

  eventLocationName: Yup.string(),

  isPaymentNaira: Yup.boolean(),
  paymentNaira: Yup.string(),

  isPaymentOutsideNigeria: Yup.boolean(),
  paymentOutsideNigeria: Yup.string(),

  isFloorplan: Yup.boolean().default(true),
  isSimpleTicketsForCenePlus: Yup.boolean(),
  floorplan_id: Yup.string()
});

export const eventSchema = Yup.object({
  name: Yup.string().required('Event name is a required field'),
  description: Yup.string().required('Description is a required field'),
  eventType: Yup.string().oneOf(['cene', 'cene+', 'both']).required(),

  formType: Yup.string().oneOf(['cene', 'cene+']).required(),

  cene: generalSchema,
  'cene+': generalSchema,

  floorplans: Yup.array(),
  zones: Yup.array(),
  isShowFullLineup: Yup.boolean().optional()
});

export const singleTicketSchema = Yup.object()
  .shape({
    name: Yup.string().required('Ticket name is required'),
    description: Yup.string(),
    quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1'),
    price_dollar: Yup.number().required('Dollar price is required'),
    price_pound: Yup.number().required('Pound price is required'),
    price_euro: Yup.number().required('Euro price is required'),
    price_naira: Yup.number().required('Naira price is required'),
    sale_starts_at: Yup.string().required('Start date is required'),
    sale_ends_at: Yup.string().required('End date is required'),
    is_sale_active: Yup.boolean(),
    is_accessible: Yup.boolean(),
    is_visible: Yup.boolean()
  })
  .required();

export type EventSchema = Yup.InferType<typeof eventSchema>;
