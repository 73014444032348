import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Stack } from '@mui/material';
import { Button } from '@mui/material';
import { CenePlusRequestsModalContextProvider, useCenePlusRequestsModalContext } from 'contexts/modal/SpecialModalContexts';
import CustomersStatistic from './customersStatistic';
import { User } from 'types/user';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import CustomersTable from './customers.table';
import { DebouncedInput } from 'components/third-party/react-table';
import ReusableTabs from 'components/tabs/reusableTabs';
import ReusableModal from 'components/modal/modal';
import CustomerForm from './customerInvite.form';
import { ModalContextProvider, useModalContext } from 'contexts/ReusableModalContext';
import CreateCustomerInvite from './createCustomer';

enum ITab {
  ALL = 'All',
  CENE = 'Cene',
  CENE_PLUS = 'Cene+',
  INCOMPLETE_PROFILES = 'Incomplete Profiles'
}

const CustomersPageLayout = () => {
  const { modalToggler, open } = useCenePlusRequestsModalContext();
  const { modalToggler: modalTogglerCene, open: openCene } = useModalContext();
  const [customers, setCustomers] = useState<User[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [activeTab, setActiveTab] = useState<ITab>(ITab.ALL);
  const [currentPage, setCurrentPage] = useState(1);
  const [customerCounts, setCustomerCounts] = useState<{
    totalRows?: number | null;
    totalCount?: number | null;
    ceneCount?: number | null;
    cenePlusCount?: number | null;
  }>({
    totalRows: 0,
    totalCount: 0,
    ceneCount: 0,
    cenePlusCount: 0
  });

  const itemsPerPage = 40;

  useEffect(() => {
    getCustomerCounts();
  }, []);

  useEffect(() => {
    fetchRequests();
  }, [currentPage, activeTab, filter]);

  const getCustomerCounts = async () => {
    try {
      setLoading(true);

      const { count: totalRows } = await supabase.from('customers').select('*', { count: 'exact' }).limit(1).throwOnError();

      const { count: ceneCount } = await supabase
        .from('customers')
        .select('*', { count: 'exact' })
        .eq('app', 'cene')
        .limit(1)
        .throwOnError();

      const { count: cenePlusCount } = await supabase
        .from('customers')
        .select('*', { count: 'exact' })
        .eq('app', 'cene+')
        .limit(1)
        .throwOnError();

      setCustomerCounts((prev) => ({
        ...prev,
        totalRows,
        ceneCount,
        cenePlusCount
      }));
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchRequests = async () => {
    try {
      setLoading(true);

      let query = supabase
        .from('customers')
        .select('*', { count: 'exact' })
        .range((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage - 1)
        .limit(itemsPerPage)
        .throwOnError();

      if (activeTab !== ITab.ALL) {
        const eqValue = activeTab === ITab.CENE ? 'cene' : 'cene+';
        query = query.eq(
          activeTab === ITab.INCOMPLETE_PROFILES ? 'onboarding_finished' : 'app',
          activeTab === ITab.INCOMPLETE_PROFILES ? false : eqValue
        );
      }

      if (filter) {
        const terms = filter.trim().split(/\s+/);
        if (terms.length === 1) {
          const term = `%${terms[0]}%`;
          query = query.or(`first_name.ilike.${term},last_name.ilike.${term}`);
        } else if (terms.length > 1) {
          const firstNameTerm = `%${terms[0]}%`;
          const lastNameTerm = `%${terms.slice(1).join(' ')}%`;
          query = query.or(`and(first_name.ilike.${firstNameTerm},last_name.ilike.${lastNameTerm})`);
        }
      }

      query = query.range((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage - 1);

      const { data, error, count } = await query;

      setCustomerCounts((prev) => ({
        ...prev,
        totalCount: count
      }));

      if (error) throw error;

      setCustomers(data);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const filterCustomers = () => {
    switch (activeTab) {
      case ITab.CENE:
        return customers.filter((customer) => customer.app === 'cene');
      case ITab.CENE_PLUS:
        return customers.filter((customer) => customer.app === 'cene+');
      case ITab.INCOMPLETE_PROFILES:
        return customers.filter((customer) => customer.onboarding_finished === false);
      default:
        return customers;
    }
  };

  const totalPages = Math.ceil((customerCounts.totalCount as number) / itemsPerPage);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          onClick={() => modalTogglerCene(null)}
          variant="contained"
          sx={{
            backgroundColor: '#4A7DFF',
            color: '#000',
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            gap: '8px',
            '&:hover': { backgroundColor: '#4a7dffde' }
          }}
        >
          <PlusOutlined />
          Cene Customer
        </Button>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          sx={{
            backgroundColor: '#8057DB',
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            gap: '8px',
            '&:hover': { backgroundColor: '#8157dbab' }
          }}
        >
          <PlusOutlined />
          Cene+ request
        </Button>
      </Stack>
      <CustomersStatistic
        totalCount={customerCounts.totalRows || 0}
        ceneCount={customerCounts.ceneCount || 0}
        cenePlusCount={customerCounts.cenePlusCount || 0}
        customers={customers}
      />
      <Stack
        sx={{
          mt: 3,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderInline: '1px solid #1e1e1e',
          borderTop: '1px solid #1e1e1e',
          padding: '20px'
        }}
      >
        <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} />

        <Stack width={300}>
          <DebouncedInput
            value={filter ?? ''}
            onFilterChange={(value) => setFilter(String(value))}
            placeholder="Search customer..."
            sx={{ width: '300px !important' }}
          />
        </Stack>
      </Stack>
      <Stack>
        <CustomersTable customers={filterCustomers()} isLoading={isLoading} />
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} sx={{ mt: 3, alignSelf: 'center' }} />
      </Stack>
      <ReusableModal
        currentObject={null}
        open={openCene}
        modalToggler={() => modalTogglerCene(null)}
        content={CreateCustomerInvite}
        width="520px"
      />
      <ReusableModal currentObject={null} open={open} modalToggler={() => modalToggler(null)} content={CustomerForm} width="600px" />
    </>
  );
};

const CustomersPage = () => {
  return (
    <CenePlusRequestsModalContextProvider>
      <ModalContextProvider>
        <CustomersPageLayout />
      </ModalContextProvider>
    </CenePlusRequestsModalContextProvider>
  );
};

export default CustomersPage;
