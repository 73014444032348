import { DollarCircleOutlined } from '@ant-design/icons';
import { NavItemType } from 'types/menu';

const financials: NavItemType = {
  id: 'group-financials-loading',
  type: 'group',
  children: [
    {
      id: 'financials',
      title: 'Financials',
      type: 'item',
      url: '/financials',
      icon: DollarCircleOutlined
    }
  ]
};

export default financials;
