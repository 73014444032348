import { Button, DialogActions, DialogContent, Divider, Grid, InputLabel, Stack, TextField, Alert, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useFormik } from 'formik';

import { customerFormSchema } from 'schemas/customerFormSchema';
import { User } from 'types/user';
import { supabase } from 'supabase/supabase';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { UserType } from 'types/customer';
import { format, parse, parseISO } from 'date-fns';
import MusicGenres from './musicGenres';
import Interests from './interests';

export const customerUserTypes = [{ value: UserType.CENE }, { value: UserType.CENE_PLUS }];

const getInitialValues = (customer: User | null) => {
  const newUser = {
    ...customer
  };

  return newUser;
};

export type CustomerFormProps = { currentObject: User | null; onSuccess?: () => void };

const CustomerForm = ({ currentObject, onSuccess = () => null }: CustomerFormProps) => {
  const [supabaseError, setSupabaseError] = useState<string | null>(null);
  const currentDate = format(parseISO(currentObject?.created_at as string), 'dd.MM.yyyy');

  const formik = useFormik({
    initialValues: getInitialValues(currentObject),

    validationSchema: customerFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSupabaseError(null);

        if (currentObject) {
          const { error } = await supabase
            .from('customers')
            .update([
              {
                ...values
              }
            ])
            .eq('id', currentObject.id);

          if (error) return setSupabaseError(error.message);

          enqueueSnackbar('Customer updated successfully', { variant: 'success' });
        } else {
          const { error } = await supabase.from('customers').insert([
            {
              ...values
            }
          ]);

          if (error) return setSupabaseError(error.message);

          await supabase.auth.admin.inviteUserByEmail(`${values.email}`, {
            data: {
              app: values.app
            }
          });

          enqueueSnackbar('Customer created successfully', { variant: 'success' });
        }

        onSuccess();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleCancel = () => {
    formik.resetForm({
      values: getInitialValues(currentObject)
    });
  };

  const handleGenreChange = (updatedGenres: string[]) => {
    formik.setFieldValue('genres', updatedGenres);
  };

  const handleInterestChange = (updatedInterests: string[]) => {
    formik.setFieldValue('interests', updatedInterests);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {supabaseError && (
          <Alert severity="error" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '300px' }}>
            {supabaseError}
          </Alert>
        )}

        <DialogContent sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="firstName" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      First Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="firstName"
                      placeholder="Enter first name..."
                      {...getFieldProps('first_name')}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastName" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Last Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="lastName"
                      placeholder="Enter last name..."
                      {...getFieldProps('last_name')}
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="gender" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Gender
                    </InputLabel>
                    <TextField fullWidth id="gender" placeholder="Enter customer gender..." value={currentObject?.gender || null} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Email
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="email"
                      placeholder="Enter customer e-mail"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="phone" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Phone number
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="phone"
                      placeholder="Enter customer phone..."
                      {...getFieldProps('phone_number')}
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="date_of_birth" sx={{ color: '#8C8C8C', fontSize: '14px' }}>
                      Date of Birth
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={currentObject?.birthdate ? parse(currentObject.birthdate, 'yyyy-MM-dd', new Date()) : null}
                        onChange={(date) => {
                          formik.setFieldValue('birthdate', date ? format(date, 'yyyy-MM-dd') : null);
                        }}
                        format="dd.MM.yyyy"
                        slotProps={{
                          popper: {
                            sx: {
                              [`.${pickersLayoutClasses.root}`]: {
                                marginBlock: '12px'
                              }
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel sx={{ color: '#8C8C8C', fontSize: '14px' }}>Joined Cene</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={currentObject?.created_at ? parse(currentDate as string, 'dd.MM.yyyy', new Date()) : null}
                        format="dd.MM.yyyy"
                        sx={{
                          '& .MuiOutlinedInput-root.Mui-disabled': {
                            backgroundColor: '#272727',
                            '& .MuiOutlinedInput-input': { opacity: '1', WebkitTextFillColor: '#fff', color: '#fff' }
                          }
                        }}
                        disabled
                        slotProps={{
                          popper: {
                            sx: {
                              [`.${pickersLayoutClasses.root}`]: {
                                marginBlock: '12px'
                              }
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Typography sx={{ fontSize: '16px' }}>Cene Details</Typography>
                    <Divider
                      sx={{
                        marginTop: '16px !important',
                        position: 'relative',
                        width: 'calc(100% + 40px)',
                        left: '-20px',
                        borderColor: '#262626'
                      }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="genres" sx={{ color: '#91DCBE' }}>
                      Music Preferences
                    </InputLabel>
                    <MusicGenres
                      type={currentObject?.app as string}
                      selectedGenres={(formik.values?.genres as string[]) || []}
                      onGenreChange={handleGenreChange}
                    />
                  </Stack>
                </Grid>
                <Divider
                  sx={{
                    marginTop: '16px !important',
                    position: 'relative',
                    width: 'calc(100% + 40px)',
                    left: '-20px',
                    borderColor: '#262626'
                  }}
                />
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="interests" sx={{ color: '#8057DB' }}>
                      Interests
                    </InputLabel>
                    <Interests
                      type={currentObject?.app as string}
                      selectedInterests={(formik.values?.interests as string[]) || []}
                      onInterestChange={handleInterestChange}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ p: 2.5 }}>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                onClick={handleCancel}
                variant="outlined"
                sx={{
                  color: '#fff',
                  borderColor: '#434343',
                  '&:hover': {
                    backgroundColor: '#121212',
                    color: '#fff',
                    borderColor: '#434343'
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                disabled={isSubmitting}
                sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </DialogActions>
      </LocalizationProvider>
    </>
  );
};

export default CustomerForm;
