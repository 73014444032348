import { Stack, Typography } from '@mui/material';
import FloorplanTable from './partnerFloorplans.table';

const PartnerFloorplansPage = () => {
  return (
    <Stack>
      <Stack sx={{ mb: 3 }}>
        <Typography sx={{ fontSize: '16px' }}>
          Here you can view the assigned floorplans for your events. Editing is restricted, but you can check seating availability and other
          details.
        </Typography>
      </Stack>
      <FloorplanTable />
    </Stack>
  );
};

export default PartnerFloorplansPage;
