import { EyeOutlined, GroupOutlined, PlusOutlined, ScheduleOutlined } from '@ant-design/icons';
import MainCard from 'components/MainCard';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { DebouncedInput } from 'components/third-party/react-table';

import {
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from '@mui/material';

import { HeaderCells } from 'components/commonTable/headerCells';

import TableExport from 'components/commonTable/tableExport';
import CircularLoader from 'components/CircularLoader';
import { Partner, SubscribePlan } from 'types/partner';
import { parse } from 'date-fns';
import { usePartnerModalContext } from 'contexts/modal/SpecialModalContexts';
import { usePermission } from 'hooks/usePermission';
import ReusableModal from 'components/modal/modal';
import EventOrganizerForm from './partners.form';

import { useNavigate } from 'react-router';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { EventStatus } from '@ceneteam/namespace';
import dayjs from 'dayjs';

export enum EventOrganizerColumnId {
  EVENT_ORGANIZER = 'eventOrganizer',
  SUBSCRIPTION_TYPE = 'subscriptionType',
  ACCOUNT_OWNER = 'accountOwner',
  JOINED_ON = 'joinedOn',
  TOTAL_EVENTS = 'totalEvents',
  TICKETS_SOLD = 'ticketsSold',
  ACTIVE_EVENTS = 'activeEvents',
  ACTIONS = 'actions'
}

interface TableColumn {
  id: EventOrganizerColumnId;
  title: string;
  sortable: boolean;
  cell?: (partner: Partner) => React.ReactNode;
}

const eventOrganizerColumns: TableColumn[] = [
  { id: EventOrganizerColumnId.EVENT_ORGANIZER, title: 'Event Organizer', sortable: true },
  { id: EventOrganizerColumnId.SUBSCRIPTION_TYPE, title: 'Subscription Type', sortable: true },
  { id: EventOrganizerColumnId.ACCOUNT_OWNER, title: 'Account Owner', sortable: true },
  { id: EventOrganizerColumnId.JOINED_ON, title: 'Joined on', sortable: true },
  { id: EventOrganizerColumnId.TOTAL_EVENTS, title: 'Total events', sortable: true },
  { id: EventOrganizerColumnId.TICKETS_SOLD, title: 'Tickets', sortable: true },
  { id: EventOrganizerColumnId.ACTIVE_EVENTS, title: 'Active events', sortable: true },
  { id: EventOrganizerColumnId.ACTIONS, title: 'Actions', sortable: false }
];

const columnConfig: Record<EventOrganizerColumnId, keyof Partner | ((partner: Partner) => string | number)> = {
  [EventOrganizerColumnId.EVENT_ORGANIZER]: (partner) => partner.company_name,
  [EventOrganizerColumnId.SUBSCRIPTION_TYPE]: (partner) => partner.subscription_plan_id,
  [EventOrganizerColumnId.ACCOUNT_OWNER]: (partner) => `${partner.first_name} ${partner.last_name}`,
  [EventOrganizerColumnId.JOINED_ON]: (partner) => `${dayjs(partner.created_at).format('DD.MM.YYYY')}`,
  [EventOrganizerColumnId.TOTAL_EVENTS]: (partner) => partner.events.length,
  [EventOrganizerColumnId.TICKETS_SOLD]: (partner) => partner.events.reduce((sum, obj) => sum + (obj?.tickets || []).length, 0),
  [EventOrganizerColumnId.ACTIVE_EVENTS]: (partner) => partner.events.filter((item) => item.status === EventStatus.Published).length,
  [EventOrganizerColumnId.ACTIONS]: () => ''
};

const getColumnValue = (columnId: EventOrganizerColumnId, partner: Partner): string | number => {
  const config = columnConfig[columnId];
  return typeof config === 'function' ? config(partner) : (partner[config] as string | number);
};

interface PartnerTableRowsProps {
  partners: Partner[];
  onSuccess?: () => void;
}

const EventOrganizerTableRows = ({ partners = [], onSuccess = () => null }: PartnerTableRowsProps) => {
  const navigate = useNavigate();

  const handleProfileOpen = (id: string) => {
    navigate(`/partner/${id}`, { replace: false });
  };

  const renderCellContent = (column: TableColumn, partner: Partner) => {
    if (column.id === EventOrganizerColumnId.ACTIONS) {
      return (
        <IconButton onClick={() => handleProfileOpen(partner.id)}>
          <EyeOutlined />
        </IconButton>
      );
    }
    return getColumnValue(column.id, partner);
  };

  return (
    <>
      {partners.map((partner) => (
        <TableRow key={partner.id}>
          {eventOrganizerColumns.map((column) => (
            <TableCell key={column.id}>{renderCellContent(column, partner)}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface PartnersTableProps {
  isLoading?: boolean;
  partners?: Partner[];
  onSuccess?: () => void;
}

const PartnersTable = ({ onSuccess = () => null, isLoading = false, partners = [] }: PartnersTableProps) => {
  const { permissions, isSuperAdmin } = usePermission();
  const { modalToggler, selectedItem, open } = usePartnerModalContext();
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: EventOrganizerColumnId; direction: 'asc' | 'desc' }>({
    key: EventOrganizerColumnId.EVENT_ORGANIZER,
    direction: 'asc'
  });

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterPartners = partners.filter((partner) =>
    eventOrganizerColumns.some((column) => {
      const value = getColumnValue(column.id, partner);
      return typeof value === 'string' || typeof value === 'number' ? String(value).toLowerCase().includes(filter.toLowerCase()) : false;
    })
  );

  const sortedPartners = [...filterPartners].sort((a, b) => {
    const { key, direction } = sortConfig;
    const valueA = getColumnValue(key, a);
    const valueB = getColumnValue(key, b);

    if (key === EventOrganizerColumnId.SUBSCRIPTION_TYPE) {
      const plansOrder = [SubscribePlan.STARTER, SubscribePlan.PRO, SubscribePlan.PREMIUM];
      return direction === 'asc'
        ? plansOrder.indexOf(valueA as SubscribePlan) - plansOrder.indexOf(valueB as SubscribePlan)
        : plansOrder.indexOf(valueB as SubscribePlan) - plansOrder.indexOf(valueA as SubscribePlan);
    }

    if (key === EventOrganizerColumnId.JOINED_ON) {
      const dateA = parse(a.date_founded, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.date_founded, 'yyyy-MM-dd', new Date());
      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    }

    return direction === 'asc' ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
  });

  const requestSort = (key: EventOrganizerColumnId) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const eventsCount = partners.reduce((sum, obj) => sum + obj.events.length, 0);

  return (
    <MainCard content={false}>
      <Grid container sx={{ gap: '8px', pt: '16px', paddingInline: '16px' }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce icon={<GroupOutlined />} title="Partners" count={partners.length.toString()} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce icon={<ScheduleOutlined />} title="Events" count={`${eventsCount}`} />
        </Grid>
      </Grid>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: 2, ...(matchDownSM && { '& .MuiOutlinedInput-root, & .MuiFormControl-root': { width: '100%' } }) }}
      >
        <DebouncedInput value={filter ?? ''} onFilterChange={(value) => setFilter(String(value))} placeholder="Search..." />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" sx={{ width: { xs: '100%', sm: 'auto' } }}>
          <Stack direction="row" spacing={2} alignItems="center">
            {(permissions.includes('partners:c') || isSuperAdmin) && (
              <Button
                disabled={isLoading}
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={() => modalToggler(null)}
                sx={{ backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
              >
                Add Partner
              </Button>
            )}
            <TableExport data={partners} filename="partners.csv" />
          </Stack>
        </Stack>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCells<EventOrganizerColumnId> columns={eventOrganizerColumns} sortConfig={sortConfig} onRequestSort={requestSort} />
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={eventOrganizerColumns.length}>
                <CircularLoader />
              </TableCell>
            </TableRow>
          ) : partners.length === 0 ? (
            <TableRow>
              <TableCell colSpan={eventOrganizerColumns.length}>
                <Stack textAlign="center">No Partners yet</Stack>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              <EventOrganizerTableRows onSuccess={onSuccess} partners={sortedPartners} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ReusableModal
        width="600px"
        open={open}
        modalToggler={() => modalToggler(null)}
        onSuccess={onSuccess}
        content={EventOrganizerForm}
        currentObject={selectedItem}
      />
    </MainCard>
  );
};

export default PartnersTable;
