import { supabase } from 'supabase/supabase';
import { format, parseISO } from 'date-fns';

export interface Purchase {
  updated_at: string;
  event_id: string;
  event: string;
  organizer: string;
  status: string;
  ticket_type: string;
  tickets_bought: number;
  app: string;
  table: string;
}

export const fetchTransactions = async (customerId: string): Promise<Purchase[]> => {
  try {
    const { data: transactions, error: transactionsError } = await supabase
      .from('transactions')
      .select('*, partners:partner_id (company_name)')
      .eq('customer_id', customerId)
      .eq('status', 'success');

    if (transactionsError) throw transactionsError;
    if (!transactions || transactions.length === 0) return [];

    const eventIds = transactions.map((transaction) => transaction.details_data?.event_id).filter(Boolean);
    const { data: events, error: eventsError } = await supabase.from('events').select('id, name').in('id', eventIds);
    if (eventsError) throw eventsError;

    const eventsMap = (events || []).reduce<Record<string, string>>((acc, event) => {
      acc[event.id] = event.name;
      return acc;
    }, {});

    const ticketIds = transactions.flatMap((transaction) => transaction.ticket_ids || []);
    if (ticketIds.length === 0) return [];

    const ticketIdsByTransaction = transactions.reduce<Record<string, Set<string>>>((acc, transaction) => {
      const ticketIds = transaction.ticket_ids || [];
      acc[transaction.id] = new Set(ticketIds);
      return acc;
    }, {});

    const { data: tickets, error: ticketsError } = await supabase
      .from('tickets')
      .select('id, table_id, zone_id, event_id')
      .in('id', ticketIds);

    if (ticketsError) throw ticketsError;

    const ticketsByEventAndTransaction = Object.keys(ticketIdsByTransaction).reduce<
      Record<string, Record<string, Record<string, Set<string>>>>
    >((acc, transactionId) => {
      const validTicketIds = ticketIdsByTransaction[transactionId] || new Set();
      const filteredTickets = tickets?.filter((ticket) => validTicketIds.has(ticket.id)) || [];

      filteredTickets.forEach((ticket) => {
        const eventId = ticket.event_id;
        const zoneId = ticket.zone_id || 'Unknown Zone';
        const tableId = ticket.table_id || 'Unknown Table';

        if (!acc[transactionId]) acc[transactionId] = {};
        if (!acc[transactionId][eventId]) acc[transactionId][eventId] = {};
        if (!acc[transactionId][eventId][zoneId]) acc[transactionId][eventId][zoneId] = new Set();

        acc[transactionId][eventId][zoneId].add(tableId);
      });

      return acc;
    }, {});

    const purchases: Purchase[] = (transactions || []).map((transaction: any) => {
      const details = transaction.details_data || {};
      const tickets = details.tickets || [];
      const totalQuantity = tickets.reduce((sum: number, ticket: any) => sum + (ticket.quantity || 0), 0);
      const ticketNames = [...new Set(tickets.map((ticket: any) => ticket.ticket_name || 'Unknown Ticket'))].join(', ');

      const tableZoneMapString = Object.entries(ticketsByEventAndTransaction[transaction.id]?.[details.event_id] || {})
        .map(([zoneId, tableIds]) => {
          const tableIdsArray = Array.isArray(tableIds) ? tableIds : Array.from(tableIds || []);
          return `${zoneId}: ${tableIdsArray.join(', ')}`;
        })
        .join('; ');

      return {
        updated_at: format(parseISO(transaction.updated_at as string), 'dd.MM.yyyy'),
        event: eventsMap[details.event_id] || 'Unknown Event',
        event_id: details.event_id,
        organizer: transaction.partners?.company_name || 'Unknown Organizer',
        status: transaction.status,
        ticket_type: ticketNames,
        tickets_bought: totalQuantity,
        app: transaction.app || 'Unknown App',
        table: tableZoneMapString
      };
    });

    return purchases;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};
