import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MainCard from 'components/MainCard';
import { Transaction } from '..';
import { User } from 'types/user';
import { format } from 'date-fns';
import { Event } from '@ceneteam/namespace';

interface Props {
  transaction: Transaction | null;
  customer: User | null;
  event: Event | null;
  isLoading: boolean;
}

const DetailsPage = ({ transaction, customer, isLoading, event }: Props) => {
  if (!transaction?.partners) return <></>;
  const {
    partners: { first_name, last_name },
    updated_at,
    id,
    amount,
    currency
  } = transaction as Transaction;

  if (isLoading) return <CircularProgress />;

  return (
    <Stack sx={{ mt: 5, padding: 4, backgroundColor: '#000' }} id="receipt-container">
      <Grid container spacing={2}>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>Name:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>
                {customer?.first_name} {customer?.last_name}
              </Typography>
              <AccountCircleIcon />
            </Stack>
          </MainCard>
        </Grid>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>Placed on:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{updated_at ? format(new Date(updated_at), 'dd.MM.yyyy') : '-'}</Typography>
              <CalendarTodayIcon />
            </Stack>
          </MainCard>
        </Grid>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>Phone number:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{customer?.phone_number ? customer?.phone_number : '-'}</Typography>
              <PhoneIcon />
            </Stack>
          </MainCard>
        </Grid>
        <Grid item md={3}>
          <MainCard>
            <Typography sx={{ color: '#8C8C8C' }}>E-mail:</Typography>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{customer?.email ? customer?.email : '-'}</Typography>
              <EmailIcon />
            </Stack>
          </MainCard>
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: '#262626', marginY: 3 }} />
      <Grid container spacing={5}>
        <Grid item md={5}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Payment method</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography sx={{ color: '#B6A5DC' }}>Credit Card</Typography>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Transaction id:</Typography>
              <Typography>{id}</Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Amount:</Typography>
              <Typography>{`${amount} ${currency}`}</Typography>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item md={3}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Shipping method</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography sx={{ color: '#B6A5DC' }}>{transaction?.payment}</Typography>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Tracking Code:</Typography>
              <Typography>FX-012345-6</Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Date:</Typography>
              <Typography>12/12/2021</Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Fulfillment status:</Typography>
              <Typography
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 8px',
                  fontWeight: 400,
                  fontSize: '12px',
                  backgroundColor: '#A4DB6C',
                  color: '#000',
                  borderRadius: '4px'
                }}
              >
                Delivered
              </Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#8C8C8C' }}>Payment status:</Typography>
              <Typography
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 8px',
                  fontWeight: 400,
                  fontSize: '12px',
                  backgroundColor: '#B79BF1',
                  color: '#000',
                  borderRadius: '4px'
                }}
              >
                Paid
              </Typography>
            </Stack>
          </Stack>
        </Grid> */}
        <Grid item md={4}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Bill to</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography>
              {customer?.first_name} {customer?.last_name}
            </Typography>
            <Grid>
              <Typography>{customer?.phone_number ? customer?.phone_number : 'N/A'}</Typography>
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#acb9ff' }}>Sent to</Typography>
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography>
              {first_name} {last_name}
            </Typography>
            <Grid>
              <Typography>{transaction?.partners.phone_number ? transaction?.partners.phone_number : 'N/A'}</Typography>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <TableContainer sx={{ mt: 3 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#000' }}>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transaction.finance_details.tickets.map((product) => (
              <TableRow key={product.ticket_name_id}>
                <TableCell>{event?.name}</TableCell>
                <TableCell>{product.ticket_name}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>
                  {product.price} {product.currency}
                </TableCell>
                <TableCell>
                  {product.amount} {product.currency}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">Subtotal:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  {transaction.finance_details.total_net_amount} {transaction.currency}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">Taxes:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  {transaction.finance_details.total_gross_amount - transaction.finance_details.total_net_amount} {transaction.currency}
                </Typography>
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell colSpan={3} align="right">
                <Typography variant="body1">Discount (5%):</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">${discount.toFixed(2)}</Typography>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="h6" sx={{ color: '#B79BF1' }}>
                  Total:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: '#B79BF1' }}>
                  {transaction.finance_details.total_gross_amount} {transaction.currency}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DetailsPage;
