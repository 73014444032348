import { Event } from '@ceneteam/namespace';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { getEventDisplayStatus, getStatusStyles } from '../events.table';
import dayjs from 'dayjs';
import { ReactComponent as MapSVG } from '../../../../svg/map.svg';
import { ReactComponent as ScheduleSVG } from '../../../../svg/schedule.svg';

interface IProps {
  event: Event | null;
}

const EventDetails = ({ event }: IProps) => {
  if (!event) {
    return <Typography>No event details available.</Typography>;
  }

  const isCene = event.type === 'cene';
  const displayStatus = getEventDisplayStatus(event as Event);
  const statusStyles = getStatusStyles(displayStatus);

  return (
    <Grid container spacing={4.5}>
      <Grid item md={3}>
        {event?.featured_image && (
          <Avatar
            alt="Partner avatar"
            variant="square"
            src={event?.featured_image.url}
            sx={{
              width: '182px',
              height: '240px',
              border: '1px solid #424242a4',
              backgroundColor: '#1818188f',
              color: '#3b3b3b',
              borderRadius: '20px 0',
              marginBottom: '20px'
            }}
          />
        )}
      </Grid>
      <Grid item md={5}>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#707070' }}>{event?.category}</Typography>
        <Typography sx={{ fontSize: '30px', fontWeight: 300, color: '#fff', mt: 1 }}>{event?.name}</Typography>
        <Typography sx={{ textTransform: 'uppercase', fontSize: '15px', color: '#91DBBF', mt: 4 }}>About event</Typography>
        <Typography sx={{ mt: 2 }}>{event?.description}</Typography>
      </Grid>
      <Grid item md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <span
          style={{
            width: '84px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 8px',
            fontWeight: 400,
            fontSize: '12px',
            backgroundColor: statusStyles.backgroundColor,
            color: statusStyles.color,
            borderRadius: '4px'
          }}
        >
          {displayStatus}
        </span>
        <Stack sx={{ gap: 2 }}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize: '10px',
              padding: 1,
              backgroundColor: isCene ? '#EC67F7' : '#B8CAD9',
              color: '#000',
              width: 'fit-content',
              borderRadius: 4
            }}
          >
            {event.type}
          </Typography>
        </Stack>
        <Stack alignItems="center" flexDirection="row" gap="24px">
          <ScheduleSVG style={{ minWidth: '24px', height: '24px', stroke: '#91DBBF' }} />
          <Typography sx={{ fontFamily: 'Roobert, sans-serif', fontSize: '15px', fontWeight: 300 }}>
            {dayjs(event.starts_at || dayjs()).format('dddd, MMMM D')} · {dayjs(event.starts_at || dayjs()).format('h:mm a')} -{' '}
            {dayjs(event.ends_at || dayjs()).format('h:mm a')} WAT
          </Typography>
        </Stack>
        <Stack alignItems="center" flexDirection="row" gap="24px">
          <MapSVG style={{ minWidth: '24px', height: '24px', stroke: '#91DBBF' }} />
          <Typography sx={{ fontFamily: 'Roobert, sans-serif', fontSize: '15px', fontWeight: 300 }}>{` ${event.address}`}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default EventDetails;
