import { Button, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { PlusOutlined } from '@ant-design/icons';
import VenuesAndFloorplansStatistic from './statistic';
import VenuesTable from './table';
import { ModalContextProvider, useModalContext } from 'contexts/ReusableModalContext';
import ReusableModal from 'components/modal/modal';
import VenueForm from './form';
import { supabase } from 'supabase/supabase';
import { useEffect, useState } from 'react';

export interface Venue {
  id: string;
  name: string;
  country: string;
  state: string;
  address: string;
  capacity_details: string;
  floorspace: string;
  created_at: string;
  created_by: { first_name: string; last_name: string; id: string; job_title: string; role_name: string };
  partners_count: number;
  floorplans: [];
  isActive: boolean;
}

const VenuesAndFloorplans = () => {
  const { open, modalToggler } = useModalContext();
  const [venues, setVenues] = useState<Venue[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchVenues();
  }, []);

  const fetchVenues = async () => {
    try {
      const { data } = await supabase.from('venues').select('*,floorplans(*)');

      setVenues(data as Venue[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <LinearProgress />;

  return venues.length < 1 ? (
    <Grid container sx={{ height: 'calc(100vh - 200px)', alignItems: 'center', justifyContent: 'center' }}>
      <Stack sx={{ width: '420px', alignItems: 'center' }}>
        <div
          style={{
            margin: 'auto',
            padding: '20px',
            borderRadius: '50%',
            backgroundColor: '#8057DB',
            width: '76px',
            height: '76px',
            border: '1px solid #434343'
          }}
        >
          <PlaceIcon
            style={{
              fontSize: '36px',
              height: '36px'
            }}
          />
        </div>
        <Typography sx={{ fontSize: '28px', fontWeight: 300, color: '#8057DB', mt: 3 }}>There are no Venues to display</Typography>
        <Typography sx={{ fontSize: '15px', mt: 2, width: '340px', textAlign: 'center' }}>
          Creating an event on Cene makes the empty page come alive!
        </Typography>
        <Button
          onClick={() => modalToggler(null)}
          variant="contained"
          startIcon={<PlusOutlined />}
          sx={{ mt: 4, backgroundColor: '#8057DB', '&:hover': { backgroundColor: '#8157dbab' } }}
        >
          Add new venue
        </Button>
      </Stack>
      <ReusableModal width="488px" open={open} modalToggler={() => modalToggler(null)} content={VenueForm} currentObject={null} />
    </Grid>
  ) : (
    <Stack sx={{ gap: 3 }}>
      <VenuesAndFloorplansStatistic array={venues} />
      <VenuesTable venues={venues} onSuccess={fetchVenues} />
    </Stack>
  );
};

const VenuesAndFloorplansPage = () => {
  return (
    <ModalContextProvider>
      <VenuesAndFloorplans />
    </ModalContextProvider>
  );
};

export default VenuesAndFloorplansPage;
