import { Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import ShapeButton from 'components/button/button';
import { FormValues } from '.';
interface FormProps {
  onNext: () => void;
  isLoading: boolean;
  formik: FormikProps<FormValues>;
}

const NameStep = ({ onNext, formik, isLoading }: FormProps) => {
  const { getFieldProps, touched, errors } = formik;

  const isNameValid = formik.values.name ? !formik.errors.name : false;
  const isDescriptionValid = formik.values.description ? !formik.errors.description : false;

  const isFormValid = isNameValid && isDescriptionValid;

  return (
    <Grid sx={{ maxWidth: '688px', textAlign: 'center', mt: 4, mx: 'auto' }}>
      <Typography sx={{ fontSize: '34px', fontWeight: 300, color: '#8057DB' }}>Let’s Create Your FloorPlan</Typography>
      <Typography sx={{ fontSize: '16px', fontWeight: 300 }}>
        Welcome to the Floorplan Setup! Before we dive into the fun of arranging tables and chairs, we need to check a few boxes.
      </Typography>
      <Grid container spacing={3} sx={{ maxWidth: '532px', my: 4, mx: 'auto' }}>
        <Grid item sm={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="name" sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}>
              FloorPlan Name
            </InputLabel>
            <TextField
              id="name"
              autoComplete="off"
              variant="standard"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="E.g Elektrik Nights"
            />
          </Stack>
        </Grid>
        <Grid item sm={12}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor="description"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 300, color: '#8057DB' }}
            >
              FloorPlan Description
            </InputLabel>
            <TextField
              id="description"
              autoComplete="off"
              variant="standard"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              sx={{
                '& .MuiInputBase-input': { paddingBlock: '16px', fontFamily: 'Roobert', fontSize: '16px', fontWeight: 300 },
                '& .MuiInput-underline:before': { borderBottomColor: '#222222' },
                '& .MuiInput-underline:after': { borderBottomColor: '#333333' },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#555555' }
              }}
              placeholder="This is a floorplan with zones A-B created by Bidemi"
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <ShapeButton width="464px" handleClick={onNext} disabled={!isFormValid || isLoading}>
          Create Floorplan
        </ShapeButton>
      </Stack>
    </Grid>
  );
};

export default NameStep;
