import { Alert, Divider, InputLabel, Stack, Switch, TextField, Typography } from '@mui/material';

import { DateTimePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { CustomInput } from 'components/customInput/customInput';
import { ExpandedCard } from 'components/expandedCard/expandedCard';
import { useCallback, useEffect, useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EventSchema } from 'pages/eventManagement/event/event.schema';
import { ReactComponent as DollarSVG } from '../../svg/dollar.svg';
import { ReactComponent as NairaSVG } from '../../svg/naira.svg';
import { ReactComponent as PoundSVG } from '../../svg/pound.svg';
import { ReactComponent as EuroSVG } from '../../svg/euro.svg';
import { format } from 'date-fns';

interface ITicketCardPlus {
  index: number;
}

export const TicketCardPlus = ({ index }: ITicketCardPlus) => {
  const formik = useFormikContext<EventSchema>();
  const [dateError, setDateError] = useState<string | null>(null);
  const minDate = format(new Date(), 'dd.MM.yyyy HH:mm');

  const values = formik.values['cene+'];
  const errors = formik.errors['cene+'];

  const removeTicket = useCallback(() => {
    if (window.confirm('Are you sure you want to delete the ticket ?'))
      formik.setFieldValue(
        `cene+.simpleTickets`,
        values.simpleTickets.filter((_, i) => i !== index)
      );
  }, [index]);

  useEffect(() => {
    const startDate = values.tickets[index]?.sale_starts_at;
    const endDate = values.tickets[index]?.sale_ends_at;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffInMinutes = (end.getTime() - start.getTime()) / 60000;

      if (end <= start) {
        setDateError('Sales end date must be later than the start date.');
      } else if (diffInMinutes < 30) {
        setDateError('The sales duration must be at least 30 minutes.');
      } else {
        setDateError(null);
      }
    }
  }, [values.tickets, index]);

  console.log(formik.values['cene+']);

  return (
    <>
      <ExpandedCard
        title={values.simpleTickets[index].name || `Ticket ${index + 1}`}
        expanded={true}
        onRemove={removeTicket}
        children={
          <Stack>
            <Stack spacing={1}>
              <CustomInput
                placeholder="Name"
                required
                name={`cene+.simpleTickets[${index}].name`}
                value={values.simpleTickets[index]?.name}
                error={getIn(errors, `simpleTickets[${index}].name`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Stack>
            <Stack spacing={1} mt={2}>
              <CustomInput
                multiline
                placeholder="Add Ticket Description or additional details users should know about this ticket"
                label="Add Ticket Description"
                name={`cene+.simpleTickets[${index}].description`}
                value={values.simpleTickets[index]?.description}
                error={getIn(errors, `simpleTickets[${index}].description`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Stack>
            <Stack spacing={1} mt={2}>
              <InputLabel required sx={{ color: '#9983C9' }}>
                Quantity of tickets to be released
              </InputLabel>
              <TextField
                error={Boolean(getIn(errors, `simpleTickets[${index}].quantity`))}
                value={values.simpleTickets[index]?.quantity}
                name={`cene+.simpleTickets[${index}].quantity`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={getIn(errors, `simpleTickets[${index}].quantity`)}
                placeholder="Enter quantity"
                type="number"
              />

              <Typography fontSize="12px" mt={1} color="#8C8C8C">
                Once you confirm this ticket category, we’ll update the stock for this ticket type for your event!
              </Typography>
            </Stack>
            <Stack spacing={1} mt={2}>
              <InputLabel required sx={{ color: '#8C8C8C' }}>
                International Currency
              </InputLabel>
              <Stack gap={1} mt={2} flexDirection="row" alignItems="baseline">
                <Stack width="100%" display="block">
                  <TextField
                    InputProps={{
                      startAdornment: <DollarSVG />
                    }}
                    error={Boolean(getIn(errors, `simpleTickets[${index}].price_dollar`))}
                    helperText={getIn(errors, `simpleTickets[${index}].price_dollar`)}
                    fullWidth
                    value={values.simpleTickets[index]?.price_dollar}
                    name={`cene+.simpleTickets[${index}].price_dollar`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Dollar"
                    type="number"
                  />
                </Stack>
                <Stack width="100%" display="block">
                  <TextField
                    InputProps={{
                      startAdornment: <PoundSVG />
                    }}
                    error={Boolean(getIn(errors, `simpleTickets[${index}].price_pound`))}
                    helperText={getIn(errors, `simpleTickets[${index}].price_pound`)}
                    fullWidth
                    value={values.simpleTickets[index]?.price_pound}
                    name={`cene+.simpleTickets[${index}].price_pound`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Pound"
                    type="number"
                  />
                </Stack>
                <Stack width="100%" display="block">
                  <TextField
                    InputProps={{
                      startAdornment: <EuroSVG />
                    }}
                    error={Boolean(getIn(errors, `simpleTickets[${index}].price_euro`))}
                    helperText={getIn(errors, `simpleTickets[${index}].price_euro`)}
                    fullWidth
                    value={values.simpleTickets[index]?.price_euro}
                    name={`cene+.simpleTickets[${index}].price_euro`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Euro"
                    type="number"
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={1} mt={2}>
              <InputLabel required sx={{ color: '#8C8C8C' }}>
                Naira Payments
              </InputLabel>
              <Stack spacing={1} width="100%">
                <TextField
                  InputProps={{
                    startAdornment: <NairaSVG />
                  }}
                  error={Boolean(getIn(errors, `simpleTickets[${index}].price_naira`))}
                  helperText={getIn(errors, `simpleTickets[${index}].price_naira`)}
                  fullWidth
                  value={values.simpleTickets[index]?.price_naira}
                  name={`cene+.simpleTickets[${index}].price_naira`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Naira"
                  type="number"
                />
              </Stack>
            </Stack>

            <Stack mt={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack gap={2} alignItems="baseline" flexDirection="row">
                  <Stack spacing={1}>
                    <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                      Sales Start Date
                    </InputLabel>
                    <DateTimePicker
                      value={values.simpleTickets[index].sale_starts_at}
                      onChange={(newValue) => {
                        console.log('Setting date value:', newValue);
                        formik.setFieldValue(`cene+.simpleTickets[${index}].sale_starts_at`, newValue);
                      }}
                      format="dd.MM.yyyy HH:mm"
                      slotProps={{
                        popper: {
                          sx: {
                            [`.${pickersLayoutClasses.root}`]: {
                              marginBlock: '12px'
                            }
                          }
                        }
                      }}
                      minDate={minDate}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                      Sales End Date
                    </InputLabel>
                    <DateTimePicker
                      value={values.simpleTickets[index].sale_ends_at}
                      minDate={minDate}
                      onChange={(newValue) => {
                        console.log('Setting date value:', newValue);
                        formik.setFieldValue(`cene+.simpleTickets[${index}].sale_ends_at`, newValue);
                      }}
                      format="dd.MM.yyyy HH:mm"
                      slotProps={{
                        popper: {
                          sx: {
                            [`.${pickersLayoutClasses.root}`]: {
                              marginBlock: '12px'
                            }
                          }
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              </LocalizationProvider>
            </Stack>

            {dateError && (
              <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
                {dateError}
              </Alert>
            )}
            {/* <Stack spacing={1} mt={2}>
              <InputLabel required>Sales Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <RangePicker
                  showTime
                  onChange={(_, t) => {
                    formik.setFieldValue(`cene+.simpleTickets[${index}].sale_starts_at`, t[0]);
                    formik.setFieldValue(`cene+.simpleTickets[${index}].sale_ends_at`, t[1]);
                  }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode?.parentNode as HTMLElement;
                  }}
                  separator={false}
                  onBlur={() => {
                    formik.setFieldTouched(`cene+.simpleTickets[${index}].sale_starts_at`, true);
                    formik.setFieldTouched(`cene+.simpleTickets[${index}].sale_ends_at`, true);
                  }}
                  color="white"
                  style={{
                    padding: '8.6px 14px',
                    borderRadius: '4px',
                    border: '1px solid #595959',
                    backgroundColor: 'transparent',
                    color: 'white'
                  }}
                  variant="borderless"
                  dropdownClassName="dark-theme-dropdown"
                  format="DD.MM.YYYY HH:mm"
                />
              </LocalizationProvider>
              {Boolean(getIn(errors, `cene+.simpleTickets[${index}].sale_starts_at`)) && (
                <FormHelperText sx={{ color: '#a61d24' }}>
                  {getIn(errors, `cene+.simpleTickets[${index}].sale_starts_at`)}{' '}
                  {getIn(errors, `cene+.simpleTickets[${index}].sale_ends_at`)}
                </FormHelperText>
              )}
            </Stack> */}
            <Divider sx={{ borderColor: '#242424', marginBlock: '20px' }} />
            <Stack spacing={1}>
              <InputLabel sx={{ color: '#9983C9' }}>Make Ticket Sales Active</InputLabel>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Switch
                  sx={{ marginLeft: 0 }}
                  checked={values.simpleTickets[index]?.is_sale_active}
                  onChange={() => {
                    formik.setFieldValue(`cene+.simpleTickets[${index}].is_sale_active`, !values.simpleTickets[index]?.is_sale_active);
                  }}
                />
                <Typography color="info">I do not need international payments lol</Typography>
              </Stack>
            </Stack>
            <Stack spacing={1} mt={3}>
              <InputLabel sx={{ color: '#9983C9' }}>Make Ticket type Accessible </InputLabel>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Switch
                  sx={{ marginLeft: 0 }}
                  checked={values.simpleTickets[index]?.is_accessible}
                  onChange={() => {
                    formik.setFieldValue(`cene+.simpleTickets[${index}].is_accessible`, !values.simpleTickets[index]?.is_accessible);
                  }}
                />
                <Typography color="info">These are not accessible seating options</Typography>
              </Stack>
            </Stack>
            <Stack spacing={1} mt={3}>
              <InputLabel sx={{ color: '#9983C9' }}>Make GuestList Tickets </InputLabel>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Switch
                  sx={{ marginLeft: 0 }}
                  checked={values.simpleTickets[index]?.is_visible}
                  onChange={() => {
                    formik.setFieldValue(`cene+.simpleTickets[${index}].is_visible`, !values.simpleTickets[index]?.is_visible);
                  }}
                />
                <Typography color="info">
                  These tickets won't be available for purchase, but can be assigned to Cene Users by your team
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
