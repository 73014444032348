import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { useParams } from 'react-router';
import CircularLoader from 'components/CircularLoader';

import { Event } from '@ceneteam/namespace';
import TicketsInfoTable from './ticketsInfo/cene';
import TablesInfoTable from './ticketsInfo/cenePlus';
import ShapeButton from 'components/button/button';
import ReusableModal from 'components/modal/modal';
import CreateTicketForm from './forms/createTicket/cene';
import OverviewCeneStatistic from './statistic/cene';
import OverviewCenePlusStatistic from './statistic/cenePlus';

export interface ISoldTable {
  zone_id: string;
  total_tables: number;
  total_seats: number;
}

export interface ICeneOverviewTable {
  ceneAvailableTickets: number;
}

const OverviewPage = () => {
  const [event, setEvent] = useState<Event | null>(null);
  const [soldTables, setSoldTables] = useState<ISoldTable[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { id } = useParams();

  console.log(soldTables, event);

  const isCene = event?.type === 'cene';

  useEffect(() => {
    if (id) {
      fetchTicketsByEvent();
      !isCene && fetchTicketsById();
    }
  }, [id]);

  const fetchTicketsByEvent = async () => {
    setLoading(true);

    try {
      const { data, error } = await supabase.from('events').select('*').eq('id', id).single();

      if (error) {
        console.error('Error fetching tickets:', error.message);
        return;
      }

      setEvent(data);
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTicketsById = async () => {
    setLoading(true);

    try {
      const { data, error } = await supabase
        .from('tickets')
        .select('id, zone_id, table_id')
        .eq('event_id', id)
        .not('customer_id', 'is', null);

      if (error) {
        console.error('Error fetching tickets:', error.message);
        return;
      }

      if (data) {
        const groupedTickets = data.reduce(
          (acc, ticket) => {
            const zoneId = ticket.zone_id;
            if (!acc[zoneId]) {
              acc[zoneId] = { zone_id: zoneId, tables: new Set(), seats: 0 };
            }
            acc[zoneId].tables.add(ticket.table_id);
            acc[zoneId].seats += 1;
            return acc;
          },
          {} as Record<string, { zone_id: string; tables: Set<string>; seats: number }>
        );

        const formattedTickets = Object.values(groupedTickets).map((zone) => ({
          zone_id: zone.zone_id,
          total_tables: zone.tables.size,
          total_seats: zone.seats
        }));
        setSoldTables(formattedTickets);
      }
    } catch (error) {
      console.error('Unexpected error fetching tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return isLoading ? (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'center', mt: 4 }}>
      <CircularLoader />
    </Stack>
  ) : (
    <>
      {id && (
        <>
          {isCene ? <OverviewCeneStatistic event={event} /> : <OverviewCenePlusStatistic event={event} soldTables={soldTables} />}

          <Stack sx={{ mt: 3 }}>
            <ShapeButton width="200px" backgroundColor="#B6A5DC" handleClick={handleToggleModal}>
              Create Ticket
            </ShapeButton>
          </Stack>
          {isCene ? (
            <TicketsInfoTable event={event} onSuccess={fetchTicketsByEvent} />
          ) : (
            <TablesInfoTable event={event} soldTables={soldTables} onSuccess={fetchTicketsByEvent} />
          )}
          <ReusableModal
            width="600px"
            open={isModalOpen}
            modalToggler={handleToggleModal}
            onSuccess={fetchTicketsByEvent}
            content={CreateTicketForm}
            currentObject={null}
          />
        </>
      )}
    </>
  );
};

export default OverviewPage;
