import { IconButton, Stack, Typography } from '@mui/material';
import ReusableTabs from 'components/tabs/reusableTabs';
import { ROUTE_EVENTS } from 'constants/routes';
import { useEffect, useState } from 'react';
import { ReactComponent as BackSVG } from '../../../../../../../svg/arrow-left.svg';
import { useNavigate, useParams } from 'react-router';
import ShapeButton from 'components/button/button';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
// import DetailsPage from './details';
// import InvoicePage from './invoice';
import { Transaction } from '..';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { User } from 'types/user';
import { Event } from '@ceneteam/namespace';
import DetailsPage from './details';
import InvoicePage from './invoice';
// import { User } from 'types/user';

enum ITab {
  DETAILS = 'Details',
  INVOICE = 'Invoice'
}

const TransactionPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState<ITab>(ITab.DETAILS);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [eventId, setEventId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customer, setCustomer] = useState<User | null>(null);
  const [event, setEvent] = useState<Event | null>(null);

  const [isLoading, setLoading] = useState(false);

  console.log(event, 'event', customer, 'customer', transaction, isLoading);

  useEffect(() => {
    if (id) {
      fetchTransaction();
    }
  }, [id]);

  useEffect(() => {
    if (transaction) {
      fetchCustomer();
      fetchEvent();
    }
  }, [transaction]);

  const fetchTransaction = async () => {
    try {
      setLoading(true);
      const { error, data } = await supabase.from('transactions').select('*, partners:partner_id(*)').eq('id', id).single();
      if (error) throw error;
      const eventId = data.details_data?.event_id;
      const customerId = data.customer_id;

      setTransaction(data);
      setEventId(eventId);
      setCustomerId(customerId);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchEvent = async () => {
    try {
      setLoading(true);
      const { error, data } = await supabase.from('events').select('*').eq('id', eventId).single();
      if (error) throw error;

      setEvent(data);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomer = async () => {
    try {
      setLoading(true);
      const { error, data } = await supabase.from('customers').select('*').eq('id', customerId).single();
      if (error) throw error;

      setCustomer(data);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    const generatePDFContent = (elementId: string, fileName: string) => {
      const element = document.getElementById(elementId);
      if (element) {
        html2canvas(element, { scale: 2 })
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = doc.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let position = 0;

            doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);

            while (imgHeight > pageHeight) {
              position -= pageHeight;
              doc.addPage();
              doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            }

            doc.save(fileName);
          })
          .catch((error) => {
            console.error('Error generating PDF:', error);
          });
      }
    };

    if (activeTab === ITab.DETAILS) {
      generatePDFContent('receipt-container', 'receipt.pdf');
    } else if (activeTab === ITab.INVOICE) {
      generatePDFContent('invoice-container', 'invoice.pdf');
    }
  };

  const onBack = () => {
    navigate(ROUTE_EVENTS, { replace: true });
  };

  const handleTabClick = (tab: ITab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
        <IconButton
          onClick={onBack}
          sx={{
            borderRadius: '12px 0',
            backgroundColor: '#191919 !important',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#333333'
            },
            '&:focus': {
              backgroundColor: '#555555'
            }
          }}
        >
          <BackSVG style={{ stroke: '#AF96E6' }} />
        </IconButton>
        <Typography sx={{ textTransform: 'uppercase', color: '#B6A5DC' }}>Back to events</Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 3 }}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ fontSize: '30px' }}>
            {transaction?.customer_data.first_name} {transaction?.customer_data.last_name}
          </Typography>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 300,
              padding: '8px',
              borderRadius: '20px 0',
              border: '1px solid #8057DB',
              color: '#8057DB'
            }}
          >
            {event?.name}
          </Typography>
        </Stack>

        <ShapeButton handleClick={generatePDF} width="212px" color="#fff">
          {activeTab === ITab.DETAILS ? 'Download Receipt' : 'Download Invoice'}
        </ShapeButton>
      </Stack>

      <ReusableTabs tabs={ITab} activeTab={activeTab} onTabClick={handleTabClick} color="#8057DB" />
      {activeTab === ITab.DETAILS ? (
        <DetailsPage event={event} transaction={transaction} customer={customer} isLoading={isLoading} />
      ) : (
        <InvoicePage event={event} transaction={transaction} customer={customer} isLoading={isLoading} />
      )}
    </>
  );
};

export default TransactionPage;
