import { Stack, Typography } from '@mui/material';
import FansTable from './fans.table';
import FansStatistic from './fans.statistic';

const FansPage = () => {
  return (
    <Stack>
      <Typography sx={{ fontSize: '16px', mb: 2 }}>
        Here you can find the statistics on the subscriptions to your profile, including the number of followers, customer categories, and
        ticket purchases.
      </Typography>
      <FansStatistic />
      <FansTable />
    </Stack>
  );
};

export default FansPage;
