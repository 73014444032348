import { Alert, Divider, InputLabel, Stack, Switch, TextField, Typography } from '@mui/material';

import { DateTimePicker, LocalizationProvider, pickersLayoutClasses } from '@mui/x-date-pickers';
import { CustomInput } from 'components/customInput/customInput';
import { ExpandedCard } from 'components/expandedCard/expandedCard';
import { useCallback, useEffect, useState } from 'react';
import { getIn, useFormikContext } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EventSchema } from 'pages/eventManagement/event/event.schema';
import { ReactComponent as DollarSVG } from '../../svg/dollar.svg';
import { ReactComponent as NairaSVG } from '../../svg/naira.svg';
import { ReactComponent as PoundSVG } from '../../svg/pound.svg';
import { ReactComponent as EuroSVG } from '../../svg/euro.svg';
import { format } from 'date-fns';

interface ITicketCard {
  index: number;
}

export const TicketCard = ({ index }: ITicketCard) => {
  const formik = useFormikContext<EventSchema>();
  const [dateError, setDateError] = useState<string | null>(null);
  const minDate = format(new Date(), 'dd.MM.yyyy HH:mm');

  const values = formik.values[formik.values.formType];
  const errors = formik.errors[formik.values.formType];

  const removeTicket = useCallback(() => {
    if (window.confirm('Are you sure you want to delete the ticket ?'))
      formik.setFieldValue(
        `${formik.values.formType}.tickets`,
        values.tickets.filter((_, i) => i !== index)
      );
  }, [index]);

  useEffect(() => {
    const startDate = values.tickets[index]?.sale_starts_at;
    const endDate = values.tickets[index]?.sale_ends_at;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffInMinutes = (end.getTime() - start.getTime()) / 60000;

      if (end <= start) {
        setDateError('Sales end date must be later than the start date.');
      } else if (diffInMinutes < 30) {
        setDateError('The sales duration must be at least 30 minutes.');
      } else {
        setDateError(null);
      }
    }
  }, [values.tickets, index]);

  return (
    <>
      <ExpandedCard
        title={values.tickets[index].name || `Ticket ${index + 1}`}
        expanded={true}
        onRemove={removeTicket}
        children={
          <Stack>
            <Stack spacing={1}>
              <Typography fontSize="12px" mt={1} color="#8C8C8C">
                The ticket name should not include any event dates or event names. It is solely the label for the ticket that appears
                alongside its price.
              </Typography>
              <CustomInput
                placeholder="Ticket Name"
                required
                name={`${formik.values.formType}.tickets[${index}].name`}
                value={values.tickets[index]?.name}
                error={getIn(errors, `tickets[${index}].name`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Stack>
            <Stack spacing={1} mt={2}>
              <CustomInput
                multiline
                placeholder="Add Ticket Description or additional details users should know about this ticket"
                label="Add Ticket Description"
                name={`${formik.values.formType}.tickets[${index}].description`}
                value={values.tickets[index]?.description}
                error={getIn(errors, `tickets[${index}].description`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Stack>
            <Stack spacing={1} mt={2}>
              <InputLabel required sx={{ color: '#9983C9' }}>
                Quantity of tickets to be released
              </InputLabel>
              <TextField
                error={Boolean(getIn(errors, `tickets[${index}].quantity`))}
                value={values.tickets[index]?.quantity}
                name={`${formik.values.formType}.tickets[${index}].quantity`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={getIn(errors, `tickets[${index}].quantity`)}
                placeholder="Enter quantity"
                type="number"
              />

              <Typography fontSize="12px" mt={1} color="#8C8C8C">
                Once you confirm this ticket category, we’ll update the stock for this ticket type for your event!
              </Typography>
            </Stack>

            {values.isPaymentNaira && (
              <Stack spacing={1} mt={2}>
                <InputLabel required sx={{ color: '#8C8C8C' }}>
                  International Currency
                </InputLabel>
                <Stack gap={1} mt={2} flexDirection="row" alignItems="baseline">
                  <Stack width="100%" display="block">
                    <TextField
                      InputProps={{
                        startAdornment: <DollarSVG />
                      }}
                      error={Boolean(getIn(errors, `tickets[${index}].price_dollar`))}
                      helperText={getIn(errors, `tickets[${index}].price_dollar`)}
                      fullWidth
                      value={values.tickets[index]?.price_dollar}
                      name={`${formik.values.formType}.tickets[${index}].price_dollar`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Dollar"
                      type="number"
                    />
                  </Stack>
                  <Stack width="100%" display="block">
                    <TextField
                      InputProps={{
                        startAdornment: <PoundSVG />
                      }}
                      error={Boolean(getIn(errors, `tickets[${index}].price_pound`))}
                      helperText={getIn(errors, `tickets[${index}].price_pound`)}
                      fullWidth
                      value={values.tickets[index]?.price_pound}
                      name={`${formik.values.formType}.tickets[${index}].price_pound`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Pound"
                      type="number"
                    />
                  </Stack>
                  <Stack width="100%" display="block">
                    <TextField
                      InputProps={{
                        startAdornment: <EuroSVG />
                      }}
                      error={Boolean(getIn(errors, `tickets[${index}].price_euro`))}
                      helperText={getIn(errors, `tickets[${index}].price_euro`)}
                      fullWidth
                      value={values.tickets[index]?.price_euro}
                      name={`${formik.values.formType}.tickets[${index}].price_euro`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Euro"
                      type="number"
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
            {values.isPaymentOutsideNigeria && (
              <Stack spacing={1} mt={2}>
                <InputLabel required sx={{ color: '#8C8C8C' }}>
                  Naira Payments
                </InputLabel>
                <Stack spacing={1} width="100%">
                  <TextField
                    InputProps={{
                      startAdornment: <NairaSVG />
                    }}
                    error={Boolean(getIn(errors, `tickets[${index}].price_naira`))}
                    helperText={getIn(errors, `tickets[${index}].price_naira`)}
                    fullWidth
                    value={values.tickets[index]?.price_naira}
                    name={`${formik.values.formType}.tickets[${index}].price_naira`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Naira"
                    type="number"
                  />
                </Stack>
              </Stack>
            )}
            <Stack mt={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack gap={2} alignItems="baseline" flexDirection="row">
                  <Stack spacing={1}>
                    <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                      Sales Start Date
                    </InputLabel>
                    <DateTimePicker
                      value={values.tickets[index].sale_starts_at}
                      onChange={(newValue) => {
                        formik.setFieldValue(`${formik.values.formType}.tickets[${index}].sale_starts_at`, newValue);
                      }}
                      format="dd.MM.yyyy HH:mm"
                      slotProps={{
                        popper: {
                          sx: {
                            [`.${pickersLayoutClasses.root}`]: {
                              marginBlock: '12px'
                            }
                          }
                        }
                      }}
                      minDate={minDate}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <InputLabel required sx={{ color: '#9983C9' }} htmlFor="date">
                      Sales End Date
                    </InputLabel>
                    <DateTimePicker
                      value={values.tickets[index].sale_ends_at}
                      minDate={minDate}
                      onChange={(newValue) => {
                        formik.setFieldValue(`${formik.values.formType}.tickets[${index}].sale_ends_at`, newValue);
                      }}
                      format="dd.MM.yyyy HH:mm"
                      slotProps={{
                        popper: {
                          sx: {
                            [`.${pickersLayoutClasses.root}`]: {
                              marginBlock: '12px'
                            }
                          }
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              </LocalizationProvider>
            </Stack>

            {dateError && (
              <Alert sx={{ marginTop: '20px' }} variant="outlined" severity="error">
                {dateError}
              </Alert>
            )}
            <Divider sx={{ borderColor: '#242424', marginBlock: '20px' }} />
            <Stack spacing={1}>
              <InputLabel sx={{ color: '#9983C9' }}>Begin Ticket Sales after the event is published.</InputLabel>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Switch
                  sx={{ marginLeft: 0 }}
                  checked={values.tickets[index]?.is_sale_active}
                  onChange={() => {
                    formik.setFieldValue(
                      `${formik.values.formType}.tickets[${index}].is_sale_active`,
                      !values.tickets[index]?.is_sale_active
                    );
                  }}
                />
                <Typography color="info">You can start selling tickets anytime using the "Edit Event" button.</Typography>
              </Stack>
            </Stack>
            <Stack spacing={1} mt={3}>
              <InputLabel sx={{ color: '#9983C9' }}>Should it be possible to request an accessible ticket?</InputLabel>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Switch
                  sx={{ marginLeft: 0 }}
                  checked={values.tickets[index]?.is_accessible}
                  onChange={() => {
                    formik.setFieldValue(
                      `${formik.values.formType}.tickets[${index}].is_accessible`,
                      !values.tickets[index]?.is_accessible
                    );
                  }}
                />
                <Typography color="info">
                  Accessible ticket requests require your approval in the "Requests" tab before being sent to the user’s basket for payment.
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={1} mt={3}>
              <InputLabel sx={{ color: '#9983C9' }}>Visibility of the Ticket</InputLabel>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Switch
                  sx={{ marginLeft: 0 }}
                  checked={values.tickets[index]?.is_visible}
                  onChange={() => {
                    formik.setFieldValue(`${formik.values.formType}.tickets[${index}].is_visible`, !values.tickets[index]?.is_visible);
                  }}
                />
                <Typography color="info">
                  These tickets won't be available for purchase but can be assigned to Cene Users by your team
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
