import { ReactComponent as EventsSVG } from 'svg/events.svg';
import { ReactComponent as TicketSVG } from 'svg/tickets.svg';
import { Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import MainCard from 'components/MainCard';
import { useEffect, useState } from 'react';
import { supabase } from 'supabase/supabase';
import { enqueueSnackbar } from 'notistack';
import { Partner } from 'types/partner';
import { useParams } from 'react-router';
import { EventStatus } from '@ceneteam/namespace';
import dayjs from 'dayjs';

const Events = () => {
  const { id } = useParams<{ id: string }>();
  const [partner, setPartner] = useState<Partner | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchPartner();
  }, [id]);

  const fetchPartner = async () => {
    setLoading(false);
    try {
      setLoading(true);
      const { data, error } = await supabase.from('partners').select('*,events(*)').eq('id', id);
      setPartner(data?.[0] || null);
      if (error?.message) throw Error(error?.message);
    } catch (error) {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  const totalStock = partner?.events.reduce((sum, obj) => sum + Object.values(obj.stock || {}).reduce((sum, obj) => sum + obj, 0), 0)

  return (
    <MainCard title="Events">
      <Grid container gap="8px" sx={{ gap: '8px' }}>
        <Grid item xs={12} sm={5.9} md={3.75}>
          <AnalyticEcommerce title="Total Events" count={`${partner?.events.length}`} icon={<EventsSVG />} />
        </Grid>

        <Grid item xs={12} sm={5.9} md={3.75}>
          <AnalyticEcommerce title="Total Stock" count={`${totalStock}`} icon={<TicketSVG />} />
        </Grid>
      </Grid>
      <TableContainer sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead sx={{ backgroundColor: 'transparent' }}>
            <TableRow>
              <TableCell>Event Name</TableCell>
              <TableCell>Audience</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Stock</TableCell>
              <TableCell>Event Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(partner?.events || []).length > 0 ? (
              partner?.events.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: item.status === EventStatus.Published ? '#33c13d' : item.status === EventStatus.ComingSoon ? '#51b1fa' : '#484449',
                        fontWeight: 300,
                        fontSize: '16px'
                      }}
                    >
                      {item.status}
                    </Typography>
                  </TableCell>
                  <TableCell>{Object.values(item.stock || {}).reduce((sum, obj) => sum + obj, 0)}</TableCell>
                  <TableCell>{dayjs(item.starts_at).format('DD.MM.YYYY hh:mm:ss')}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center" color="textSecondary">
                    No events found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default Events;
