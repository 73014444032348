import * as Yup from 'yup';

const floorplanFormSchema = Yup.object({
  name: Yup.string().required('Name is required').min(3, 'Name should be at least 3 characters long'),
  description: Yup.string().max(500, 'Description can be at most 500 characters'),
  zones: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required(),
        zoneName: Yup.string().required('Zone name is required').min(2, 'Zone name should be at least 2 characters long'),
        tablesInZone: Yup.number()
          .required('Number of tables in the zone is required')
          .positive('Tables in the zone must be a positive number')
          .integer('Tables in the zone must be an integer'),
        tableShapes: Yup.string().required('Table shape is required'),
        zoneColor: Yup.string()
          .required('Zone color is required')
          .matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, 'Must be a valid hex color'),
        tablesType: Yup.string().required('Tables type is required'),
        numberOfSeatsPerTable: Yup.number()
          .required('Number of seats per table is required')
          .positive('Seats per table must be a positive number')
          .integer('Seats per table must be an integer'),
        tables: Yup.array()
          .of(
            Yup.object({
              id: Yup.string().required(),
              tablesType: Yup.string().required('Tables type is required'),
              numberOfSeatsPerTable: Yup.number()
                .required('Number of seats per table is required')
                .positive('Seats per table must be a positive number')
                .integer('Seats per table must be an integer')
            })
          )
          .min(1, 'At least one table is required in each zone'),
        seatsIdentification: Yup.string().required('Seats identification is required')
      })
    )
    .min(1, 'At least one zone is required'),
  hasStage: Yup.boolean(),
  includeBestAvailableSeats: Yup.boolean()
});

export default floorplanFormSchema;
